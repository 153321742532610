import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import Topbar from "../Home/Topbar";
import Contact from "../Home/Contact";
import postCodeImg from "../assets/revamp-css/assets/images/services/post-code.png";
import cleanHouseImg from "../assets/revamp-css/assets/images/services/clean-house.png";
import welcomeCallImg from "../assets/revamp-css/assets/images/services/welcome-call.png";
import cleanPlanImg from "../assets/revamp-css/assets/images/services/cleaning-plan.png";
import electricalPostImg from "../assets/revamp-css/assets/images/services/Drill-Paint1.png";
import service_cards from "../assets/revamp-css/assets/images/services/service_cardsH-1.jpg";
import service_cards2 from "../assets/revamp-css/assets/images/services/service_cardsH-2.jpg";
import service_cards3 from "../assets/revamp-css/assets/images/services/service_cardsH-3.jpg";
import service_cards4 from "../assets/revamp-css/assets/images/services/service_cardsH-4.jpg";
import card_svg from "../assets/revamp-css/assets/images/services/card_svg.png";
import service_booking_man from "../assets/revamp-css/assets/images/services/service_booking_man.png";
import Axios from "axios";
import ModalCommon from "../Modal/modal";
import FooterRevamp from "../FooterRevamp";
import GetCoollahApp from "../Home/GetCoollahApp";
import FAQ from "../Home/Faq";
import ImageFallback from "../ImageFallback";

const DrillPatchAndPaintServices = () => {
  const navigate = useNavigate();
  const getCategoryId = sessionStorage.getItem("category_id");
  const [postalCode, setPostalCode] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const footerRef = useRef();
  const pincodeRef = useRef();
  const [data, setData] = React.useState(null);
  const [membertype, setMemberType] = useState("Non Commercial");

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  const handleChangeType = (type) => {
    setMemberType(type);
  };

  useEffect(() => {
    footerRef?.current?.scrollIntoView();
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
          // MemberType: membertype,
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        if (response?.data?.is_successful) {
          setData(response.data?.result?.[0]?.services);
        }
      })
      .catch((error) => {});
  }, [membertype]);

  useEffect(() => {}, [sessionStorage.getItem("product_name")]);
  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        footerRef.current.scrollIntoView();
        // navigate("/CategoryService");
      }
    }
  };

  const { state } = useLocation();
  useEffect(() => {
    if (state?.value === "home") {
      setProduct(sessionStorage.getItem("product_name"));
      pincodeRef.current.scrollIntoView();
    }
  }, [sessionStorage.getItem("product_name")]);

  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.setItem("product_image", data.product_image);
    sessionStorage.setItem("remarks", data.remarks);
    setProduct(data.product_name);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    pincodeRef.current.scrollIntoView();
  };

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  };

  const loadCatgories = () => {
    const headers = {
      "content-type": "multipart/form-data",
      access_token: sessionStorage.getItem("access_token"),
    };
    API.get("load_all_categories", { headers }).then((resp) => {
      if (resp?.data?.is_successful) {
        setServices(resp.data?.result);
      }
      sessionStorage.setItem("menus", JSON.stringify(resp.data.result));
    });
  };

  const handleClickCategories = (category_id, category_name) => {
    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem("category_name", category_name);
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("product_name");
  };

  useEffect(() => {
    loadCatgories();
  }, []);

  return (
    <Fragment>
      <Topbar />
      <div className="container-fluid over-hid" ref={pincodeRef}>
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_sec_wrapper7">
              <div className="coollah_service_main_content">
                <div className="coollah_service_realtive_div">
                  <div className="coollah_service_absolute_content">
                    <h1 className="text-nowrap">Drill Patch And Paint Services</h1>
                    <p>Our drill patch repair and  paint service includes the thorough preparation of surfaces, including the patching of small holes and cracks. The patched areas are then sanded and smoothed to ensure a uniform finish.</p>
                    <form className="coollah_service_main_form" action="">
                      <input
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                        onBlur={(e) => checkPincode(e)}
                      />
                      <button type="button" onClick={handleClick}>
                        Book Now
                      </button>
                    </form>
                    <span className="formError">{formErrors.postalCode}</span>
                    <div ref={footerRef}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid" ref={footerRef}>
        <div className="row">
          <div className="col col-12 col-lg-3 order-lg-1 order-2">
            <div className="coollah_service_lhs">
              <div className="coollah_service_main_page">
                <p>Select Service</p>
                <div className="collah_services_list_wrapp">
                  <div className="row">
                    {services.map((ele, ind) => {
                      const { category_name, category_image, category_id } =
                        ele;
                      return (
                        <div
                          onClick={() =>
                            handleClickCategories(category_id, category_name)
                          }
                          key={ind}
                          className="col col-6 col-md-4"
                        >
                          <Link
                            to={
                              "/" +
                              category_name
                                .replace(/\s+/g, "")
                                .replace(/-/g, "")
                                .replace("/", "")
                            }
                          >
                            <div className="coollah_service_explain">
                              <ImageFallback
                                src={category_image}
                                alt={"service_img"}
                              />
                              <p>{category_name}</p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="coollah_service_aircon_list">
                <img src={electricalPostImg} alt="ele-service-img" />
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-9 order-lg-2 order-1">
            <div className="row">
              <div className="col-md-12">
                <div className="coollah_rhs">
                  <div className="coollah_service_services">
                    <div className="coollah_residential_height_wrapper">
                      <div className="row">
                        {data?.length === 0 && (
                          <div className="col-md-12">
                            <div className="text-center my-5">
                              <h3>No Services Found</h3>
                            </div>
                          </div>
                        )}
                        {data?.map((data, ind) => (
                          <div className="col-md-12" key={ind}>
                            <div className="coollah_service_chemical">
                              <div className="row">
                                <div className="col col-md-9">
                                  <div className="coollah_service_chemical_content">
                                    <h3> {data.product_name}</h3>
                                    <span className="heading_border"></span>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: data.remarks,
                                      }}
                                    ></p>
                                    <p className="price">
                                      Starts at <span>${data.price}</span>{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="col col-md-3">
                                  <div className="coollah_service_chem_img_container">
                                    <ImageFallback
                                      src={data.product_image}
                                      alt={"chemical_service_img"}
                                    />
                                    <button onClick={(e) => handleBook(data)}>
                                      Book Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_over">
              <h2>What does Our Drill, Patch & Painting Service Cover?</h2>
              <div className="coollah_service_doubled_border">
                <span className="border_first"></span>
                <span className="border_second"></span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="coollah_service_card_sec">
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards3}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>Our Drill, Patch & Paint Services cover tasks such as drilling holes, patching up any wall imperfections.</li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>Precision meets functionality with our professional drill hole services, catering to your specific needs and enhancing the utility of your space.</li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Drill Holes</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>Seamlessly restore the integrity of your walls with our meticulous patch-up drill hole services, leaving your surfaces flawless and ready for a fresh look.</li>
                            </div>
                            {/* <div className="coollah_service_card_list">
                              <li>Patching or filling any resulting holes or imperfections in surfaces.</li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>This can include activities like installing shelves, hanging artwork, or addressing any minor damages to walls..</li>
                            </div> */}
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Patch-Up Drill Holes</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards2}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>Elevate your space with a burst of color through our painting add-on service, perfectly complementing our drilling and patching services for a complete transformation.</li>
                            </div>
                            {/* <div className="coollah_service_card_list">
                              <li>We provide recommendations for safety improvements and preventive measures.</li>
                            </div> */}
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Painting Add-On</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards4}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>Explore a variety of additional services tailored to meet your unique requirements, that add functionality and aesthetics to your living or working environment..</li>
                            </div>
                            {/* <div className="coollah_service_card_list">
                              <li>Our energy-efficient lighting options and design consultation for ambiance and functionality ensure the combination of functionality with elegance.</li>
                            </div> */}
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Other Services</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row coollah_service_book">
          <div className="col col-12 col-md-12 col-lg-6">
            <div className="coollah_service_booking_man">
              <img src={service_booking_man} alt="Service_bboking_man_img" />
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-4">
            <div className="coollah_service_connect_form">
              <p>Get Your Services Professionally</p>
              <form className="coollah_service_booking_form">
                <input
                  name="pincode"
                  pattern="[0-9]*"
                  maxLength="6"
                  placeholder="Please Enter Postal Code"
                  title="Please Enter Postal Code"
                  autoComplete="off"
                  tabIndex="37"
                  required=""
                  value={postalCode}
                  onChange={(e) =>
                    setPostalCode((v) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                  onBlur={(e) => checkPincode(e)}
                />
                <button type="button" onClick={handleClick}>
                  Book Now
                </button>
              </form>
            </div>
          </div>
        </div>
        
        <div className="coollah_service_circles_mobile">
          <div className="row ">
            <div className="col-md-12">
              <div className="coollah_service_started">
                <h2>How to Get Started</h2>
                <div className="coollah_service_doubled_border">
                  <span className="border_first"></span>
                  <span className="border_second"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="coollah_service_circles">
          <div className="row">
              <div className="bg_sec">
                <div className="col-md-12">
                  <div className="bg-dot"></div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_location">
                  <img src={postCodeImg} alt="Location_image" />
                  <div className="coollah_service_content">
                    <h3>Enter your postcode</h3>
                    <p>Begin your journey with Coollah's service by entering your pin code on our application. Instantly check service availability in your area and access service plans tailored to your location, ensuring a seamless and personalized experience.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_calender">
                  <img src={cleanPlanImg} alt="Calender_image" />
                  <div className="coollah_service_content">
                    <h3>Choose your Service plan</h3>
                    <p>Select the service plan that best suits your needs, whether it's standard service, deep service, or specialized services. Each plan comes with detailed descriptions, empowering you to make an informed choice. Choose the frequency that aligns with your lifestyle, from one-time service to convenient recurring schedules.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_telecalling">
                  <img src={welcomeCallImg} alt="Telecalling_image" />
                  <div className="coollah_service_content">
                    <h3>Receive a welcome call</h3>
                    <p>After choosing your service plan, our dedicatedservice team will reach out with a warm welcome call. Confirm service details, schedule your service appointment, and have any questions addressed. We strive for a personalized and hassle-free experience, ensuring your complete satisfaction.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_success">
                  <img src={cleanHouseImg} alt="Success_logo" />
                  <div className="coollah_service_content">
                    <h3>Enjoy Our Service</h3>
                    <p>With all administrative details sorted, it's time to savor the convenience of our top-notch service. Our highly trained professionals arrive at your scheduled time, equipped with tools and materials to transform your living space. Sit back, relax, and witness your home refreshed and revitalized.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="coollah_circle_connector_waves">
              <img
                src="./assets/images/services/connector.png"
                alt="Connectin_waves_image"
              />
            </div> */}
          </div>
        </div>
        
      </div>
      <GetCoollahApp />
      <FAQ categoryId={getCategoryId} />
      <Contact />
      <FooterRevamp />
      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
            <h5 className="mb-2">Not Found</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </Fragment>
  );
};

export default DrillPatchAndPaintServices;
