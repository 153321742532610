import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactTable from "../ReactTable";
import Success from "../Success";
import Failure from "../Failure";
import AddAddress from "../AddAddress";
import Recharge from "../Recharge";
import AddNewAddress from "../AddNewAddress";
import ViewInstallationCallReport from "../InstallationTicket/ViewInstallationCallReport";
import ViewServiceCallReport from "../ServiceTicket/ViewServiceCallReport";
import ViewContractCallReport from "../ViewContractViewReport";
import CategoryService from "../CategoryServices";
import CleaningServices from "../AllServices/CleaningServices";
import AddPost from "../Posts/AddPost";
import EditPost from "../Posts/EditPost";
import AirConGasTopup from "../AllServices/AirConGasTopup";
import AirConServicesCeiling from "../AllServices/AirConServicesCeiling";
import AirConServiceWallMounted from "../AllServices/AirConServicesWallMounted";
import AnnualService from "../AllServices/AnnualService";
import ElectricalServices from "../AllServices/ElectricalServices";
import HandymanService from "../AllServices/HandymanService";
import PlumbingService from "../AllServices/PlumbingServices";
import PlumbingServicesRevamp from "../AllServices/PlumbingServiceRevamp";
import Testimonials from "../Home/Testimonials";
import AirConServices from "../AllServices/AirconServices";
import CarpenterServices from "../AllServices/CarpenterServicing";
import Pincode from "../Pincode";
import ServiceSelection from "../ServiceSelection";
import Privacy from "../Privacy";
import Terms from "../Terms";
import AirConServicesRevamp from "../AllServices/AirconServiceRevamp";
import AirConInstallations from "../AllServices/AirconInstallations";
import ElectricalServicesRevamp from "../AllServices/ElectricalServicesRevamp";
import ContactRevamp from "../Home/ContactRevamp";
import DrillPatchAndPaintServices from "../AllServices/DrillPatchAndPaingServices";
import DoorComponentReplacementServices from "../AllServices/DoorComponentReplacement";

const MyPosts = lazy(() => import("../Posts/MyPosts"));
const Posts = lazy(() => import("../Posts/Posts"));
const LoadAppointment = lazy(() => import("../Appointment/LoadAppointment"));
const ViewAppointment = lazy(() => import("../ViewAppointment"));
const Home = lazy(() => import("../Home"));
const ProformaInvoices = lazy(() =>
  import("../ProformaInvoices/ProformaInvoices")
);
const SingleProformaInvoice = lazy(() =>
  import("../ProformaInvoices/SingleProformaInvoice")
);
const Invoices = lazy(() => import("../Invoices/Invoice"));
const SingleInvoice = lazy(() => import("../Invoices/SingleInvoice"));
const AgentProformaInvoices = lazy(() =>
  import("../Agent/AgentProformaInvoices")
);
const AgentInvoices = lazy(() => import("../Agent/AgentInvoices"));
const AgentSingleProformaInvoice = lazy(() =>
  import("../Agent/AgentSingleProformaInvoice")
);
const LoginRevamp = lazy(() => import("../LoginRevamp"));
const LoginOtpRevamp = lazy(() => import("../LoginOtpRevamp"));
const ContactUs = lazy(() => import("../Contact"));
const FAQ = lazy(() => import("../FAQ"));
const Blog = lazy(() => import("../Blog"));
const Enquiry = lazy(() => import("../Enquiry"));
const Booking = lazy(() => import("../Booking"));
const BuyNow = lazy(() => import("../BuyNow"));
const AssetContract = lazy(() => import("../AssetContract"));
const Contract = lazy(() => import("../Contract"));
const ServiceAgreement = lazy(() => import("../ServiceAgreement"));
const BlogView = lazy(() => import("../BlogView"));
const NeedHelp = lazy(() => import("../NeedHelp"));
const Register = lazy(() => import("../Register"));
const ForgotPassword = lazy(() => import("../ForgotPassword/ForgotPassword"));
const ServiceGeneral = lazy(() => import("../ServiceGeneral"));
const ServiceDisplay = lazy(() => import("../ServiceDisplay"));
const ManageProfile = lazy(() => import("../ManageProfile"));
const ManageAddress = lazy(() => import("../ManageAddress"));
const ManageAllAddress = lazy(() => import("../ManageAllAddress"));
const ViewContract = lazy(() => import("../ViewContract"));
const LoadContract = lazy(() => import("../LoadContract"));
const Points = lazy(() => import("../Points"));
const Chat = lazy(() => import("../Chat"));
const ChangeAppointment = lazy(() =>
  import("../Appointment/ChangeAppointment")
);
const ChangePassword = lazy(() => import("../ChangePassword/ChangePassword"));
const InstallationTicket = lazy(() =>
  import("../InstallationTicket/InstallationTicket")
);
const InstallationTicketDetails = lazy(() =>
  import("../InstallationTicket/InstallationTicketDetails")
);
const SingleInstallationTicketDetails = lazy(() =>
  import("../InstallationTicket/SingleInstallationTicketDetails")
);
const ServiceTicket = lazy(() => import("../ServiceTicket/ServiceTicket"));
const EditServiceTicket = lazy(() =>
  import("../ServiceTicket/EditServiceTicket")
);
const ServiceTicketDetails = lazy(() =>
  import("../ServiceTicket/ServiceTicketDetails")
);
const SingleServiceTicketDetails = lazy(() =>
  import("../ServiceTicket/SingleServiceTicketDetails")
);

const AgentChangeAppointment = lazy(() =>
  import("../Agent/AgentChangeAppointment")
);
const AgentManageProfile = lazy(() => import("../Agent/AgentManageProfile"));
const ViewAgentContract = lazy(() => import("../Agent/ViewAgentContracts"));
const ViewMemberContract = lazy(() => import("../Agent/ViewMemberContract"));
const MemberDetails = lazy(() => import("../Agent/MemberDetails"));
const ViewMemberAppointments = lazy(() =>
  import("../Agent/ViewMemberAppointments")
);
const ViewMember = lazy(() => import("../Agent/ViewMembers"));
const AgentEditServiceTicket = lazy(() =>
  import("../Agent/AgentEditServiceTicket")
);
const AgentRegister = lazy(() => import("../Agent/AgentRegister"));
const AddMember = lazy(() => import("../Agent/AddMember"));
const CreateContract = lazy(() => import("../Agent/CreateContract"));
const Services = lazy(() => import("../Agent/Services"));
const ContractDetails = lazy(() => import("../Agent/ContractDetails"));
const AppointmentDetails = lazy(() => import("../Agent/AppointmentDetails"));
const AgentInstallationTicket = lazy(() =>
  import("../Agent/AgentInstallationTicket")
);
const AgentServiceTicket = lazy(() => import("../Agent/AgentServiceTicket"));
const AgentChangePassword = lazy(() => import("../Agent/AgentChangePassword"));
const AgentInstallationTicketDetails = lazy(() =>
  import("../Agent/AgentInstallationTicketDetails")
);
const AgentSingleInstallationTicket = lazy(() =>
  import("../Agent/AgentSingleInstallationTicket")
);
const AgentServiceTicketDetails = lazy(() =>
  import("../Agent/AgentServiceTicketDetails")
);
const AgentSingleServiceTicket = lazy(() =>
  import("../Agent/AgentSingleServiceTicket")
);
const AgentCommission = lazy(() => import("../Agent/AgentCommission"));
const Help = lazy(() => import("../help"));
const Share = lazy(() => import("../Share"));

function Layout() {
  return (
    <Routes>
      {/* used files */}
      <Route exact="true" path="/" element={<Home />} />
      <Route exact="true" path="/PaymentSuccess" element={<Success />} />
      <Route path="/PaymentFailure" element={<Failure />} />
      <Route
        exact="true"
        path="/ProfomaInvoices"
        element={<ProformaInvoices />}
      />
      <Route exact="true" path="/Invoices" element={<Invoices />} />
      {/* <Route path="/Login" element={<Login />} /> */}
      <Route path="/Login" element={<LoginRevamp />} />
      <Route path="/LoginOtp" element={<LoginOtpRevamp />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/NeedHelp" element={<NeedHelp />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Enquiry" element={<Enquiry />} />
      <Route path="/Enquiry/:AirconInstallations" element={<Enquiry />} />
      <Route path="/ServiceDisplay" element={<ServiceDisplay />} />
      <Route path="/Contract" element={<Contract />} />
      <Route path="/ChangeAppointment" element={<ChangeAppointment />} />
      <Route path="/ServiceAgreement" element={<ServiceAgreement />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/ChangePassword" element={<ChangePassword />} />
      <Route path="/ManageProfile" element={<ManageProfile />} />
      <Route path="/ManageAddress" element={<ManageAddress />} />
      {/* {view-address} */}
      <Route path="/ManageAllAddress" element={<ManageAllAddress />} />
      {/* {all-address} */}
      <Route path="/ViewContract" element={<ViewContract />} />
      <Route path="/LoadAppointment" element={<LoadAppointment />} />
      <Route path="/ViewAppointment" element={<ViewAppointment />} />
      <Route path="/LoadContract" element={<LoadContract />} />
      <Route path="/Chat" element={<Chat />} />
      <Route path="/InstallationTicket" element={<InstallationTicket />} />
      <Route
        path="/ViewInstallationCallReport"
        element={<ViewInstallationCallReport />}
      />
      <Route
        path="/ViewServiceCallReport"
        element={<ViewServiceCallReport />}
      />
       <Route
        path="/ViewContractCallReport"
        element={<ViewContractCallReport />}
      />
      <Route
        path="/InstallationTicketDetails"
        element={<InstallationTicketDetails />}
      />
      <Route
        path="/SingleInstallationTicketDetails"
        element={<SingleInstallationTicketDetails />}
      />
      <Route path="/AddNewAddress" element={<AddNewAddress />} />
      <Route path="/Booking" element={<Booking />} />
      <Route path="/Pincode" element={<Pincode />} />
      <Route path="/CleaningServices" element={<CleaningServices />} />
      <Route path="/AirConServices" element={<AirConServicesRevamp />} />
      <Route path="/AirconInstallation" element={<AirConInstallations />} />
      <Route path="/ServiceSelection" element={<ServiceSelection />} />
      <Route path="/ElectricalServices" element={<ElectricalServicesRevamp />} />
      <Route path="/CategoryService" element={<CategoryService />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/AddAddress" element={<AddAddress />} />
      <Route path="/CreateContract" element={<CreateContract />} />
      <Route path="/ViewMemberContract" element={<ViewMemberContract />} />
      <Route path="/AgentManageProfile" element={<AgentManageProfile />} />
      

      <Route path="/Contact" element={<ContactRevamp />} />
      {/* <Route path="/Contact" element={<ContactUs />} /> */}





      {/* un-used files */}
      <Route
        exact="true"
        path="/SingleProformaInvoice"
        element={<SingleProformaInvoice />}
      />
      <Route exact="true" path="/SingleInvoice" element={<SingleInvoice />} />
      <Route path="/AssetContract" element={<AssetContract />} />
      <Route path="/ServiceGeneral" element={<ServiceGeneral />} />
      <Route path="/Points" element={<Points />} />
      <Route path="/Blog" element={<Blog />} />
      <Route path="/BlogView" element={<BlogView />} />
      <Route path="/ServiceTicket" element={<ServiceTicket />} />
      <Route path="/EditServiceTicket" element={<EditServiceTicket />} />
      <Route path="/ServiceTicketDetails" element={<ServiceTicketDetails />} />
      <Route
        path="/SingleServiceTicketDetails"
        element={<SingleServiceTicketDetails />}
      />
      <Route path="/BuyNow" element={<BuyNow />} />
      <Route path="/AirConGasTopup" element={<AirConGasTopup />} />
      <Route
        path="/AirConServicesCeiling"
        element={<AirConServicesCeiling />}
      />
      <Route path="/CarpenterServices" element={<CarpenterServices />} />
      <Route
        path="/AirConServicesWallMounted"
        element={<AirConServiceWallMounted />}
      />
      <Route path="/Privacy" element={<Privacy />} />
      <Route path="/Terms" element={<Terms />} />
      <Route path="/Handyman" element={<AnnualService />} />
      <Route path="/HandymanServices" element={<HandymanService />} />
      <Route path="/Drill,Patch&PaintServices" element={<DrillPatchAndPaintServices />} />
      <Route path="/DoorComponentReplacement" element={<DoorComponentReplacementServices />} />
      <Route path="/PlumbingServices" element={<PlumbingServicesRevamp />} />
      <Route path="/MyPosts" element={<MyPosts />} />
      <Route path="/Posts" element={<Posts />} />
      <Route path="/AddPost" element={<AddPost />} />
      <Route path="/EditPost" element={<EditPost />} />
      <Route path="/AgentCommission" element={<AgentCommission />} />
      <Route path="/AddMember" element={<AddMember />} />
      <Route path="/ViewAgentContracts" element={<ViewAgentContract />} />
      <Route path="/ViewMember" element={<ViewMember />} />
      <Route
        path="/ViewMemberAppointments"
        element={<ViewMemberAppointments />}
      />
      <Route path="/Testimonials" element={<Testimonials />} />
      <Route path="/Recharge" element={<Recharge />} />
      <Route path="/Help" element={<Help />} />
      <Route path="/Share" element={<Share />} />
      <Route path="/ReactTable" element={<ReactTable />} />
      {/* un-used files */}


      {/* condition-based-routes */}
      <Route path="/MemberDetails" element={<MemberDetails />} />
      <Route path="/AppointmentDetails" element={<AppointmentDetails />} />
      <Route
        path="/AgentInstallationTicket"
        element={<AgentInstallationTicket />}
      />
      <Route path="/AgentServiceTicket" element={<AgentServiceTicket />} />
      <Route
        path="/AgentChangeAppointment"
        element={<AgentChangeAppointment />}
      />
      <Route
        path="/AgentInstallationTicketDetails"
        element={<AgentInstallationTicketDetails />}
      />
      <Route
        path="/AgentSingleInstallationTicket"
        element={<AgentSingleInstallationTicket />}
      />
      <Route
        path="/AgentServiceTicketDetails"
        element={<AgentServiceTicketDetails />}
      />
      <Route
        path="/AgentSingleServiceTicket"
        element={<AgentSingleServiceTicket />}
      />
      <Route
        path="/AgentEditServiceTicket"
        element={<AgentEditServiceTicket />}
      />
      <Route
        path="/AgentProformaInvoices"
        element={<AgentProformaInvoices />}
      />
      <Route path="/AgentInvoices" element={<AgentInvoices />} />
      <Route
        path="/AgentSingleProformaInvoice"
        element={<AgentSingleProformaInvoice />}
      />
      <Route path="/AgentRegister" element={<AgentRegister />} />
      <Route path="/AgentChangePassword" element={<AgentChangePassword />} />
      <Route path="/ContractDetails" element={<ContractDetails />} />
    </Routes>
  );
}

export default Layout;
