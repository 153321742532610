import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "./API/API";

const Sidebar = () => {
  const [screens, setScreens] = useState(null);
  const location = useLocation();

  const { pathname } = location;

  // const splitLocation = pathname.split("/");
  // console.log(splitLocation[1])
  useEffect(() => {
    API.get("load_screens?type=Member", {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((response) => {
      setScreens(response.data);
    });
  }, []);

  if (!screens) return null;

  return (
    <div className="st-sidebar st-right-sidebar">
      <div className="widget widget_categories">
        <h2 className="widget-title">Dashboard</h2>
        <ul>
          {screens.result.map((screen) => (
            <li key={screen.screen_name}>
              <Link to={screen.screen_path} className="Link sidebar">
                {/* <i className={screen.screen_logo} ></i> */}
                <img src={screen.screen_logo} />
                <span
                  className={
                    pathname === screen.screen_path
                      ? "menu-title active"
                      : "nav-item"
                  }
                >
                  {screen.screen_name}{" "}
                </span>
              </Link>
            </li>
          ))}

          {/* 
		  <li><Link to="/Chat" className='Link'><i className="fa fa-list-alt"></i> Chat</Link></li>
		  <li><Link to="/LoadContract" className='Link'><i className="fa fa-list-ul"></i> My Contracts</Link></li>
		  
        <li><Link to="/InstallationTicketDetails" className='Link'><i className="fa fa-info-circle"></i> Installation Ticket</Link></li>
        <li><Link to="/ServiceTicket" className='Link'><i className="fa fa-info-circle"></i> Service Ticket</Link></li>
        <li><Link to="/Invoices" className='Link'><i className="fa fa-file-text"></i> Service Ticket</Link></li> */}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
