import FooterRevamp from "../FooterRevamp";
import React, { useState, useEffect, Fragment } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import ModalCommon from "../Modal/modal";
import Topbar from "../Home/Topbar";
const AddPost = () => {

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");
  const [post, setPost] = useState(false);

  // Add New Address
  const [showAddAddress, setAddAddressShow] = useState(false);
  const handleAddAddressClose = () => setAddAddressShow(false);
  const handleAddAddrressShow = () => setAddAddressShow(true);

  const [formErrors, setFormErrors] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [additional_image, setAdditionalImage] = useState("");
  const [additional_image1, setAdditionalImage1] = useState("");
  const [additional_image2, setAdditionalImage2] = useState("");
  const [default_image, setDefaultImage] = useState("");

  const [ad_category_id, setcategoryid] = useState();
  const [brand, setBrand] = useState("");
  const [product_age, setProductAge] = useState("");
  const [special_remarks, setSpecialRemark] = useState("");
  const [offer_price, setOfferPrice] = useState("");
  const [ad_category_name, setAdCategoryName] = useState("");
  const navigate = useNavigate();

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
    if (successShow && post) {
      setSuccessShow(false);
      navigate("/MyPosts");
    }
  };

  useEffect(() => {
    API.get("load_all_ad_category").then((result) => {
      setCategories(result.data.result);
    });

    if (formErrors === "") {
      setIsSubmit(false);
    }
  }, []);
  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      setIsSubmit(true);
    }
  }, [formErrors]);

  const handleChange = (event) => {
    console.log(event.target.files[0]);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      var index1 = reader.result.split(",")[1];
      console.log(index1);
      // var end_date = (reader.result).substr(1, index1);
      // console.log(end_date)
      setDefaultImage(index1);
    };
    reader.readAsDataURL(file);
  };
  const handleAdditionalChange = (event) => {
    console.log(event.target.files[0]);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      var index1 = reader.result.split(",")[1];
      setAdditionalImage(index1);
    };
    reader.readAsDataURL(file);
  };
  const handleAdditionalChange1 = (event) => {
    console.log(event.target.files[0]);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      var index1 = reader.result.split(",")[1];
      setAdditionalImage1(index1);
    };
    reader.readAsDataURL(file);
  };
  const handleAdditionalChange2 = (event) => {
    console.log(event.target.files[0]);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      var index1 = reader.result.split(",")[1];
      setAdditionalImage2(index1);
    };
    reader.readAsDataURL(file);
  };
  const addCategory = () => {
    handleAddAddrressShow();
  };
  const addAdCategory = () => {
    API.post("create_ad_category", {
      user_id: sessionStorage.getItem("user_id"),
      ad_category_name: ad_category_name,
    })
      .then(function (response) {
        if (response.data.is_successful === true) {
          setMessage("Ad Category Added Successfully");
          // setSuccessAlert(true);
          handleSuccessShow();
          setIsSubmit(false);
          handleAddAddressClose();
        } else {
          setMessage(response.data.message);
          // setFailureAlert(true);
          handleFaliureShow();
          setIsSubmit(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmit(false);
      });
  };
  const validate = (name) => {
    const errors = {};

    switch (name) {
      case "ad_category_id":
        if (!ad_category_id) {
          errors.ad_category_id = "Category  is required!";
        }

        break;

      case "brand":
        if (!brand) {
          errors.brand = "Brand is required";
        }
        break;
      case "default_image":
        if (!default_image) {
          errors.default_image = "Image is required";
        }
        break;
      case "product_age":
        if (!product_age) {
          errors.product_age = "Product Age is required";
        }
        break;

      case "offer_price":
        if (!offer_price) {
          errors.price = "Price is required";
        }
        break;
      default:
        break;
    }
    return errors;
  };
  const CategoryValidate = (name) => {
    const errors = {};

    switch (name) {
      case "ad_category_name":
        if (!ad_category_name) {
          errors.ad_category_name = "Category is required!";
        }

        break;

      default:
        break;
    }
    return errors;
  };
  const validate1 = (
    ad_category_id,
    brand,
    default_image,
    product_age,
    offer_price
  ) => {
    const errors = {};
    if (!ad_category_id) {
      errors.ad_category_id = "Category is required!";
    }

    if (!brand) {
      errors.brand = "Brand is required";
    }

    if (!default_image) {
      errors.default_image = "Image is required";
    }

    if (!product_age) {
      errors.product_age = "Product Age is required";
    }

    if (!offer_price) {
      errors.price = "Price is required";
    }

    return errors;
  };
  const handleError = (e) => {
    const name = e.target.name;

    setFormErrors(validate(name));
  };

  const handleCategoryError = () => {
    setFormErrors(CategoryValidate(ad_category_name));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(
      validate1(ad_category_id, brand, default_image, product_age, offer_price)
    );
    if (isSubmit === true) {
      if (additional_image === "") {
        API.post("create_ad", {
          user_id: sessionStorage.getItem("user_id"),
          ad_category_id: ad_category_id,
          brand_name: brand,
          product_age: product_age,
          default_image: default_image,
          special_remarks: special_remarks,
          offer_price: offer_price,
          additional_images: [],
        })
          .then(function (response) {
            if (response.data.is_successful === true) {
              setMessage("Ad Posted Successfully");
              // setSuccessAlert(true);
              handleSuccessShow();

              setIsSubmit(false);
              setPost(true);
              // navigate("/MyPosts");
            } else {
              setMessage(response.data.message);
              // setFailureAlert(true);
              handleFaliureShow();
              setIsSubmit(false);
            }
          })

          .catch(function (error) {
            console.log(error);
            setIsSubmit(false);
          });
      } else if (additional_image1 === "") {
        API.post("create_ad", {
          user_id: sessionStorage.getItem("user_id"),
          ad_category_id: ad_category_id,
          brand_name: brand,
          product_age: product_age,
          default_image: default_image,
          special_remarks: special_remarks,
          offer_price: offer_price,
          additional_images: [
            {
              base_64: additional_image,
            },
          ],
        })
          .then(function (response) {
            if (response.data.is_successful === true) {
              setMessage("Ad Posted Successfully");
              // setSuccessAlert(true);
              handleSuccessShow();
              setPost(true);
              setIsSubmit(false);

              // navigate("/MyPosts");
            } else {
              setMessage(response.data.message);
              // setFailureAlert(true);
              handleFaliureShow();
              setIsSubmit(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsSubmit(false);
          });
      } else if (additional_image2 === "") {
        API.post("create_ad", {
          user_id: sessionStorage.getItem("user_id"),
          ad_category_id: ad_category_id,
          brand_name: brand,
          product_age: product_age,
          default_image: default_image,
          special_remarks: special_remarks,
          offer_price: offer_price,
          additional_images: [
            {
              base_64: additional_image,
            },
            {
              base_64: additional_image1,
            },
          ],
        })
          .then(function (response) {
            if (response.data.is_successful === true) {
              setMessage("Ad Posted Successfully");
              // setSuccessAlert(true);
              handleSuccessShow();
              setPost(true);
              setIsSubmit(false);
              // navigate("/MyPosts");
            } else {
              setMessage(response.data.message);
              // setFailureAlert(true);
              handleFaliureShow();
              setIsSubmit(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsSubmit(false);
          });
      } else {
        API.post("create_ad", {
          user_id: sessionStorage.getItem("user_id"),
          ad_category_id: ad_category_id,
          brand_name: brand,
          product_age: product_age,
          default_image: default_image,
          special_remarks: special_remarks,
          offer_price: offer_price,
          additional_images: [
            {
              base_64: additional_image,
            },
            {
              base_64: additional_image1,
            },
            {
              base_64: additional_image2,
            },
          ],
        })
          .then(function (response) {
            if (response.data.is_successful === true) {
              setMessage("Ad Posted Successfully");
              // setSuccessAlert(true);
              handleSuccessShow();
              setPost(true);
              setIsSubmit(false);
              // navigate("/MyPosts");
            } else {
              setMessage(response.data.message);
              // setFailureAlert(true);
              handleFaliureShow();
              setIsSubmit(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsSubmit(false);
          });
      }
    }
  };
  return (
    <>
      <div className="st-height-70"></div>
      <div className="st-blue-color">
        <Topbar />
      </div>

      <section className="st-page-header st-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="st-page-header-text">
                <h1>Add Post</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="st-blog-wrap st-section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <form
                action="#"
                method="post"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="mt-2">
                  <br></br>
                  <div className="form-group row">
                    <div className="col-md-2">
                      <label>Ad Category</label>

                      <span className="formError">*</span>
                    </div>

                    <div className="col-md-4">
                      <select
                        name="ad_category_id"
                        id="ad_category_id"
                        type="number"
                        maxLength="50"
                        className="form-control ccp per AddrClass"
                        autoComplete="off"
                        tabIndex="39"
                        required=""
                        onBlur={(e) => handleError(e)}
                        onChange={(e) => setcategoryid(e.target.value)}
                        readOnly
                      >
                        <option value="">{ad_category_name}</option>
                        {categories.map(
                          ({ ad_category_id, ad_category_name }) => (
                            <option key={ad_category_id} value={ad_category_id}>
                              {ad_category_name}
                            </option>
                          )
                        )}
                      </select>
                      <span className="formError">
                        {formErrors.ad_category_id}
                      </span>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn btn-success"
                        onClick={(e) => addCategory(e)}
                      >
                        Add Category
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2 required">
                      <label>Brand</label>
                      <span className="formError">*</span>
                    </div>

                    <div className="col-md-4">
                      <input
                        name="brand"
                        id="brand"
                        className="form-control"
                        type="text"
                        placeholder="Brand"
                        title="Enter Brand"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        onBlur={(e) => handleError(e)}
                      />
                      <span className="formError brand">
                        {formErrors.brand}
                      </span>
                    </div>

                    <div className="col-md-2">
                      <label>Product Age</label>

                      <span className="formError">*</span>
                    </div>

                    <div className="col-md-4">
                      <input
                        name="product_age"
                        id="product_age"
                        className="form-control"
                        type="text"
                        placeholder="Product Age"
                        value={product_age}
                        title="Please Enter Product Age"
                        onChange={(e) => setProductAge(e.target.value)}
                        onBlur={(e) => handleError(e)}
                      />
                      <span className="formError">
                        {formErrors.product_age}
                      </span>{" "}
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-2">
                      <label>Image</label>
                      <span className="formError">*</span>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="file"
                        id="default_image"
                        name="default_image"
                        maxLength="6"
                        className="form-control ccp per AddrClass"
                        title="Please Choose Image"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        onBlur={(e) => handleError(e)}
                        onChange={handleChange}
                      />
                      <span className="formError">
                        {formErrors.default_image}
                      </span>
                    </div>
                    <div className="col-md-2 required">
                      <label>Price</label>
                      <span className="formError">*</span>
                    </div>

                    <div className="col-md-4">
                      <input
                        name="offer_price"
                        id="offer_price"
                        className="form-control"
                        type="text"
                        placeholder="Enter Your Price"
                        title="Enter Your Price"
                        value={offer_price}
                        onChange={(e) => setOfferPrice(e.target.value)}
                        onBlur={(e) => handleError(e)}
                      />
                      <span className="formError">{formErrors.price}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <div className="col-md-4 required">
                          <label>Remarks</label>
                        </div>

                        <div className="col-md-8">
                          <textarea
                            name="special_remarks"
                            maxLength={130}
                            id="special_remarks"
                            className="form-control"
                            type="text"
                            placeholder="Enter Your Remarks"
                            style={{ height: "110px" }}
                            title="Enter Your Remarks"
                            value={special_remarks}
                            onChange={(e) => setSpecialRemark(e.target.value)}
                            onBlur={(e) => handleError(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <div className="col-md-4">
                          <label>Additional Image1</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="file"
                            id="additional_image"
                            name="additional_image"
                            maxLength="6"
                            className="form-control ccp per AddrClass"
                            title="Please Choose Image"
                            autoComplete="off"
                            tabIndex="37"
                            required=""
                            onBlur={(e) => handleError(e)}
                            onChange={handleAdditionalChange}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-4">
                          <label>Additional Image2</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="file"
                            id="additional_image1"
                            name="additional_image1"
                            maxLength="6"
                            className="form-control ccp per AddrClass"
                            title="Please Choose Image"
                            autoComplete="off"
                            tabIndex="37"
                            required=""
                            onBlur={(e) => handleError(e)}
                            onChange={handleAdditionalChange1}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-4">
                          <label>Additional Image3</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="file"
                            id="additional_image2"
                            name="additional_image2"
                            maxLength="6"
                            className="form-control ccp per AddrClass"
                            title="Please Choose Image"
                            autoComplete="off"
                            tabIndex="37"
                            required=""
                            onBlur={(e) => handleError(e)}
                            onChange={handleAdditionalChange2}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <FooterRevamp />

      {/* //New Address */}

      <Modal show={showAddAddress} onHide={handleAddAddressClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="post" autoComplete="off">
            <div id="AssetAddressmodel">
              <div className="form-group row">
                <div className="col-md-3 required">
                  <label className="text-right">
                    Ad Category Name<span style={{ color: "red" }}>*</span>
                  </label>
                </div>

                <div className="col-md-9">
                  <input
                    name="ad_category_name"
                    id="ad_category_name"
                    className="form-control"
                    type="text"
                    placeholder="Ad Category Name"
                    value={ad_category_name}
                    title="Please Enter Ad Category Name"
                    onChange={(e) => setAdCategoryName(e.target.value)}
                    onBlur={(e) => handleError(e)}
                  />

                  <span className="formError">
                    {formErrors.ad_category_name}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-3"></div>

                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => {
                      addAdCategory(e);
                    }}
                  >
                    Add Category
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Success Alert */}
      {/* <Modal
        size="md"
        show={successAlert}
        onHide={(e) => setSuccessAlert(false)}
        backdrop="static"
        keyboard={false}
      >
        <div className="alert alert-success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-hidden="true"
            onClick={(e) => setSuccessAlert(false)}
          >
            ×
          </button>
          <span className="glyphicon glyphicon-ok"></span> <strong>Success</strong>
          <hr className="message-inner-separator" />
          <p>{successMessage}</p>
        </div>
      </Modal> */}

      {/* Failure Alert */}
      {/* <Modal
        show={failureAlert}
        onHide={(e) => setFailureAlert(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <div className="alert alert-danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-hidden="true"
            onClick={(e) => setFailureAlert(false)}
          >
            ×
          </button>
          <span className="glyphicon glyphicon-hand-right"></span>{" "}
          <strong>Failure</strong>
          <hr className="message-inner-separator" />
          <p>{failureMessage}</p>
        </div>
      </Modal> */}

      <ModalCommon
        show={successShow || failureShow}
        onHide={handleClose}
      >
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
              <p>
                {message}
              </p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </>
  );
};

export default AddPost;
