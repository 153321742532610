import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Success = (props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto mt-5">
            <div className="payment">
              <div className="payment_header">
                <div className="check">
                  <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                </div>
              </div>
              <div className="content">
                <h1 className="mt-2">Payment Success !</h1>
                <p className="mt-2">
                  We have Successfully Received Your Payment and We will Assign
                  a Technician Shortly{" "}
                </p>
                <Link to={"/"} className="Link mt-2">
                  Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Success;
