import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import Topbar from "../Home/Topbar";
import Contact from "../Home/Contact";
import postCodeImg from "../assets/revamp-css/assets/images/services/post-code.png";
import cleanHouseImg from "../assets/revamp-css/assets/images/services/clean-house.png";
import welcomeCallImg from "../assets/revamp-css/assets/images/services/welcome-call.png";
import cleanPlanImg from "../assets/revamp-css/assets/images/services/cleaning-plan.png";
import airconInstallPostImg from "../assets/revamp-css/assets/images/post/air-ins-ser.png";
import service_cards from "../assets/revamp-css/assets/images/services/service_cardsAI-1.jpg";
import service_cards2 from "../assets/revamp-css/assets/images/services/service_cardsAI-2.jpg";
import service_cards3 from "../assets/revamp-css/assets/images/services/service_cardsAI-3.jpg";
import service_cards4 from "../assets/revamp-css/assets/images/services/service_cardsAI-4.jpg";
import card_svg from "../assets/revamp-css/assets/images/services/card_svg.png";
import service_booking_man from "../assets/revamp-css/assets/images/services/service_booking_man.png";
import serviceA1 from "../assets/revamp-css/assets/images/services/A-I-01.jpg";
import serviceA2 from "../assets/revamp-css/assets/images/services/A-I-02.jpg";
import serviceA3 from "../assets/revamp-css/assets/images/services/A-I-03.jpg";
import serviceA4 from "../assets/revamp-css/assets/images/services/A-I-04.jpg";
import serviceA5 from "../assets/revamp-css/assets/images/services/A-I-05.jpg";
import serviceA6 from "../assets/revamp-css/assets/images/services/A-I-06.jpg";
import serviceA7 from "../assets/revamp-css/assets/images/services/A-I-07.jpg";
import serviceA8 from "../assets/revamp-css/assets/images/services/A-I-08.jpg";
import serviceA9 from "../assets/revamp-css/assets/images/services/A-I-09.jpg";
import serviceA10 from "../assets/revamp-css/assets/images/services/A-I-10.jpg";
import Axios from "axios";
import ModalCommon from "../Modal/modal";
import FooterRevamp from "../FooterRevamp";
import GetCoollahApp from "../Home/GetCoollahApp";
import FAQ from "../Home/Faq";
import ImageFallback from "../ImageFallback";

const AirconInstallations = () => {
  const navigate = useNavigate();
  const getCategoryId = sessionStorage.getItem("category_id");
  const [postalCode, setPostalCode] = useState("");
  const [postCodePro, setPostCodePro] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const footerRef = useRef();
  const pincodeRef = useRef();
  const [data, setData] = React.useState(null);
  const [membertype, setMemberType] = useState("Non Commercial");

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  const handleChangeType = (type) => {
    setMemberType(type);
  };

  useEffect(() => {
    footerRef?.current?.scrollIntoView();
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
          MemberType: membertype,
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        if (response?.data?.is_successful) {
          setData(response.data?.result?.[0]?.services);
        }
      })
      .catch((error) => {});
  }, [membertype]);

  useEffect(() => {}, [sessionStorage.getItem("product_name")]);
  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        footerRef.current.scrollIntoView();
        // navigate("/CategoryService");
      }
    }
    // navigate("/Enquiry" + "/AirconInstallations")
  };

  // const handleClickPro = () => {
  //   setFormErrors(validate(postCodePro));
  //   sessionStorage.setItem("Pincode", postalCode);
  //   if (postalCode !== "" && postalCode.length === 6) {
  //     if (sessionStorage.getItem("product_name") !== null) {
  //       navigate("/Booking");
  //     } else {
  //       footerRef.current.scrollIntoView();
  //       // navigate("/CategoryService");
  //     }
  //   }
  // };

  const { state } = useLocation();
  useEffect(() => {
    if (state?.value === "home") {
      setProduct(sessionStorage.getItem("product_name"));
      pincodeRef.current.scrollIntoView();
    }
  }, [sessionStorage.getItem("product_name")]);

  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.setItem("product_image", data.product_image);
    sessionStorage.setItem("remarks", data.remarks);
    setProduct(data.product_name);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    pincodeRef.current.scrollIntoView();
  };

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            handleFaliureShow();
            setPostalCode("");
            setPostCodePro("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  };

  const loadCatgories = () => {
    const headers = {
      "content-type": "multipart/form-data",
      access_token: sessionStorage.getItem("access_token"),
    };
    API.get("load_all_categories", { headers }).then((resp) => {
      if (resp?.data?.is_successful) {
        setServices(resp.data?.result);
      }
      sessionStorage.setItem("menus", JSON.stringify(resp.data.result));
    });
  };

  const handleClickCategories = (category_id, category_name) => {
    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem("category_name", category_name);
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("product_name");
  };

  useEffect(() => {
    loadCatgories();
  }, []);

  const BookNowSec = () => {
    return (
      <Fragment>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA1} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">Daikin I-Smile Eco Series Inverter</h4>
              <h5>
                System 2 - <span>$2,180</span>
              </h5>
              <p>MKM50VVMG / CTKM25VVMG*2</p>
              <h5>
                System 3 - <span>$2,680</span>
              </h5>
              <p>MKM50VVMG / CTKM25VVMG*3</p>
              <h5>
                System 4 - <span>$4,010</span>
              </h5>
              <p>MKM85VVMG / CTKM25VVMG*3 / CTKM71VVMG*1</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                  Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA2} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">Daikin 3 Ticks Ezi Series R32</h4>
              <h5>
                System 2 - <span>$1,950</span>
              </h5>
              <p>MKC50SVMG / CTKC25WVMG*2</p>
              <h5>
                System 3 - <span>$2,450</span>
              </h5>
              <p>MKC50SVMG / CTKC25WVMG*3</p>
              <h5>
                System 3 - <span>$2,930</span>
              </h5>
              <p>MKC70WVMG / CTKC25WVMG*2 / CTKC50MVMG*1</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA3} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">Mitsubishi Starmex 5 Ticks FP Series R32</h4>
              <h5>
                System 2 - <span>$1,980</span>
              </h5>
              <p>MXY-2H20VF-SG1, MSXY-FP10VG-SG1*2</p>
              <h5>
                System 3 - <span>$2,810</span>
              </h5>
              <p>MXY-3H28VG-SG1, MSXY-FP10VG-SG1*3</p>
              <h5>
                System 4 - <span>$3,610</span>
              </h5>
              <p>MXY-4H33VG-SG1, MSXY-FP10VG-SG1*3, MSXY-FP24VG-SG1</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA4} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">Midea 5 Ticks R32 Series</h4>
              <h5>
                System 2 - <span>$1,930</span>
              </h5>
              <p>MAE-2M18E, MSEID-09*2</p>
              <h5>
                System 3 - <span>$2,490</span>
              </h5>
              <p>MAE-3M25E, MSEID-09*3</p>
              <h5>
                System 4 - <span>$3,100</span>
              </h5>
              <p>MAE-4M30E, MSEID-09*3, MSEID-18*1</p>

              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA5} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">
                Panasonic R32 Multi Split U Series (Standard)
              </h4>
              <h5>
                System 2 - <span>$1,740</span>
              </h5>
              <p>CU-2U18YBZ / CS-MPU9YKZ*2</p>
              <h5>
                System 3 - <span>$2,450</span>
              </h5>
              <p>CU-3U27YBZ / CS-MPU9YKZ*3</p>
              <h5>
                System 4 - <span>$3,130</span>
              </h5>
              <p>CU-4U28YBZ/ CS-MPU9YKZ*3, CS-MPU18YKZ*1</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA6} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">
                Panasonic R32 Multi Split XU Series (X-Premium)
              </h4>
              <h5>
                System 2 - <span>$1,910</span>
              </h5>
              <p>CU-2XU20YBZ, CS-XU9XKZW*2</p>
              <h5>
                System 3 - <span>$2,730</span>
              </h5>
              <p>CU-3XU28YBZ, CS-Xu9XKZW*3</p>
              <h5>
                System 4 - <span>$3,480</span>
              </h5>
              <p>CU-4XU33YBZ, CS-XU9XKZW*3, CS-XU18XKZW</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA7} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">LG Artcool Series R32 Wifi Build In</h4>
              <h5>
                System 2 - <span>$2,290</span>
              </h5>
              <p>Z3UQ18GFA0 / AMNC09GDJR0*2</p>
              <h5>
                System 3 - <span>$2,830</span>
              </h5>
              <p>Z3UQ18GFA0 / AMNC09GDJR0*3</p>
              <h5>
                System 4 - <span>$3,800</span>
              </h5>
              <p>Z4UQ28GFA0 / AMNC09GDJR0*3, AMNC24GDKR0*1</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA8} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">Toshiba 5 Ticks Youme Series R32</h4>
              <h5>
                System 2 - <span>$2,080</span>
              </h5>
              <p>RAS-2M21U2ACVG-SG/RAS-M10U2KCVG-SG1*2</p>
              <h5>
                System 3 - <span>$2,750</span>
              </h5>
              <p>RAS-3M31U2ACVG-SG/RAS-M10U2KCVG-SG1*3</p>
              <h5>
                System 4 - <span>$3,750</span>
              </h5>
              <p>RAS-4M41U2ACVG-SG/ RAS-M10U2KCVG-SG1*3/ RAS-M24U2KCVG-SG1*1</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img src={serviceA9} alt="aircon-service_list" className="mb-2" />
              <h4 className="mb-2">
                Mitsubishi Heavy Industries Multi Split SCM Series
              </h4>
              <h5>
                System 2 - <span>$2,270</span>
              </h5>
              <p>SCM60YS-W / SRK10YVS-W7*2</p>
              <h5>
                System 3 - <span>$2,660</span>
              </h5>
              <p>SCM60YS-W / SRK10YVS-W7*3</p>
              <h5>
                System 4 - <span>$3,450</span>
              </h5>
              <p>SCM80YS-W / SRK10YVS-W7*3, SRK24YVS-W7</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 list-itema">
          <div className="bg-white mb-4">
            <div className="p-3 service-list">
              <img
                src={serviceA10}
                alt="aircon-service_list"
                className="mb-2"
              />
              <h4 className="mb-2">Haier Flexi Series R32 5 Ticks</h4>
              <h5>
                System 2 - <span>$1,860</span>
              </h5>
              <p>4U65RH1SRA / AS25S2SF2FA*2</p>
              <h5>
                System 3 - <span>$2,220</span>
              </h5>
              <p>4U65RH1SRA / AS25S2SF2FA*3</p>
              <h5>
                System 4 - <span>$2,940</span>
              </h5>
              <p>4U80SH1SRA / AS25S2SF2FA*3 / AS71S2SF2FA</p>
              <div className="bo-set">
                <Link to={`/Enquiry/AirconInstallations`} className="button-boo">
                Enquire Now <i className="ml-2 fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Topbar />
      <div className="container-fluid over-hid" ref={pincodeRef}>
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_sec_wrapper">
              <div className="coollah_service_main_content">
                <div className="coollah_service_realtive_div">
                  <div className="coollah_service_absolute_content">
                    <h1 className="text-nowrap">AirCon Installations</h1>
                    <p>
                      Our Installation Services provide top-notch meticulous
                      planning and a keen eye for details, offering distinct
                      customer service.
                    </p>
                    {/* <form className="coollah_service_main_form" action="">
                      <input
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                        onBlur={(e) => checkPincode(e)}
                      />
                      <button type="button" onClick={handleClick}>
                        Book Now
                      </button>
                    </form>
                    <span className="formError">{formErrors.postalCode}</span>
                    <div ref={footerRef}></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid" ref={footerRef}>
        <div className="row">
          <div className="col col-12 col-lg-3 order-lg-1 order-2">
            <div className="coollah_service_lhs sidebar-sicky">
              <div className="coollah_service_main_page">
                <p>Select Service</p>
                <div className="collah_services_list_wrapp">
                  <div className="row">
                    {services.map((ele, ind) => {
                      const { category_name, category_image, category_id } =
                        ele;
                      return (
                        <div
                          onClick={() =>
                            handleClickCategories(category_id, category_name)
                          }
                          key={ind}
                          className="col col-6 col-md-4"
                        >
                          <Link
                            to={
                              "/" +
                              category_name
                                .replace(/\s+/g, "")
                                .replace(/-/g, "")
                                .replace("/", "")
                            }
                          >
                            <div className="coollah_service_explain">
                              <ImageFallback
                                src={category_image}
                                alt={"service_img"}
                              />
                              <p>{category_name}</p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="coollah_service_aircon_list">
                <img src={airconInstallPostImg} alt="aircon-service_list" />
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-9 order-lg-2 order-1">
            <div className="row">
              <div className="col-md-12">
                <section className="service-list1">
                  <div className="container">
                    <div className="row list-who">{BookNowSec()}</div>
                  </div>
                </section>

                {/* <div className="coollah_rhs">
                  <div className="coollah_service_services">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li
                        className="nav-item"
                        onClick={() => handleChangeType("Non Commercial")}
                        role="presentation"
                      >
                        <button
                          className="nav-link active residental"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          RESIDENTAL SERVICE
                        </button>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => handleChangeType("B2BCustomer")}
                        role="presentation"
                      >
                        <button
                          className="nav-link commercial"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          COMMERCIAL SERVICE
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="coollah_residential_height_wrapper">
                          <div className="row">
                            {data?.length === 0 && (
                              <div className="col-md-12">
                                <div className="text-center my-5">
                                  <h3>No Services Found</h3>
                                </div>
                              </div>
                            )}
                            {data?.map((data, ind) => (
                              <div className="col-md-12" key={ind}>
                                <div className="coollah_service_chemical">
                                  <div className="row">
                                    <div className="col col-md-9">
                                      <div className="coollah_service_chemical_content">
                                        <h3> {data.product_name}</h3>
                                        <span className="heading_border"></span>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: data.remarks,
                                          }}
                                        ></p>
                                        <p className="price">
                                          Starts at <span>${data.price}</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col col-md-3">
                                      <div className="coollah_service_chem_img_container">
                                        <ImageFallback
                                          src={data.product_image}
                                          alt={"chemical_service_img"}
                                        />
                                        <button
                                          onClick={(e) => handleBook(data)}
                                        >
                                          Book Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {data?.length === 0 && (
                              <div className="col-md-12">
                                <div className="text-center my-5">
                                  <h3>No Services Found</h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="coollah_residential_height_wrapper">
                          <div className="row">
                            {data?.map((data, ind) => (
                              <div className="col-md-12" key={ind}>
                                <div className="coollah_service_chemical">
                                  <div className="row">
                                    <div className="col col-md-9">
                                      <div className="coollah_service_chemical_content">
                                        <h3> {data.product_name}</h3>
                                        <span className="heading_border"></span>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: data.remarks,
                                          }}
                                        ></p>
                                        <p className="price">
                                          Starts at <span>${data.price}</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col col-md-3">
                                      <div className="coollah_service_chem_img_container">
                                        <ImageFallback
                                          src={data.product_image}
                                          alt={"chemical_service_img"}
                                        />
                                        <button
                                          onClick={(e) => handleBook(data)}
                                        >
                                          Book Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {data?.length === 0 && (
                              <div className="col-md-12">
                                <div className="text-center my-5">
                                  <h3>No Services Found</h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_over">
              <h2>What does Our Aircon Installation Service Cover?</h2>
              <div className="coollah_service_doubled_border">
                <span className="border_first"></span>
                <span className="border_second"></span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="coollah_service_card_sec">
                <div className="col-lg-3 col-md-12 ">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                Through our expert consultation, we help you
                                determine the most suitable air conditioning
                                system for your space.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                {" "}
                                We perform a complete assessment of your cooling
                                needs, considering factors such as room size,
                                insulation, and usage patterns.
                              </li>
                            </div>
                            {/* <div className="coollah_service_card_list">
                              <img
                                className="collah_service_card_img"
                                src={card_svg}
                                alt="list_icon"
                              />
                              <li>
                                Provide quotation on repair or replacement of
                                faulty parts
                              </li>
                            </div> */}
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Consultation and Assessment</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards2}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                We offer precise installation by skilled
                                technicians to ensure optimal performance and
                                longevity.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                We ensure proper placement and mounting of the
                                indoor and outdoor units, including refrigerant
                                line installation.
                              </li>
                            </div>

                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Professional Installation</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards3}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              {/* <img
                                className="collah_service_card_img"
                                src={card_svg}
                                alt="list_icon"
                              /> */}
                              <li>
                                Our high-quality wiring and electrical
                                connections are expertly handled to guarantee
                                safe and reliable operation.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                We offer compliance with electrical codes and
                                safety standards for worry-free usage.
                              </li>
                            </div>

                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Electrical Connections</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards4}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                Our detailed guidance on operating and
                                maintaining your new air conditioner will help
                                you reap maximum comfort.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                We also advise on best practices for maximizing
                                energy efficiency and lifespan.
                              </li>
                            </div>

                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Instruction and Guidance</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row coollah_service_book">
          <div className="col col-12 col-md-12 col-lg-6">
            <div className="coollah_service_booking_man">
              <img src={service_booking_man} alt="Service_bboking_man_img" />
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-4">
            <div className="coollah_service_connect_form">
              <p>Get Your Services Professionally</p>
              <form className="coollah_service_booking_form">
                <input
                  name="pincode"
                  pattern="[0-9]*"
                  maxLength="6"
                  placeholder="Please Enter Postal Code"
                  title="Please Enter Postal Code"
                  autoComplete="off"
                  tabIndex="37"
                  required=""
                  // value={postCodePro}
                  // onChange={(e) =>
                  //   setPostCodePro((v) =>
                  //     e.target.validity.valid ? e.target.value : v
                  //   )
                  // }
                  value={postalCode}
                  onChange={(e) =>
                    setPostalCode((v) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                  onBlur={(e) => checkPincode(e)}
                />
                <button type="button" onClick={handleClick}>
                Enquire Now
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="coollah_service_circles_mobile">
          <div className="row ">
            <div className="col-md-12">
              <div className="coollah_service_started">
                <h2>How to Get Started</h2>
                <div className="coollah_service_doubled_border">
                  <span className="border_first"></span>
                  <span className="border_second"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="coollah_service_circles">
          <div className="row">
              <div className="bg_sec">
                <div className="col-md-12">
                  <div className="bg-dot"></div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_location">
                  <img src={postCodeImg} alt="Location_image" />
                  <div className="coollah_service_content">
                    <h3>Enter your postcode</h3>
                    <p>Begin your journey with Coollah's service by entering your pin code on our application. Instantly check service availability in your area and access service plans tailored to your location, ensuring a seamless and personalized experience.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_calender">
                  <img src={cleanPlanImg} alt="Calender_image" />
                  <div className="coollah_service_content">
                    <h3>Choose your Service plan</h3>
                    <p>Select the service plan that best suits your needs, whether it's standard service, deep service, or specialized services. Each plan comes with detailed descriptions, empowering you to make an informed choice. Choose the frequency that aligns with your lifestyle, from one-time service to convenient recurring schedules.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_telecalling">
                  <img src={welcomeCallImg} alt="Telecalling_image" />
                  <div className="coollah_service_content">
                    <h3>Receive a welcome call</h3>
                    <p>After choosing your service plan, our dedicatedservice team will reach out with a warm welcome call. Confirm service details, schedule your service appointment, and have any questions addressed. We strive for a personalized and hassle-free experience, ensuring your complete satisfaction.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_success">
                  <img src={cleanHouseImg} alt="Success_logo" />
                  <div className="coollah_service_content">
                    <h3>Enjoy Our Service</h3>
                    <p>With all administrative details sorted, it's time to savor the convenience of our top-notch service. Our highly trained professionals arrive at your scheduled time, equipped with tools and materials to transform your living space. Sit back, relax, and witness your home refreshed and revitalized.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="coollah_circle_connector_waves">
              <img
                src="../assets/images/services/connector.png"
                alt="Connectin_waves_image"
              />
            </div> */}
          </div>
        </div>
      </div>
      <GetCoollahApp />
      <FAQ categoryId={getCategoryId} />
      <Contact />
      <FooterRevamp />
      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
              <h5 className="mb-2">Not Found</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </Fragment>
  );
};

export default AirconInstallations;
