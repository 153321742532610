import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../API/API";
import Topbar from "../Home/Topbar";
import Banner from "../assets/img/service-bg-2.jpg";
import GasTop1 from "../assets/img/gasTop-img-1.jpg";
import GasTop5 from "../assets/img/gasTop-img-5.jpg";
import GasTop6 from "../assets/img/gasTop-img-6.jpg";
import GasTop7 from "../assets/img/gasTop-img-7.jpg";

import Start1 from "../assets/img/start1.png";
import Start2 from "../assets/img/start2.png";
import Start3 from "../assets/img/start3.png";
import Start4 from "../assets/img/start4.png";
import Contact from "../Home/Contact";
import FooterRevamp from "../FooterRevamp";

const AirConGasTopup = () => {
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [data, setData] = React.useState(null);
  useEffect(() => {
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setData(response.data);
        console.log("data", response);
      })
      .catch((error) => {});
  }, []);
  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    navigate("/Pincode");
  };
  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        navigate("/CategoryService");
      }
    }
  };
  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  };
  return (
    <div className="st-blue-color">
      <Topbar />
      <div className="st-content" style={{ marginBottom: "412px" }}>
        <div className="st-hero-slide st-style2 st-flex">
          <div className="container">
            <div className="st-hero-text st-style1 st-color1">
              <h1 className="st-hero-title">
                AirCon Gas Topup
                <br />
              </h1>

              <div className="st-hero-subtitle">
                Spend your time on the most important things in life.
                <br />
                Leave the home cleaning to Coollah, Singapore's No. 1<br />
                professional home cleaning service!
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="st-newsletter">
                    <form className="mailchimp st-subscribe-form" action="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                      />
                      <button
                        type="button"
                        id="subscribe-button"
                        className="st-newsletter-btn"
                        onClick={handleClick}
                      >
                        Enquire Now
                      </button>
                      <span className="formError">{formErrors.postalCode}</span>
                      {}
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label
                        className="subscription-label"
                        htmlFor="subscriber-email"
                      ></label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="st-hero-img"></div>
          <div className="st-circla-animation">
            <div className="st-circle st-circle-first"></div>
            <div className="st-circle st-circle-second"></div>
          </div>

          <div className="st-wave-wrap">
            <div className="st-wave">
              <div
                className="st-wave-in"
                style={{ backgroundImage: `url(${Banner})` }}
              ></div>
              <div id="color-overlay"></div>
            </div>
          </div>
        </div>

        <div className="lrblock hero-slied">
          <ul className="flexCenter">
            <li>
              <div className="details st-hero-text st-style1 st-color1">
                <h1 className="st-hero-title">AirCon Services-Ceiling</h1>
                <div className="st-hero-subtitle">
                  Spend your time on the most important things in life.
                  <br />
                  Leave the home cleaning to Coollah, Singapore's No. 1<br />
                  professional home cleaning service!
                </div>

                <div className="st-newsletter">
                  <form className="mailchimp st-subscribe-form" action="">
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      pattern="[0-9]*"
                      maxLength="6"
                      placeholder="Please Enter Postal Code"
                      className="form-control ccp per AddrClass"
                      title="Please Enter Postal Code"
                      autoComplete="off"
                      tabIndex="37"
                      required=""
                      value={postalCode}
                      onChange={(e) =>
                        setPostalCode((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                    />
                    <button
                      type="button"
                      id="subscribe-button"
                      className="st-newsletter-btn"
                      onClick={handleClick}
                    >
                      Enquire Now
                    </button>
                    <span className="formError">{formErrors.postalCode}</span>
                    {}
                    <h5 className="subscription-success"> .</h5>
                    <h5 className="subscription-error"> .</h5>
                    <label
                      className="subscription-label"
                      htmlFor="subscriber-email"
                    ></label>
                  </form>
                </div>
              </div>
            </li>
            <li>
              <div className="figure">
                <img src={Banner} alt="img" className="img-responsive" />
              </div>
            </li>
          </ul>
        </div>

        <div className="st-about-wrap st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <h4>Why You Should Get a Gas Top Up for Your AC Unit</h4>
                <div className="">
                  <p className="text-justify">
                    Make sure that the gas levels on your aircon unit are at the
                    optimum levels is important if you wan to make sure that you
                    get the most from your aircon unit. Here are 3 reasons why
                    you want to make sure that you get regular gas top ups for
                    your Singapore home's AC unit.
                  </p>

                  <div className="tabs">
                    <div className="tab">
                      <input type="radio" id="rd1" name="rd" />
                      <label className="tab-label" htmlFor="rd1">
                        1.More Efficient
                      </label>
                      <div className="tab-content">
                        An AC unit can not work at maximum efficiency levels if
                        there is not enough gas. Therefore, you will want to get
                        regular gas top up procedures done on your AC unit if
                        you want to make sure that it keeps working at optimum
                        levels. No only will a gas top up help the AC unit to
                        work better, you may also see a reduction in your
                        electricity bills as a result of the fact that the
                        aircon unit is working at maximum performance levels.
                      </div>
                    </div>
                    <div className="tab">
                      <input type="radio" id="rd2" name="rd" />
                      <label className="tab-label" htmlFor="rd2">
                        2.Keep Your Evaporator Coiling From Freezing
                      </label>
                      <div className="tab-content">
                        The evaporator coil is one of the most important
                        components of your air conditioner. The coil needs to be
                        properly maintained so that your aircon unit can
                        continue to function normally. Failing to provide your
                        air conditioner unit with enough gas can cause the
                        evaporator coil to freeze which will damage your air
                        conditioner. Making sure that your evaporator stays in
                        good working order will help your air conditioner to
                        last longer.
                      </div>
                    </div>
                    <div className="tab">
                      <input type="radio" id="rd3" name="rd" />
                      <label className="tab-label" htmlFor="rd3">
                        3.Stop Water And Freon Leaks2
                      </label>
                      <div className="tab-content">
                        Many air conditioners suffer from problems such as
                        leaking freon or water due to the lack of air
                        conditioning gas. Water leaks because the vaporisation
                        process that takes place inside the air conditioner does
                        not have enough gas present to complete as it normally
                        should. In addition, freon make leak from an air
                        conditioner due to the lack of aircon gas. If freon is
                        leaking out, you should make sure that the spill is
                        cleaned up immediately by a professional because freon
                        leaks are dangerous for humans and the environment.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <img src={GasTop1} alt="img" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="st-about-wrap st-section bg-cray">
          <div className="container-fluid">
            <h2 className="mb-3 text-center text-red">Our Services</h2>
            <div className="row">
              {data?.result[0]?.services.map((data) => (
                <>
                  <div className="col-lg-3">
                    <div
                      className="st-iconbox st-style1 st-style3 wow fadeInUp"
                      data-wow-duration="0.8s"
                      data-wow-delay="0.2s"
                    >
                      <h5 className="st-iconbox-title font-bold">
                        {data.product_name}
                      </h5>
                      <p>{data.price}</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.remarks }}
                        className="st-iconbox-text"
                      ></div>
                      <div className="text-right">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(e) => handleBook(data)}
                        >
                          Enquire Now
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </section>

        <section className="st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="">
                  <h2 className="mb-3">
                    Get your home services professionally
                  </h2>

                  <form className="mailchimp st-subscribe-form" action="">
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      pattern="[0-9]*"
                      maxLength="6"
                      className="form-control ccp per AddrClass"
                      placeholder="Please Enter Postal Code"
                      title="Please Enter Postal Code"
                      autoComplete="off"
                      tabIndex="37"
                      required=""
                      value={postalCode}
                      onChange={(e) =>
                        setPostalCode((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                    />
                    <button
                      type="button"
                      id="subscribe-button"
                      className="st-newsletter-btn"
                      onClick={handleClick}
                    >
                      Enquire Now
                    </button>
                    <span className="formError">{formErrors.postalCode}</span>
                    {}
                    <h5 className="subscription-success"> .</h5>
                    <h5 className="subscription-error"> .</h5>
                    <label
                      className="subscription-label"
                      htmlFor="subscriber-email"
                    ></label>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="st-about-wrap st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <img src={GasTop5} alt="img" className="img-responsive" />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="m-5">
                  <h4>How refrigerant work in Air Conditioner</h4>
                  <p>
                    Without refrigerant, there would be no air conditioning,
                    refrigeration or freezing technology.
                  </p>
                  <p>
                    Air conditioners contain a type of gas called "refrigerant"
                    that absorbs heat from indoor air through coils made with
                    copper and converts it to liquid form in high-pressure
                    conditions outside the AC unit. The fan system blows hot
                    outdoor air over these cooling units which then releases
                    cool foggy vapor back into the house for ultimate comfort!
                  </p>
                  <p>
                    The refrigerant is then cooled and turns back into a
                    low-pressure gas. Another fan located inside the home blows
                    air over these cold coils to distribute this cool,
                    refreshing feeling throughout your building.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p>
                  In most cases, cooling chemicals inside the refrigerant of an
                  air-conditioner does not get used up throughout the service
                  life of the unit.
                </p>
                <p>
                  But, there are cases where the refrigerant storage leaks and
                  the chemical seeps out slowly emptying the container.
                </p>
                <p>
                  The aircon technician first takes care of the hole or damage
                  causing the leak of the substance which is followed by topping
                  up to fill the newly-repaired container with cooling gas.
                </p>
                <p>
                  This procedure keeps the cooling process going for your
                  air-conditioner.
                </p>
                <p>
                  Related Read : Misconceptions Surrounding Aircon Gas Top Up
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="st-about-wrap st-section bg-cray">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="m-1">
                  <h4>Why It Is Important to Check and Top Up Gas?</h4>
                  <p>
                    Gas leaks happen to air-conditioners and will directly
                    affect the unit’s efficiency.
                  </p>
                  <p>
                    Aside from your room not getting cold enough, the compressor
                    does not benefit from the cooling effect of the gas which
                    eventually causes it to overheat and fail.
                  </p>
                  <p>
                    The evaporating coils receive the cold gas from the
                    refrigerant and if it’s not cold the evaporator will stop
                    operating.
                  </p>
                  <p>
                    Refrigerant chemical leaking from your AC can be harmful to
                    the environment if it gets to the water system.
                  </p>
                  <p>
                    It is important to engage a licensed aircon servicing
                    company to help you check your aircon gas level and fix the
                    gas leak problem. We support various type of aircon brands
                    including Panasonic, LG, Fujitsu, Toshiba, Mitsubishi and
                    Daikin.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="">
                  <img src={GasTop6} alt="img" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="st-about-wrap st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <img src={GasTop7} alt="img" className="img-responsive" />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="m-1">
                  <h4>How To Check & Top Up Aircon Gas?</h4>
                  <p>
                    Aircon gas top-ups can only be done effectively and safely
                    by a professional technician.
                  </p>
                  <p>
                    They use a gauge to check for the refrigerant level inside
                    your air-conditioning unit. It will tell them the level of
                    gas that needs to be filled into the refrigerant storage.
                  </p>
                  <p>
                    A refrigerant tank will be used to charge up gas into the
                    air-conditioner so that the gas levels go back to normal.
                  </p>
                  <p>
                    This will be tested back again using the gas gauge to ensure
                    that your AC will have enough refrigerant inside.
                  </p>
                  <p>
                    Your aircon technician will only use the right type of gas
                    to refill your refrigerant storage.
                  </p>
                  <p>
                    It is not safe to use an R410 tank on an R22 tank and vice
                    versa.
                  </p>
                  <p>
                    Aside from that, the technician will also recommend repairs
                    to the refrigerant unit if it has damaged so that it won’t
                    leak anymore.
                  </p>
                  <p>
                    Other faulty components need to be checked to ensure the
                    safety of the unit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="st-about-wrap st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4>How To Get Started</h4>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start1} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>1. Enter your postcode</h5>
                </div>
                <p>
                  Enter your postal code to check the availability of cleaners
                  in your area.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start2} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>2. Choose your cleaning plan</h5>
                </div>
                <p>
                  Select your preferred cleaning plan and secure your booking.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start3} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>3. Receive a welcome call</h5>
                </div>
                <p>
                  Your dedicated Home Advisor will be in touch with you shortly
                  to support all your needs.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start4} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>4. Enjoy your clean house</h5>
                </div>
                <p>
                  Enjoy a sparkling home and share your feedback with us for
                  constant quality improvement.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Contact />
      </div>
      <FooterRevamp />
    </div>
  );
};

export default AirConGasTopup;
