import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef, Fragment } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import FooterRevamp from "../FooterRevamp";
import Topbar from "../Home/Topbar";
import postCodeImg from "../assets/revamp-css/assets/images/services/post-code.png";
import cleanHouseImg from "../assets/revamp-css/assets/images/services/clean-house.png";
import welcomeCallImg from "../assets/revamp-css/assets/images/services/welcome-call.png";
import cleanPlanImg from "../assets/revamp-css/assets/images/services/cleaning-plan.png";
import airconServImg from "../assets/revamp-css/assets/images/post/air-ser.png";
import service_cards from "../assets/revamp-css/assets/images/services/service_cardsA-1.jpg";
import service_cards2 from "../assets/revamp-css/assets/images/services/service_cardsA-2.jpg";
import service_cards3 from "../assets/revamp-css/assets/images/services/service_cardsA-3.jpg";
import service_cards4 from "../assets/revamp-css/assets/images/services/service_cardsA-4.jpg";
import card_svg from "../assets/revamp-css/assets/images/services/card_svg.png";
import service_booking_man from "../assets/revamp-css/assets/images/services/service_booking_man.png";
import Contact from "../Home/Contact";
import Axios from "axios";
import ModalCommon from "../Modal/modal";
import FAQ from "../Home/Faq";
import GetCoollahApp from "../Home/GetCoollahApp";
import ImageFallback from "../ImageFallback";
// import logo from "../assets/img/logo.png";
// import MapLocation from "./MapLocation";

const AirConServicesRevamp = () => {
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [data, setData] = React.useState(null);
  const footerRef = useRef();
  const pincodeRef = useRef();
  const [membertype, setMemberType] = useState("Non Commercial");
  const getCategoryId = sessionStorage.getItem("category_id");

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  const handleChangeType = (type) => {
    setMemberType(type);
  };

  useEffect(() => {
    footerRef?.current?.scrollIntoView();
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
          MemberType: membertype,
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        if (response?.data?.is_successful) {
          setData(response.data?.result?.[0]?.services);
        }
      })
      .catch((error) => {});
  }, [membertype]);

  const { state } = useLocation();
  useEffect(() => {
    if (state?.value === "home") {
      setProduct(sessionStorage.getItem("product_name"));
      pincodeRef?.current?.scrollIntoView();
    }
  }, [sessionStorage.getItem("product_name")]);

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            // setFailureAlert(true);
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const handleClick = () => {
    setFormErrors(validate(postalCode));

    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        footerRef.current.scrollIntoView();
      }
    }
  };

  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    setProduct(data.product_name);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.setItem("product_image", data.product_image);
    sessionStorage.setItem("remarks", data.remarks);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    pincodeRef.current.scrollIntoView();
    // navigate('/Pincode');
  };

  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (postalCode.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            // setFailureAlert(true);
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", postalCode);
          }
        });
      }
    }
    return errors;
  };

  const loadCatgories = () => {
    const headers = {
      "content-type": "multipart/form-data",
      access_token: sessionStorage.getItem("access_token"),
    };
    API.get("load_all_categories", { headers }).then((resp) => {
      if (resp?.data?.is_successful) {
        setServices(resp.data?.result);
      }
      sessionStorage.setItem("menus", JSON.stringify(resp.data.result));
    });
  };

  const handleClickCategories = (category_id, category_name) => {
    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem("category_name", category_name);
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("product_name");
  };

  useEffect(() => {
    loadCatgories();
  }, []);

  return (
    <Fragment>
      <Topbar />
      <div className="container-fluid over-hid" ref={pincodeRef}>
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_sec_wrapper2">
              <div className="coollah_service_main_content">
                <div className="coollah_service_realtive_div">
                  <div className="coollah_service_absolute_content">
                    <h1>Aircon Services</h1>
                    <p>
                      Experience our comprehensive Aircon Services ranging from
                      inspection, expert maintenance, and specialized solutions
                      to ensure optimized performance and ultimate comfort.
                    </p>
                    <form className="coollah_service_main_form" action="">
                      <input
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                        onBlur={(e) => checkPincode(e)}
                      />
                      <button type="button" onClick={handleClick}>
                        Book Now
                      </button>
                    </form>
                    <span className="formError">{formErrors.postalCode}</span>
                    <div ref={footerRef}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" ref={footerRef}>
        <div className="row">
          <div className="col col-12 col-lg-3 order-lg-1 order-2">
            <div className="coollah_service_lhs">
              <div className="coollah_service_main_page">
                <p>Select Service</p>
                <div className="collah_services_list_wrapp">
                  <div className="row">
                    {services.map((ele, ind) => {
                      const { category_name, category_image, category_id } =
                        ele;
                      return (
                        <div
                          onClick={() =>
                            handleClickCategories(category_id, category_name)
                          }
                          key={ind}
                          className="col col-6 col-md-4"
                        >
                          <Link
                            to={
                              "/" +
                              category_name
                                .replace(/\s+/g, "")
                                .replace(/-/g, "")
                                .replace("/", "")
                            }
                          >
                            <div className="coollah_service_explain">
                              <ImageFallback
                                src={category_image}
                                alt={"service_img"}
                              />
                              <p>{category_name}</p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="coollah_service_aircon_list">
                <img src={airconServImg} alt="aircon-service_list" />
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-9 order-lg-2 order-1">
            <div className="row">
              <div className="col-md-12">
                <div className="coollah_rhs">
                  <div className="coollah_service_services">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li
                        className="nav-item"
                        onClick={() => handleChangeType("Non Commercial")}
                        role="presentation"
                      >
                        <button
                          className="nav-link active residental"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          RESIDENTIAL SERVICE
                        </button>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => handleChangeType("B2BCustomer")}
                        role="presentation"
                      >
                        <button
                          className="nav-link commercial"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          COMMERCIAL SERVICE
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="coollah_residential_height_wrapper">
                          <div className="row">
                            {data?.map((data, ind) => (
                              <div className="col-md-12" key={ind}>
                                <div className="coollah_service_chemical">
                                  <div className="row">
                                    <div className="col col-md-9">
                                      <div className="coollah_service_chemical_content">
                                        <h3> {data.product_name}</h3>
                                        <span className="heading_border"></span>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: data.remarks,
                                          }}
                                        ></p>
                                        <p className="price">
                                          Starts at <span>${data.price}</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col col-md-3">
                                      <div className="coollah_service_chem_img_container">
                                        <ImageFallback
                                          src={data.product_image}
                                          alt={"chemical_service_img"}
                                        />
                                        <button
                                          onClick={(e) => handleBook(data)}
                                        >
                                          Book Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {data?.length === 0 && (
                              <div className="col-md-12">
                                <div className="text-center my-5">
                                  <h3>No Services Found</h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="coollah_residential_height_wrapper">
                          <div className="row">
                            {data?.map((data, ind) => (
                              <div className="col-md-12" key={ind}>
                                <div className="coollah_service_chemical">
                                  <div className="row">
                                    <div className="col col-md-9">
                                      <div className="coollah_service_chemical_content">
                                        <h3> {data.product_name}</h3>
                                        <span className="heading_border"></span>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: data.remarks,
                                          }}
                                        ></p>
                                        <p className="price">
                                          Starts at <span>${data.price}</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col col-md-3">
                                      <div className="coollah_service_chem_img_container">
                                        <ImageFallback
                                          src={data.product_image}
                                          alt={"chemical_service_img"}
                                        />
                                        <button
                                          onClick={(e) => handleBook(data)}
                                        >
                                          Book Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {data?.length === 0 && (
                              <div className="col-md-12">
                                <div className="text-center my-5">
                                  <h3>No Services Found</h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_over">
              <h2>What does Our Aircon Service Cover?</h2>
              <div className="coollah_service_doubled_border">
                <span className="border_first"></span>
                <span className="border_second"></span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="coollah_service_card_sec">
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Comprehensive Inspection:</strong> Our
                                team conducts a thorough inspection of your
                                entire air conditioning system. This includes a
                                close examination of key components to ensure
                                they're in optimal condition.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Filter Maintenance:</strong> We address
                                the heart of your AC by cleaning or replacing
                                filters. This step enhances indoor air quality
                                and promotes system efficiency.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Electrical Connections:</strong> To
                                prevent electrical issues and potential hazards,
                                we meticulously check and tighten all electrical
                                connections within your AC unit.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Lubrication:</strong> Proper lubrication
                                of moving parts is essential for reducing
                                friction and wear. This maintenance step helps
                                extend the life of your AC.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Refrigerant Assessment:</strong> We
                                verify refrigerant levels and make adjustments
                                if necessary. Correct levels are crucial for
                                efficient cooling and preventing damage to your
                                system.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Performance Evaluation:</strong> Our
                                experts conduct an overall performance
                                assessment to ensure your AC operates at peak
                                efficiency, providing reliable and
                                energy-efficient cooling.
                              </li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Inspection & Maintenance</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards2}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Comprehensive Cleaning:</strong> Our
                                team performs a deep and thorough cleaning of
                                your entire air conditioning unit, removing
                                dirt, dust, and debris that can hinder
                                performance.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Ductwork and Vents:</strong> We inspect
                                your ductwork and air vents to ensure they're
                                clean and unobstructed, optimizing air
                                circulation and indoor air quality.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Thermostat Calibration:</strong> We test
                                and calibrate your thermostat settings to ensure
                                accurate temperature control, maximizing comfort
                                and energy efficiency.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Drainage System Check:</strong> Proper
                                drainage is essential to prevent water damage.
                                We inspect and clean the drainage system,
                                reducing the risk of leaks and mold growth.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Troubleshooting and Repairs:</strong> As
                                part of general maintenance, we perform basic
                                troubleshooting and address minor issues,
                                preventing them from becoming major problems.
                              </li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>General Maintenance</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards3}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Extended Cleaning:</strong> Take your
                                AC's cleanliness to the next level with our
                                extended cleaning service, removing even the
                                toughest dirt and grime.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>In-Depth Inspections:</strong> We
                                conduct more extensive inspections, thoroughly
                                examining your system for potential issues and
                                addressing them proactively.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Wiring and Insulation:</strong> Our
                                experts pay extra attention to wiring and
                                insulation, ensuring safety and energy
                                efficiency.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Coils and Evaporator Units:</strong> We
                                clean coils and evaporator units, promoting
                                better heat exchange and overall system
                                performance.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>
                                  Energy Efficiency Recommendations:
                                </strong>{" "}
                                We provide recommendations for improving energy
                                efficiency, potentially saving you money on your
                                energy bills.
                              </li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Other General Maintenance</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards4}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Specialized Cleaning:</strong> Eliminate
                                the stubborn dirt and mold with our specialized
                                cleaning services, ensuring a healthy and
                                odor-free indoor environment.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Advanced Troubleshooting:</strong> A
                                plethora of services ranging from handling
                                complex issues to conducting advanced
                                troubleshooting to identify and resolve problems
                                efficiently.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Air Quality Solutions:</strong>{" "}
                                Rendering solutions to enhance indoor air
                                quality, such as installing air purifiers or
                                recommending ventilation improvements.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Energy-Saving Upgrades:</strong>{" "}
                                Orchestrated with energy-saving high features
                                and technologies, we enhance both your
                                environmental footprint and energy costs.
                              </li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>
                                <strong>Smart Technology Integration:</strong>{" "}
                                We bring services in smart thermostat technology
                                and controls, bringing more convenience and
                                efficiency.
                              </li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Addon Specialized Services</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row coollah_service_book">
          <div className="col col-12 col-md-12 col-lg-6">
            <div className="coollah_service_booking_man">
              <img src={service_booking_man} alt="Service_bboking_man_img" />
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-4">
            <div className="coollah_service_connect_form">
              <p>Get Your Services Professionally</p>
              <form className="coollah_service_booking_form">
                <input
                  name="pincode"
                  pattern="[0-9]*"
                  maxLength="6"
                  placeholder="Please Enter Postal Code"
                  title="Please Enter Postal Code"
                  autoComplete="off"
                  tabIndex="37"
                  required=""
                  value={postalCode}
                  onChange={(e) =>
                    setPostalCode((v) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                  onBlur={(e) => checkPincode(e)}
                />
                <button type="button" onClick={handleClick}>
                  Enquire Now
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="coollah_service_circles_mobile">
          <div className="row ">
            <div className="col-md-12">
              <div className="coollah_service_started">
                <h2>How to Get Started</h2>
                <div className="coollah_service_doubled_border">
                  <span className="border_first"></span>
                  <span className="border_second"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="coollah_service_circles">
          <div className="row">
              <div className="bg_sec">
                <div className="col-md-12">
                  <div className="bg-dot"></div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_location">
                  <img src={postCodeImg} alt="Location_image" />
                  <div className="coollah_service_content">
                    <h3>Enter your postcode</h3>
                    <p>Begin your journey with Coollah's service by entering your pin code on our application. Instantly check service availability in your area and access service plans tailored to your location, ensuring a seamless and personalized experience.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_calender">
                  <img src={cleanPlanImg} alt="Calender_image" />
                  <div className="coollah_service_content">
                    <h3>Choose Your Service Plan</h3>
                    <p>Select the service plan that best suits your needs, whether it's standard service, deep service, or specialized services. Each plan comes with detailed descriptions, empowering you to make an informed choice. Choose the frequency that aligns with your lifestyle, from one-time service to convenient recurring schedules.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_telecalling">
                  <img src={welcomeCallImg} alt="Telecalling_image" />
                  <div className="coollah_service_content">
                    <h3>Receive a welcome call</h3>
                    <p>After choosing your service plan, our dedicatedservice team will reach out with a warm welcome call. Confirm service details, schedule your service appointment, and have any questions addressed. We strive for a personalized and hassle-free experience, ensuring your complete satisfaction.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_success">
                  <img src={cleanHouseImg} alt="Success_logo" />
                  <div className="coollah_service_content">
                    <h3>Enjoy Our Service</h3>
                    <p>With all administrative details sorted, it's time to savor the convenience of our top-notch service. Our highly trained professionals arrive at your scheduled time, equipped with tools and materials to transform your living space. Sit back, relax, and witness your home refreshed and revitalized.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="coollah_circle_connector_waves">
              <img
                src="./assets/images/services/connector.png"
                alt="Connectin_waves_image"
              />
            </div> */}
          </div>
        </div>
      </div>
      <GetCoollahApp />
      <FAQ categoryId={getCategoryId} />
      <Contact />
      <FooterRevamp />
      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
              <h5 className="mb-2">Not Found</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </Fragment>
  );
};

export default AirConServicesRevamp;
