import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import Topbar from './Home/Topbar';
import FooterRevamp from './FooterRevamp';

const Pincode = () => {
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState("");
  const [formErrors, setFormErrors] = useState('');

  const handleClick = () => {
    setFormErrors(validate(postalCode));
    if (postalCode !== "" && postalCode.length === 6) {
      sessionStorage.setItem("Pincode", postalCode)
      navigate('/Booking')
    }
  }
  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode)
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    }
    else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  }
  return (

    <div className='st-blue-color'>
      <Topbar />
      <div className="st-content" style={{ marginBottom: "412px" }}>
        <div className="st-hero-slide st-style2 st-flex">
          <div className="container">
            <div className="st-hero-text st-style1 st-color1">


              <div className="row">
                <div className="col-md-12">
                  <div className="st-newsletter">
                    <form className="mailchimp st-subscribe-form" action="">
                      <input type="text" id="pincode" name="pincode" pattern="[0-9]*" maxLength="6" placeholder="Please Enter Postal Code" className="form-control ccp per AddrClass" title="Please Enter Postal Code" autoComplete="off" tabIndex="37" required="" value={postalCode} onChange={(e) => setPostalCode((v) => (e.target.validity.valid ? e.target.value : v))} />
                      <button type="button" id="subscribe-button" className="st-newsletter-btn" onClick={handleClick}>Book Now</button>
                      <span className="formError">{formErrors.postalCode}</span>
                      { }
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label className="subscription-label" htmlFor="subscriber-email"></label>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>


      </div>

      <FooterRevamp />
    </div>



  )
}

export default Pincode;