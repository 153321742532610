import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../API/API";
import Topbar from "../Home/Topbar";
import Banner from "../assets/img/service-bg-4.jpg";
import Start1 from "../assets/img/start1.png";
import Start2 from "../assets/img/start2.png";
import Start3 from "../assets/img/start3.png";
import Start4 from "../assets/img/start4.png";
import Contact from "../Home/Contact";
import FooterRevamp from "../FooterRevamp";

const AirConServiceWallMounted = () => {
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [data, setData] = React.useState(null);
  useEffect(() => {
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setData(response.data);
        console.log("data", response);
      })
      .catch((error) => {});
  }, []);
  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        navigate("/CategoryService");
      }
    }
  };
  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    navigate("/Pincode");
  };
  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  };
  return (
    <div className="st-blue-color">
      <Topbar />
      <div className="st-content" style={{ marginBottom: "412px" }}>
        <div className="st-hero-slide st-style2 st-flex">
          <div className="container">
            <div className="st-hero-text st-style1 st-color1">
              <h1 className="st-hero-title">AirCon Services-Wall Mounted</h1>

              <div className="st-hero-subtitle">
                Spend your time on the most important things in life.
                <br />
                Leave the home cleaning to Coollah, Singapore's No. 1<br />
                professional home cleaning service!
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="st-newsletter">
                    <form className="mailchimp st-subscribe-form" action="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                      />
                      <button
                        type="button"
                        id="subscribe-button"
                        className="st-newsletter-btn"
                        onClick={handleClick}
                      >
                        Book Now
                      </button>
                      <span className="formError">{formErrors.postalCode}</span>
                      {}
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label
                        className="subscription-label"
                        htmlFor="subscriber-email"
                      ></label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="st-hero-img"></div>
          <div className="st-circla-animation">
            <div className="st-circle st-circle-first"></div>
            <div className="st-circle st-circle-second"></div>
          </div>

          <div className="st-wave-wrap">
            <div className="st-wave">
              <div
                className="st-wave-in"
                style={{ backgroundImage: `url(${Banner})` }}
              ></div>
              <div id="color-overlay"></div>
            </div>
          </div>
        </div>

        <section className="st-about-wrap st-section bg-cray">
          <div className="container-fluid container1">
            <h2 className="mb-3 text-center text-red">Our Services</h2>
            <div className="row">
              {data?.result[0]?.services.map((data) => (
                <>
                  <div className="col-lg-3">
                    <div
                      className="st-iconbox st-style1 st-style3 wow fadeInUp"
                      data-wow-duration="0.8s"
                      data-wow-delay="0.2s"
                    >
                      <h5 className="st-iconbox-title font-bold">
                        {data.product_name}
                      </h5>
                      <p>{data.price}</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.remarks }}
                        className="st-iconbox-text"
                      ></div>
                      <div className="text-right bottom-po">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(e) => handleBook(data)}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </section>

        <section className="st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="">
                  <h2 className="mb-3">
                    Get your home services professionally
                  </h2>

                  <form className="mailchimp st-subscribe-form" action="">
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      pattern="[0-9]*"
                      maxLength="6"
                      className="form-control ccp per AddrClass"
                      placeholder="Please Enter Postal Code"
                      title="Please Enter Postal Code"
                      autoComplete="off"
                      tabIndex="37"
                      required=""
                      value={postalCode}
                      onChange={(e) =>
                        setPostalCode((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                    />
                    <button
                      type="button"
                      id="subscribe-button"
                      className="st-newsletter-btn"
                      onClick={handleClick}
                    >
                      Book Now
                    </button>
                    <span className="formError">{formErrors.postalCode}</span>
                    {}
                    <h5 className="subscription-success"> .</h5>
                    <h5 className="subscription-error"> .</h5>
                    <label
                      className="subscription-label"
                      htmlFor="subscriber-email"
                    ></label>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="st-about-wrap st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4>How To Get Started</h4>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start1} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>1. Enter your postcode</h5>
                </div>
                <p>
                  Enter your postal code to check the availability of cleaners
                  in your area.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start2} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>2. Choose your cleaning plan</h5>
                </div>
                <p>
                  Select your preferred cleaning plan and secure your booking.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start3} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>3. Receive a welcome call</h5>
                </div>
                <p>
                  Your dedicated Home Advisor will be in touch with you shortly
                  to support all your needs.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start4} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>4. Enjoy your clean house</h5>
                </div>
                <p>
                  Enjoy a sparkling home and share your feedback with us for
                  constant quality improvement.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <MapLocation/> */}
        <Contact />
      </div>
      <FooterRevamp />
    </div>
  );
};

export default AirConServiceWallMounted;
