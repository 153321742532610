import locateImg from "../assets/revamp-css/assets/images/contact/locate-lg.png";
import mailImg from "../assets/revamp-css/assets/images/contact/mail-lg.png";
import callImg from "../assets/revamp-css/assets/images/contact/call-lg.png";
import bannerImg from "../assets/revamp-css/assets/images/contact/banner.png";
import { Fragment } from "react";
import Topbar from "./Topbar";
import FooterRevamp from "../FooterRevamp";

const ContactRevamp = () => {
  return (
    <Fragment>
      <Topbar />
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col col-md-12">
            <div className="coollah_contact_banner_sec">
              <img src={bannerImg} alt="contact-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="coollah_contact_content_sec_wrapper">
          <div className="row">
            <div className="col col-12 col-lg-4 col-md-6">
              <div className="coollah_contact_content_sec">
                <div className="coollah_img_sec">
                  <img src={locateImg} alt="locate-img" />
                </div>
                <div className="coollah_contact_contents">
                  <p>
                    362, #05-14, Upper Payalebar Road, Da Jin Factory Building
                    Singapore-534963
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-12 col col-lg-4 col-md-6">
              <div className="coollah_contact_content_sec">
                <div className="coollah_img_sec">
                  <img src={callImg} alt="call-img" />
                </div>
                <div className="coollah_contact_contents">
                  <a rel="noreferrer" href="tel:65 89493347">
                    +65 89493347
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-12 col col-lg-4 col-md-6">
              <div className="coollah_contact_content_sec">
                <div className="coollah_img_sec">
                  <img src={mailImg} alt="mail-img" />
                </div>
                <div className="coollah_contact_contents">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:enquiry@gmail.com"
                  >
                    enquiry@coollah.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterRevamp />
    </Fragment>
  );
};

export default ContactRevamp;
