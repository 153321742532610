import React, { Component } from 'react';




class Loading extends React.Component {
  render() {
    return (
      <div id="st-preloader">
      <div className="st-preloader-wave"></div>
      <div className="st-preloader-wave"></div>
      <div className="st-preloader-wave"></div>
      <div className="st-preloader-wave"></div>
      <div className="st-preloader-wave"></div>
    </div>
    );
  }
}

export default Loading;