import FooterRevamp from "../FooterRevamp";
import React, { useState, useEffect, Fragment } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ModalCommon from "../Modal/modal";
import Topbar from "../Home/Topbar";
const EditPost = (props) => {
	// Add New Address
	const [showAddAddress, setAddAddressShow] = useState(false);
	const handleAddAddressClose = () => setAddAddressShow(false);
	const handleAddAddrressShow = () => setAddAddressShow(true);

	const [formErrors, setFormErrors] = useState("");
	const [isSubmit, setIsSubmit] = useState(false);
	const [categories, setCategories] = useState([]);
	const [additional_image, setAdditionalImage] = useState("");
	const [additional_image1, setAdditionalImage1] = useState("");
	const [additional_image2, setAdditionalImage2] = useState("");
	const [additionalImage, setAdditional_Image] = useState("");
	const [additionalImage1, setAdditional_Image1] = useState("");
	const [additionalImage2, setAdditional_Image2] = useState("");
	const [default_image, setDefaultImage] = useState("");
	const [default_image1, setDefaultImage1] = useState("");
	const [category_id, setcategoryid] = useState();
	const [brand, setBrand] = useState("");
	const [product_age, setProductAge] = useState("");
	const [special_remarks, setSpecialRemark] = useState("");
	const [offer_price, setOfferPrice] = useState("");
	const [ad_category_name, setAdCategoryName] = useState("");

	const [details, setDetails] = useState([]);
	const [ad_id, setAd_id] = useState("");
	const navigate = useNavigate();

	const [successAlert, setSuccessAlert] = useState(false);
	const [failureMessage, setFailureMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [failureAlert, setFailureAlert] = useState(false);
	const { state } = useLocation();


	const [successShow, setSuccessShow] = useState(false);
	const [failureShow, setFailureShow] = useState(false);
	const [message, setMessage] = useState("");
	const [post, setPost] = useState(false);

	const handleSuccessShow = () => setSuccessShow(true);
	const handleFaliureShow = () => setFailureShow(true);

	const handleClose = () => {
		if (successShow) {
			setSuccessShow(false);
		}
		if (failureShow) {
			setFailureShow(false);
		}
		if (successShow && post) {
			navigate("/MyPosts");
		}
	};

	const getBase64FromUrl = async (url) => {
		const data = await fetch(url, {
			mode: "cors",
		});
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data);
			};
		});
	};

	useEffect(() => {
		API.get("load_all_ad_category").then((result) => {
			setCategories(result.data.result);
		});
		API.get("load_ad_details", { params: { ad_id: state.ad_id } }).then(
			(result) => {
				setcategoryid(result.data.result[0].ad_category_id);
				setAd_id(result.data.result[0].ad_id);
				setBrand(result.data.result[0].brand_name);
				setAdCategoryName(result.data.result[0].ad_category_name);
				setProductAge(result.data.result[0].product_age);
				setDefaultImage1(result.data.result[0].default_image);
				setOfferPrice(result.data.result[0].offer_price);
				setSpecialRemark(result.data.result[0].special_remarks);
				setDefaultImage(result.data.result[0].default_image);
				setAdditionalImage(result.data.result[0].additional_images[0].image);
				setAdditionalImage1(result.data.result[0].additional_images[1].image);
				setAdditionalImage2(result.data.result[0].additional_images[2].image);
				setAdditional_Image(result.data.result[0].additional_images[0].image);
				setAdditional_Image1(result.data.result[0].additional_images[1].image);
				setAdditional_Image2(result.data.result[0].additional_images[2].image);
			}
		);
		if (formErrors === "") {
			setIsSubmit(false);
		}
	}, []);
	useEffect(() => {
		if (Object.keys(formErrors).length === 0) {
			setIsSubmit(true);
		}
	}, [formErrors]);

	const base64convert = (img) => {
		var logoImg = new Image();
		logoImg.onload = function () {
			var canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);
			var dataURL = canvas.toDataURL("image/png");
			console.log(dataURL);
			return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
		};
	};

	const handleChange = (event) => {
		console.log(event.target.files[0]);

		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = () => {
			setDefaultImage1(reader.result);
			var index1 = reader.result.split(",")[1];
			console.log(index1);

			setDefaultImage(index1);
		};
		reader.readAsDataURL(file);
	};
	const handleAdditionalChange = (event) => {
		console.log(event.target.files[0]);

		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = () => {
			console.log(reader.result);
			setAdditional_Image(reader.result);
			var index1 = reader.result.split(",")[1];
			setAdditionalImage(index1);
		};
		reader.readAsDataURL(file);
	};
	const handleAdditionalChange1 = (event) => {
		console.log(event.target.files[0]);

		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = () => {
			console.log(reader.result);
			setAdditional_Image1(reader.result);
			var index1 = reader.result.split(",")[1];
			setAdditionalImage1(index1);
		};
		reader.readAsDataURL(file);
	};
	const handleAdditionalChange2 = (event) => {
		console.log(event.target.files[0]);

		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = () => {
			setAdditional_Image2(reader.result);
			console.log(reader.result);
			var index1 = reader.result.split(",")[1];
			setAdditionalImage2(index1);
		};
		reader.readAsDataURL(file);
	};
	const addCategory = () => {
		handleAddAddrressShow();
	};
	const addAdCategory = () => {
		API.post("create_ad_category", {
			user_id: sessionStorage.getItem("user_id"),
			ad_category_name: ad_category_name,
		})
			.then(function (response) {
				if (response.data.is_successful === true) {
					setMessage("Ad Category Added Successfully");
					// setSuccessAlert(true);
					handleSuccessShow();
					setIsSubmit(false);
					handleAddAddressClose();
				} else {
					// setMessage(response.data.message);
					// setFailureAlert(true);
					handleFaliureShow();
					setIsSubmit(false);
				}
			})
			.catch(function (error) {
				console.log(error);
				setIsSubmit(false);
			});
	};
	const validate = (values, name) => {
		const errors = {};

		switch (name) {
			case "email_id":
				const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
				if (!values.email_id) {
					errors.email_id = "Email is required!";
				} else if (!regex.test(values.email_id)) {
					errors.email_id = "This is not a valid email format!";
				}
				break;

			case "mobile_no":
				if (!values.mobile_no) {
					errors.mobile_no = "Mobile No is required";
				}
				break;
			case "floor":
				if (!values.floor) {
					errors.floor = "Floor is required";
				}
				break;
			case "unit":
				if (!values.unit) {
					errors.unit = "Unit is required";
				}
				break;

			case "installation_date":
				if (!values.installation_date) {
					errors.installation_date = "Installation Date is required";
				}
				break;
			default:
				break;
		}
		return errors;
	};
	const CategoryValidate = (name) => {
		const errors = {};

		switch (name) {
			case "ad_category_name":
				if (!ad_category_name) {
					errors.ad_category_name = "Category is required!";
				}

				break;

			default:
				break;
		}
		return errors;
	};
	const validate1 = (values) => {
		const errors = {};

		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

		if (!values.email_id) {
			errors.email_id = "Email is required!";
		} else if (!regex.test(values.email_id)) {
			errors.email_id = "This is not a valid email format!";
		}
		if (!values.pincode) {
			errors.pincode = "Postal Code is required";
		} else if (values.pincode.length < 6) {
			errors.pincode = "Postal Code must have 6 digits";
		}
		if (!values.address) {
			errors.address = "Address is required";
		}

		if (!values.mobile_no) {
			errors.mobile_no = "Mobile No is required";
		}

		if (!values.city_id) {
			errors.city_id = "City is required";
		}
		if (!values.floor) {
			errors.floor = "Floor is required";
		}
		if (!values.unit) {
			errors.unit = "Unit is required";
		}
		if (!values.installation_date) {
			errors.installation_date = "Installation Date is required";
		}

		return errors;
	};
	const handleError = (e) => {
		const name = e.target.name;
		setFormErrors(validate(name));
	};

	const handleCategoryError = () => {
		setFormErrors(CategoryValidate(ad_category_name));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(ad_category_id);
		// setFormErrors(validate1());
		//    if(isSubmit===true)
		// 		{
		if (additional_image === "") {
			API.post("edit_ad", {
				ad_id: ad_id,

				user_id: sessionStorage.getItem("user_id"),
				ad_category_id: category_id,
				brand_name: brand,
				product_age: product_age,
				default_image: default_image,
				special_remarks: special_remarks,
				offer_price: offer_price,
				additional_images: [],
			})
				.then(function (response) {
					if (response.data.is_successful === true) {
						setMessage("Ad Edited Successfully");
						// setSuccessAlert(true);
						handleSuccessShow();

						setIsSubmit(false);
						setPost(true);
						// navigate("/MyPosts");
					} else {
						setMessage(response.data.message);
						// setFailureAlert(true);
						handleFaliureShow()
						setIsSubmit(false);
					}
				})

				.catch(function (error) {
					console.log(error);
					setIsSubmit(false);
				});
		}

		// }
		else if (additional_image1 === "") {
			API.post("edit_ad", {
				ad_id: ad_id,

				user_id: sessionStorage.getItem("user_id"),
				ad_category_id: category_id,
				brand_name: brand,
				product_age: product_age,
				default_image: default_image,
				special_remarks: special_remarks,
				offer_price: offer_price,
				additional_images: [
					{
						base_64: additional_image,
					},
				],
			})
				.then(function (response) {
					if (response.data.is_successful === true) {
						setMessage("Ad Edited Successfully");
						// setSuccessAlert(true);
						handleSuccessShow();

						setIsSubmit(false);
						setPost(true);
						// navigate("/MyPosts");
					} else {
						setMessage(response.data.message);
						// setFailureAlert(true);
						handleFaliureShow();
						setIsSubmit(false);
					}
				})
				.catch(function (error) {
					console.log(error);
					setIsSubmit(false);
				});
		} else if (additional_image2 === "") {
			API.post("edit_ad", {
				ad_id: ad_id,

				user_id: sessionStorage.getItem("user_id"),
				ad_category_id: category_id,
				brand_name: brand,
				product_age: product_age,
				default_image: default_image,
				special_remarks: special_remarks,
				offer_price: offer_price,
				additional_images: [
					{
						base_64: additional_image,
					},
					{
						base_64: additional_image1,
					},
				],
			})
				.then(function (response) {
					if (response.data.is_successful === true) {
						setMessage("Ad Edited Successfully");
						// setSuccessAlert(true);
						handleSuccessShow();

						setIsSubmit(false);
						setPost(true);
						// navigate("/MyPosts");
					} else {
						setMessage(response.data.message);
						// setFailureAlert(true);
						handleFaliureShow();
						setIsSubmit(false);
					}
				})
				.catch(function (error) {
					console.log(error);
					setIsSubmit(false);
				});
		} else {
			API.post("edit_ad", {
				ad_id: ad_id,

				user_id: sessionStorage.getItem("user_id"),
				ad_category_id: category_id,
				brand_name: brand,
				product_age: product_age,
				default_image: default_image,
				special_remarks: special_remarks,
				offer_price: offer_price,
				additional_images: [
					{
						base_64: additional_image,
					},
					{
						base_64: additional_image1,
					},
					{
						base_64: additional_image2,
					},
				],
			})
				.then(function (response) {
					if (response.data.is_successful === true) {
						setMessage("Ad Edited Successfully");
						// setSuccessAlert(true);
						handleSuccessShow();

						setIsSubmit(false);
						setPost(true);
						// navigate("/MyPosts");
					} else {
						setMessage(response.data.message);
						// setFailureAlert(true);
						handleFaliureShow();
						setIsSubmit(false);
					}
				})
				.catch(function (error) {
					console.log(error);
					setIsSubmit(false);
				});
		}
		// }
	};

	return (
		<>
			<div className="st-height-70"></div>
			<div className="st-blue-color">
				<Topbar />
			</div>

			<section className="st-page-header st-bg">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="st-page-header-text">
								<h1>Edit Post</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="st-blog-wrap st-section">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>
						<div className="col-md-9">
							<Link
								to="/MyPosts"
								title="Click here to get back to the list"
								className="btn btn-round btn-primary btn-sm float-end"
							>
								<i className="fa fa-backward"></i>
							</Link>
							<br></br>
							<br></br>
							{/* <h4 className="font-w600 title mb-2 mr-auto">
                Installation Appointment
            </h4> */}

							<form
								action="#"
								method="post"
								onSubmit={handleSubmit}
								autoComplete="off"
							>
								<div className="mt-2">
									<br></br>
									<div className="form-group row">
										<div className="col-md-2">
											<label>Ad Category</label>

											<span className="formError">*</span>
										</div>

										<div className="col-md-4">
											<select
												name="ad_category_id"
												id="ad_category_id"
												type="number"
												maxLength="50"
												className="form-control ccp per AddrClass"
												autoComplete="off"
												tabIndex="39"
												required=""
												onBlur={(e) => handleError(e)}
												onChange={(e) => setcategoryid(e.target.value)}
												readOnly
											>
												<option value="">{ad_category_name}</option>
												{categories.map(
													({ ad_category_id, ad_category_name }) => (
														<option key={ad_category_id} value={ad_category_id}>
															{ad_category_name}
														</option>
													)
												)}
											</select>
											<span className="formError">
												{formErrors.category_id}
											</span>
										</div>
										<div className="col-md-4">
											<button
												className="btn btn-success"
												onClick={(e) => addCategory(e)}
											>
												Add Category
											</button>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-2 required">
											<label>Brand</label>
											<span className="formError">*</span>
										</div>

										<div className="col-md-4">
											<input
												name="brand"
												id="brand"
												className="form-control"
												type="text"
												placeholder="Brand"
												title="Enter Brand"
												value={brand}
												onChange={(e) => setBrand(e.target.value)}
												onBlur={(e) => handleError(e)}
											/>
											<span className="formError brand">
												{formErrors.brand}
											</span>
										</div>

										<div className="col-md-2">
											<label>Image</label>
											<span className="formError">*</span>
										</div>
										<div className="col-md-4">
											<img src={default_image1} />
											<input
												type="file"
												id="default_image"
												name="default_image"
												className="file-input"
												onBlur={(e) => handleError(e)}
												onChange={handleChange}
												hidden
											/>
											<label
												htmlFor="default_image"
												className="btn btn-sm btn-round btn-warning"
												style={{ marginLeft: "80px" }}
											>
												<i className="fa fa-edit fa-1x text-light"></i>
											</label>
											<span className="formError">
												{formErrors.default_image}
											</span>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-2">
											<label>Product Age</label>

											<span className="formError">*</span>
										</div>

										<div className="col-md-4">
											<input
												name="product_age"
												id="product_age"
												className="form-control"
												type="text"
												placeholder="Product Age"
												value={product_age}
												title="Please Enter Product Age"
												onChange={(e) => setProductAge(e.target.value)}
												onBlur={(e) => handleError(e)}
											/>
											<span className="formError">
												{formErrors.product_age}
											</span>{" "}
										</div>
										<div className="col-md-2">
											<label>Additional Image1</label>
										</div>
										<div className="col-md-4">
											<img src={additionalImage} />
											<input
												type="file"
												id="additional_image"
												name="additional_image"
												maxLength="6"
												className="form-control ccp per AddrClass"
												title="Please Choose Image"
												autoComplete="off"
												tabIndex="37"
												required=""
												onBlur={(e) => handleError(e)}
												onChange={handleAdditionalChange}
												hidden
											/>

											<label
												htmlFor="additional_image"
												className="btn btn-sm btn-round btn-warning"
												style={{ marginLeft: "80px" }}
											>
												<i className="fa fa-edit fa-1x text-light"></i>
											</label>

											<span className="formError">
												{formErrors.default_image}
											</span>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-2 required">
											<label>Price</label>
											<span className="formError">*</span>
										</div>

										<div className="col-md-4">
											<input
												name="offer_price"
												id="offer_price"
												className="form-control"
												type="text"
												placeholder="Enter Your Price"
												title="Enter Your Price"
												value={offer_price}
												onChange={(e) => setOfferPrice(e.target.value)}
												onBlur={(e) => handleError(e)}
											/>
										</div>
										<div className="col-md-2">
											<label>Additional Image2</label>
										</div>
										<div className="col-md-4">
											<img src={additionalImage1} />
											<input
												type="file"
												id="additional_image1"
												name="additional_image1"
												maxLength="6"
												className="form-control ccp per AddrClass"
												title="Please Choose Image"
												autoComplete="off"
												tabIndex="37"
												required=""
												onBlur={(e) => handleError(e)}
												onChange={handleAdditionalChange1}
												hidden
											/>
											<label
												htmlFor="additional_image1"
												className="btn btn-sm btn-round btn-warning"
												style={{ marginLeft: "80px" }}
											>
												<i className="fa fa-edit fa-1x text-light"></i>
											</label>
											<span className="formError">
												{formErrors.default_image}
											</span>
										</div>
									</div>

									<div className="form-group row">
										<div className="col-md-2 required">
											<label>Remarks</label>
										</div>

										<div className="col-md-4">
											<textarea
												name="special_remarks"
												id="special_remarks"
												className="form-control"
												type="text"
												placeholder="Enter Your Remarks"
												style={{ height: "110px" }}
												title="Enter Your Remarks"
												value={special_remarks}
												onChange={(e) => setSpecialRemark(e.target.value)}
												onBlur={(e) => handleError(e)}
											/>
										</div>
										<div className="col-md-2">
											<label>Additional Image3</label>
										</div>
										<div className="col-md-4">
											<img src={additionalImage2} />
											<input
												type="file"
												id="additional_image2"
												name="additional_image2"
												maxLength="6"
												className="form-control ccp per AddrClass"
												title="Please Choose Image"
												autoComplete="off"
												tabIndex="37"
												required=""
												onBlur={(e) => handleError(e)}
												onChange={handleAdditionalChange2}
												hidden
											/>
											<label
												htmlFor="additional_image2"
												className="btn btn-sm btn-round btn-warning"
												style={{ marginLeft: "80px" }}
											>
												<i className="fa fa-edit fa-1x text-light"></i>
											</label>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6"></div>
										<div className="col-md-6">
											<button type="submit" className="btn btn-primary">
												Update
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<FooterRevamp />

			{/* //New Address */}

			<Modal show={showAddAddress} onHide={handleAddAddressClose}>
				<Modal.Header closeButton>
					<Modal.Title>ADD Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form method="post" autoComplete="off">
						<div id="AssetAddressmodel">
							<div className="form-group row">
								<div className="col-md-3 required">
									<label className="text-right">
										Ad Category Name<span style={{ color: "red" }}>*</span>
									</label>
								</div>

								<div className="col-md-9">
									<input
										name="ad_category_name"
										id="ad_category_name"
										className="form-control"
										type="text"
										placeholder="Ad Category Name"
										value={ad_category_name}
										title="Please Enter Ad Category Name"
										onChange={(e) => setAdCategoryName(e.target.value)}
										onBlur={(e) => handleError(e)}
									/>

									<span className="formError">
										{formErrors.ad_category_name}
									</span>
								</div>
							</div>

							<div className="form-group row">
								<div className="col-md-3"></div>

								<div className="col-md-4">
									<button
										type="button"
										className="btn btn-success"
										onClick={(e) => {
											addAdCategory(e);
										}}
									>
										Add Category
									</button>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>

			{/* Success Alert */}
			{/* <Modal
        size="md"
        show={successAlert}
        onHide={(e) => setSuccessAlert(false)}
        backdrop="static"
        keyboard={false}
      >
        <div className="alert alert-success">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-hidden="true"
            onClick={(e) => setSuccessAlert(false)}
          >
            ×
          </button>
          <span className="glyphicon glyphicon-ok"></span> <strong>Success</strong>
          <hr className="message-inner-separator" />
          <p>{successMessage}</p>
        </div>
      </Modal> */}

			{/* Failure Alert */}
			{/* <Modal
        show={failureAlert}
        onHide={(e) => setFailureAlert(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <div className="alert alert-danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-hidden="true"
            onClick={(e) => setFailureAlert(false)}
          >
            ×
          </button>
          <span className="glyphicon glyphicon-hand-right"></span>{" "}
          <strong>Failure</strong>
          <hr className="message-inner-separator" />
          <p>{failureMessage}</p>
        </div>
      </Modal> */}

			<ModalCommon
				show={successShow || failureShow}
				onHide={handleClose}
			>
				{successShow && (
					<Modal.Header className="modal-head">
						<div className="icon-box">
							<i className="fa fa-check icons-1"></i>
						</div>
					</Modal.Header>
				)}
				{failureShow && (
					<Modal.Header className="modal-headd">
						<div className="icon-box1">
							<i className="fa fa-exclamation icons-1"></i>
						</div>
					</Modal.Header>
				)}

				{(successShow || failureShow) && (
					<Fragment>
						<Modal.Body className="text-center">
							<p>
								{message}
							</p>
						</Modal.Body>
						<Modal.Footer className="text-right font-weight">
							<a
								className="text-blue"
								data-dismiss="modal"
								onClick={handleClose}
							>
								OK
							</a>
						</Modal.Footer>
					</Fragment>
				)}
			</ModalCommon>

		</>
	);
};

export default EditPost;
