import React,{useEffect,useState} from "react";

import { useTable, useRowSelect } from "react-table";
import API  from './API/API';






const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function Table({ columns, data, onRowSelectStateChange }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data
    },
    useRowSelect
  );

  // Row-select state change
  React.useEffect(() => onRowSelectStateChange?.(selectedRowIds), [
    onRowSelectStateChange,
    selectedRowIds
  ]);

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(0, 10).map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              "selectedFlatRows[].original": selectedFlatRows.map(
                (d) => d.original
              )
            },
            null,
            2
          )}
        </code>
      </pre>
    </>
  );
}

function ReactTable() {
    const[service,setService]=useState([]);
    useEffect(() => {

   
        API.get('load_all_services',{headers:
        {
          'access_token':sessionStorage.getItem('access_token')
        }}).then(result => {
          
            setService(result.data.result);
        });
    },[]);
  const columns = React.useMemo(
    () => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      {
        Header: "Product Name",
        accessor: "product_name"
      },
 
    
  
      {
        Header: "Category Name",
        accessor: "category_name"
      },
    ],
    []
  );



  /** @type {{[key: number]: boolean}} selectedRowIds */
  const [selectedRowIds, setSelectedRowIds] = React.useState({});

  return (
  <>
      <button
        className="action"
        onClick={() => alert(JSON.stringify(selectedRowIds))}
      >
        Send selection
      </button>
      <Table
        columns={columns}
        data={service}
        onRowSelectStateChange={setSelectedRowIds}
      />
   </>
  );
}

export default ReactTable;
