import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Failure = (props) => {
  const { state } = useLocation();
  const [show, setShow] = useState("");
  if (sessionStorage.getItem("agent_member_id") === "") {
    setShow(false);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mx-auto mt-5">
            <div className="payment">
              <div className="payment_header">
                <div className="check">
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>{" "}
                </div>
              </div>
              <div className="content">
                <h1>Payment Failure!</h1>
                <p>Your Payment has Failed.Please Try again after Some time</p>
                {show ? (
                  <Link to="/" className="Link">
                    Go to Home
                  </Link>
                ) : (
                  <Link to="/" className="Link">
                    Go to Home
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Failure;
