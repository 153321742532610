import Topbar from '../Home/Topbar';
import FooterRevamp from '../FooterRevamp';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import API from '../API/API';
import Sidebar from '../Sidebar';
import CallReportTable from '../CallReportTable';
import CallReportService from '../CallReportService';
import SpareDetails from '../SpareDetails';
const ViewServiceCallReport = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();

    const navigate = useNavigate();
    const [service, setService] = useState([]);
    const [callReport, setCallReport] = useState([]);
    const [spare, setSpare] = useState([]);
    const [remarks, setRemarks] = useState("");
    useEffect(() => {
        API.get('load_call_reports', { params: { ticket_id: state.ticket_id } }).then(result => {
            setIsLoading(true);
            setRemarks(result.data.result[0].remarks);
            setCallReport(result.data.result);
            setIsLoading(false);
        });

    }, [])

    useEffect(() => {

        setIsLoading(true);

        API.get('load_single_service_ticket', { params: { member_id: sessionStorage.getItem('member_id'), ticket_id: state.ticket_id } }).then(result => {

            setSpare(result.data.result[0].spares);
            setService(result.data.result[0].services)

        });

        setIsLoading(false);

    }, [])


    return (
        <>

            <>
                <div className="st-height-70"></div>
                <div className='st-blue-color'>
                    <Topbar />
                </div>

                <section className="st-page-header st-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="st-page-header-text">
                                    <h1>Service Call Report</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="st-blog-wrap st-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">
                                <Sidebar />
                            </div>
                            <div className="col-md-9">
                                <Link to="/SingleServiceTicketDetails" state={{ ticket_id: state.ticket_id }} title="Click here to get back to the list" className="btn btn-round btn-primary btn-sm float-end" ><i className="fa fa-backward"></i></Link>

                                <div className="container-fluid">

                                    <h3 className="font-w600 title mb-2 mr-auto">
                                        You can see Service Call Report details here
                                    </h3>

                                </div>

                                <br></br>
                                <div className="card">
                                    <div className="card-body">
                                        <form >

                                            <ul><li style={{ display: 'none' }}></li>
                                            </ul>
                                            <div>
                                                <h4>Call Report Details</h4>
                                                <br></br>
                                                {isLoading ? (
                                                    <p>Loading ...</p>
                                                ) : (
                                                    <>
                                                        <textarea name="remarks" id="remarks" type="text" className="form-control" value={remarks} placeholder="Remarks" readonly=""></textarea>

                                                        <div className="form-group row">


                                                            <CallReportTable columns={
                                                                [
                                                                    {
                                                                        Header: "Report Date",
                                                                        accessor: "report_date"
                                                                    },

                                                                    {
                                                                        Header: "Technician Name",
                                                                        accessor: "tech_name",
                                                                        Cell: row => {
                                                                            const technician_name = row.row.original.tech_name
                                                                            if (technician_name === " ") return "NA";
                                                                            return technician_name;

                                                                        }
                                                                    },

                                                                    {
                                                                        Header: "Technician Mobile",
                                                                        accessor: "tech_mob",
                                                                        Cell: row => {
                                                                            const technician_name = row.row.original.tech_mob
                                                                            if (technician_name === " ") return "Null";
                                                                            return technician_name;

                                                                        }
                                                                    },
                                                                    {
                                                                        Header: "Technician Email",
                                                                        accessor: "tech_email",
                                                                        Cell: row => {
                                                                            const technician_name = row.row.original.tech_email
                                                                            if (technician_name === " ") return "Null";
                                                                            return technician_name;

                                                                        }
                                                                    },
                                                                    {
                                                                        Header: "Call Status",
                                                                        accessor: "call_status"
                                                                    },

                                                                ]

                                                            } data={callReport} loading={isLoading} minRows={0} paging={true} />

                                                        </div>
                                                    </>

                                                )}

                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterRevamp />

            </>

        </>
    )
}

export default ViewServiceCallReport;