import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import Topbar from "../Home/Topbar";
import Banner from "../assets/img/service-bg-3.jpg";
import Start1 from "../assets/img/start1.png";
import Start2 from "../assets/img/start2.png";
import Start3 from "../assets/img/start3.png";
import Start4 from "../assets/img/start4.png";
import Contact from "../Home/Contact";
import Axios from "axios";
import ModalCommon from "../Modal/modal";
import FooterRevamp from "../FooterRevamp";

const CarpenterServices = () => {
  const navigate = useNavigate();
  const footerRef = useRef();
  const pincodeRef = useRef();
  const [postalCode, setPostalCode] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [data, setData] = React.useState(null);

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  useEffect(() => {
    footerRef.current.scrollIntoView();

    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  }, []);

  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        navigate("/CategoryService");
      }
    }
  };

  const handleBook = (data) => {
    sessionStorage.setItem("product_image", data.product_image);
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    setProduct(data.product_name);
    sessionStorage.setItem("remarks", data.remarks);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    pincodeRef.current.scrollIntoView();
  };

  useEffect(() => {}, [sessionStorage.getItem("product_name")]);
  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (postalCode.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            // setFailureAlert(true);
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", postalCode);
          }
        });
      }
    }
    return errors;
  };

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            // setFailureAlert(true);
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const { state } = useLocation();
  useEffect(() => {
    if (state?.value === "home") {
      setProduct(sessionStorage.getItem("product_name"));
      pincodeRef.current.scrollIntoView();
    }
  }, [sessionStorage.getItem("product_name")]);

  return (
    <div className="st-blue-color" ref={pincodeRef}>
      <Topbar />
      <div className="st-content">
        <div className="st-hero-slide st-style2 st-flex">
          <div className="container">
            <div className="st-hero-text st-style1 st-color1">
              <h1 className="st-hero-title">Carpenter Services</h1>
              <h3>{product}</h3>
              <div className="st-hero-subtitle">
                Spend your time on the most important things in life.
                <br />
                Leave the home cleaning to Coollah, Singapore's No. 1<br />
                professional home cleaning service!
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="st-newsletter">
                    <form className="mailchimp st-subscribe-form" action="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                        onBlur={(e) => checkPincode(e)}
                      />
                      <button
                        type="button"
                        id="subscribe-button"
                        className="st-newsletter-btn"
                        onClick={handleClick}
                      >
                        Book Now
                      </button>
                      <span className="formError">{formErrors.postalCode}</span>
                      {}
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label
                        className="subscription-label"
                        htmlFor="subscriber-email"
                      ></label>
                    </form>
                    <div ref={footerRef}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="st-hero-img"></div>
          <div className="st-circla-animation">
            <div className="st-circle st-circle-first"></div>
            <div className="st-circle st-circle-second"></div>
          </div>

          <div className="st-wave-wrap">
            <div className="st-wave">
              <div
                className="st-wave-in"
                style={{ backgroundImage: `url(${Banner})` }}
              ></div>
              <div id="color-overlay"></div>
            </div>
          </div>
        </div>

        <section>
          <div className="st-about-wrap st-section bg-cray">
            <div className="container-fluid container1">
              <h2 className="mb-3 text-center text-red">Carpenter Services</h2>
              <div className="row">
                {data?.result[0]?.services.map((data, ind) => (
                  <Fragment key={ind}>
                    <div className="col-lg-3">
                      <div
                        className="st-iconbox st-style1 st-style3 wow fadeInUp"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.2s"
                      >
                        <img src={data.product_image} alt="Img" />
                        <h5 className="st-iconbox-title font-bold">
                          {data.product_name}
                        </h5>
                        <p>${data.price}</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: data.remarks }}
                          className="st-iconbox-text"
                        ></div>
                        <div className="text-right bottom-po">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(e) => handleBook(data)}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="">
                  <h2 className="mb-3">
                    Get your home services professionally
                  </h2>

                  <form className="mailchimp st-subscribe-form" action="">
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      pattern="[0-9]*"
                      maxLength="6"
                      className="form-control ccp per AddrClass"
                      placeholder="Please Enter Postal Code"
                      title="Please Enter Postal Code"
                      autoComplete="off"
                      tabIndex="37"
                      required=""
                      value={postalCode}
                      onChange={(e) =>
                        setPostalCode((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                    />
                    <button
                      type="button"
                      id="subscribe-button"
                      className="st-newsletter-btn"
                      onClick={handleClick}
                    >
                      Book Now
                    </button>
                    <span className="formError">{formErrors.postalCode}</span>
                    {}
                    <h5 className="subscription-success"> .</h5>
                    <h5 className="subscription-error"> .</h5>
                    <label
                      className="subscription-label"
                      htmlFor="subscriber-email"
                    ></label>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="st-about-wrap st-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4>How To Get Started</h4>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start1} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>1. Enter your postcode</h5>
                </div>
                <p>
                  Enter your postal code to check the availability of cleaners
                  in your area.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start2} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>2. Choose your cleaning plan</h5>
                </div>
                <p>
                  Select your preferred cleaning plan and secure your booking.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start3} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>3. Receive a welcome call</h5>
                </div>
                <p>
                  Your dedicated Home Advisor will be in touch with you shortly
                  to support all your needs.
                </p>
              </div>
              <div className="col-lg-3 text-center">
                <img src={Start4} alt="img" />
                <div className="st-section-heading st-style1">
                  <h5>4. Enjoy your clean house</h5>
                </div>
                <p>
                  Enjoy a sparkling home and share your feedback with us for
                  constant quality improvement.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Contact />
        {/* <MapLocation/> */}
      </div>
      <FooterRevamp />
      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
              <h5>Failure</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </div>
  );
};

export default CarpenterServices;
