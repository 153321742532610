import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef, Fragment } from "react";
import API from "../API/API";
import Topbar from "../Home/Topbar";
import Banner from "../assets/img/service-bg-5.jpg";
import Contact from "../Home/Contact";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import ModalCommon from "../Modal/modal";
import GetCoollahApp from "../Home/GetCoollahApp";
import FAQ from "../Home/Faq";
import FooterRevamp from "../FooterRevamp";

const CleaningServices = () => {
  const navigate = useNavigate();
  const footerRef = useRef();
  const pincodeRef = useRef();
  const [postalCode, setPostalCode] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [data, setData] = React.useState(null);
  const { state } = useLocation();

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  useEffect(() => {
    if (state?.value === "home") {
      setProduct(sessionStorage.getItem("product_name"));
      pincodeRef.current.scrollIntoView();
    }
  }, [sessionStorage.getItem("product_name")]);

  useEffect(() => {
    footerRef.current.scrollIntoView();
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => { });
  }, []);

  useEffect(() => { }, [sessionStorage.getItem("product_name")]);

  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        navigate("/CategoryService");
      }
    }
  };

  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.setItem("product_image", data.product_image);
    sessionStorage.setItem("remarks", data.remarks);
    setProduct(data.product_name);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    pincodeRef.current.scrollIntoView();
  };

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (postalCode.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", postalCode);
          }
        });
      }
    }
    return errors;
  };

  return (
    <div className="st-blue-color" ref={pincodeRef}>
      <Topbar />
      <div className="st-content">
        <div className="st-hero-slide st-style2 st-flex">
          <div className="container">
            <div className="st-hero-text st-style1 st-color1">
              <h1 className="st-hero-title">Cleaning Service</h1>
              <h3>{product}</h3>
              <div className="st-hero-subtitle">
                Spend your time on the most important things in life.
                <br />
                Leave the home cleaning to Coollah, Singapore's No. 1<br />
                professional home cleaning service!
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="st-newsletter">
                    <form className="mailchimp st-subscribe-form" action="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                        onBlur={(e) => checkPincode(e)}
                      />
                      <button
                        type="button"
                        id="subscribe-button"
                        className="st-newsletter-btn"
                        onClick={handleClick}
                      >
                        Book Now
                      </button>
                      <span className="formError">{formErrors.postalCode}</span>
                      { }
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label
                        className="subscription-label"
                        htmlFor="subscriber-email"
                      ></label>
                    </form>
                    <div ref={footerRef}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="st-hero-img"></div>
          <div className="st-circla-animation">
            <div className="st-circle st-circle-first"></div>
            <div className="st-circle st-circle-second"></div>
          </div>

          <div className="st-wave-wrap">
            <div className="st-wave">
              <div
                className="st-wave-in"
                style={{ backgroundImage: `url(${Banner})` }}
              ></div>
              <div id="color-overlay"></div>
            </div>
          </div>
        </div>

        <section>
          <div className="st-about-wrap st-section bg-cray">
            <div className="container-fluid container1">
              <h2 className="mb-3 text-center text-red">Cleaning Services</h2>
              <div className="row">
                {data?.result[0]?.services.map((data, ind) => (
                  <div className="col-lg-3" key={ind}>
                    <div
                      className="st-iconbox st-style1 st-style3 wow fadeInUp"
                      data-wow-duration="0.8s"
                      data-wow-delay="0.2s"
                    >
                      <img src={data.product_image} alt="Img" />
                      <h5 className="st-iconbox-title font-bold">
                        {data.product_name}
                      </h5>
                      <p>${data.price}</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.remarks }}
                        className="st-iconbox-text"
                      ></div>
                      <div className="text-right bottom-po">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={(e) => handleBook(data)}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Contact />
      </div>
      <GetCoollahApp />
      <FAQ />
      <Contact />
      <FooterRevamp />
      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
              <h5>Failure</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a className="text-blue" data-dismiss="modal" onClick={handleClose}>
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </div>
  );
};

export default CleaningServices;
