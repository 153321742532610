// import logo from "../assets/img/logo.png";
// import { NavLink } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../API/API";
import API1 from "../API/API1";
// import { StyledEngineProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import walletopen from "../assets/img/wallet-open.png";
import Marquee from "react-fast-marquee";
import Logo from "../assets/revamp-css/assets/images/logo/logo.png";
import searchIcon from "../assets/revamp-css/assets/images/logo/search.png";
// import { useSelector } from "react-redux";
import { googleLogout } from '@react-oauth/google';
const Topbar = (props) => {
  const { isBookNowLogin } = props;
  const [services, setServices] = useState([]);
  // const reloadLoginAction = useSelector(
  //   (state) => state?.account?.reloadLoginAction
  // );
  // const services = [
  //   {
  //     label: "Aircon Services",
  //     url: "AirConServices",
  //     category_id: "91",
  //   },
  //   {
  //     label: "Electrical Services",
  //     url: "ElectricalServices",
  //     category_id: "72",
  //   },
  //   {
  //     label: "AirCon Installation",
  //     url: "AirconInstallations",
  //     category_id: "91",
  //   },
  //   {
  //     label: "Handyman Services",
  //     url: "HandymanServices",
  //     category_id: "91",
  //   },
  // ];
  const [token, setGetToken] = useState("");
  const [toggle, setToggle] = useState(false);
  // const [couponList, setCouponList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [balance, setBalance] = useState("");
  const { pathname } = location;
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setToggle(false);
    }
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    const access_token = sessionStorage.getItem("access_token");
    setGetToken(access_token);
  }, [token]);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setToggle(false);
    }
  }, [dimensions]);

  useEffect(() => {
    if (pathname === "/") {
      sessionStorage.removeItem("category_id");
    }

    setIsLoading(true);
    // API1.get("load_all_active_coupons", {
    //   params: { category_id: sessionStorage.getItem("category_id") },
    // })
    //   .then((result) => {
    //     setCouponList(result.data.result);
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //   });
  }, [sessionStorage.getItem("category_id")]);

  // change Password
  const [logintype, setUserData] = useState(null);

  useEffect(() => {
    const user_data = sessionStorage.getItem("pro_login");
    console.log(user_data);
    
    if (user_data) {
      try {
       
        setUserData(user_data); // Set the parsed user data
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    } else {
      console.log("No user data found in sessionStorage.");
    }
  }, []); // Run once when the component mounts


  const [showResults, setShowResults] = React.useState(false);
  const navigate = useNavigate();
  const Logout = () => {
    googleLogout();
    sessionStorage.clear();
    navigate("/");
    setShowResults(true);
  };

  const [post, setPost] = React.useState(null);
  const [show, SetShow] = useState(true);
  const handleClick = (category_id, category_name) => {
    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem("category_name", category_name);
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("product_name");
  };

  const loadBalance = (user_id) => {
    const headers = { "content-type": "multipart/form-data" };
    const payload = { params: { user_id } };
    API.get("load_Balanceenquiry", payload, { headers }).then((resp) => {
      const { balance } = resp.data.result[0];
      setBalance(balance);
      sessionStorage.setItem("walletBalance", balance);
    });
  };

  const loadCatgories = () => {
    const headers = {
      "content-type": "multipart/form-data",
      access_token: sessionStorage.getItem("access_token"),
    };
    API.get("load_all_categories", { headers }).then((resp) => {
      if (resp?.data?.is_successful) {
        setPost(resp.data);
        setServices(resp?.data?.result);
      }
      sessionStorage.setItem("menus", JSON.stringify(resp.data.result));
    });
  };

  useEffect(() => {
    const user_id = sessionStorage.getItem("user_id");
    loadCatgories();
    if (user_id) {
      loadBalance(user_id);
    } else {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (isBookNowLogin) {
      const user_id = sessionStorage.getItem("user_id");
      if (user_id) {
        loadBalance(user_id);
        setShowResults(false);
      }
    }
  }, [isBookNowLogin]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onChangeHandle = async (value) => {
    setLoading(true);
    API.get("autocomplete?qry=" + value)
      .then((response) => {
        setOptions(response.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e, value) => {
    var page = value.category_name
      .replace(/\s+/g, "")
      .replace(/-/g, "")
      .replace("/", "");
    if (value.product_name !== "") {
      sessionStorage.setItem("category_id", value.category_id);
      sessionStorage.setItem("product_id", value.product_id);
      sessionStorage.setItem("category_name", value.category_name);
      sessionStorage.setItem("product_name", value.product_name);
      sessionStorage.removeItem("coupon_code");
      sessionStorage.removeItem("coupon_value");
      navigate("/Booking");
    } else {
      sessionStorage.setItem("category_name", value.category_name);
      sessionStorage.setItem("category_id", value.category_id);
      navigate("/" + page);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleBooknow = (coupon) => {
    sessionStorage.setItem("coupon_value", coupon.coupon_discount_value);
    sessionStorage.setItem("coupon_code", coupon.coupon_code);
    sessionStorage.setItem("category_id", coupon.category_id);
    sessionStorage.removeItem("product_name");
    var value = data.find(
      (x) => x.category_id === parseInt(coupon.category_id)
    ).category_name;
    sessionStorage.setItem("category_name", value);
    navigate("/CategoryService");
  };

  const handlePost = (e) => {
    const token = sessionStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    API.get(`redirect_to_myshop`, config).then(function (response) {
      var url = response.data.url;
      window.open(url);
    });
  };

  const toggleNav = () => {
    setToggle(!toggle);
  };

  return (
    <header className="p-0">
      <nav className="fixed-top navbar navbar-expand-lg bg-body-tertiary coollah_header_wrapper">
        <div className="container-fluid">
          <div className="d-flex">
            <Link className="navbar-brand" to={"/"}>
              <img src={Logo} alt="logo-img" />
            </Link>
            <form className="d-flex" role="search">
              <div className="collah_header_search_wrapper">
                {/* <input
                  className="form-control me-2"
                  type="text"
                  placeholder="Search Services"
                  aria-label="Search"
                /> */}
                <Autocomplete
                  id="asynchronous-demo"
                  // style={{ width: 420 }}
                  className="auto_complete_search"
                  open={open}
                  placeholder="Search Services"
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onChange={(event, value) => handleChange(event, value)}
                  getOptionSelected={(option, value) =>
                    option.category_name === value.category_name
                  }
                  getOptionLabel={(option) =>
                    option.product_name !== ""
                      ? option.category_name + "-" + option.product_name
                      : option.category_name
                  }
                  options={options}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Search Services"
                      // variant="outlined"
                      placeholder="Search Services"
                      onChange={(ev) => {
                        if (
                          ev.target.value !== "" ||
                          ev.target.value !== null
                        ) {
                          onChangeHandle(ev.target.value);
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <img src={searchIcon} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <rect width="25" height="25" fill="url(#pattern0)" />
                  <defs>
                    <pattern
                      id="pattern0"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        xlinkHref="#image0_186_225"
                        transform="scale(0.0028169)"
                      />
                    </pattern>
                  </defs>
                </svg>
              </div>
            </form>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarScroll">
            <ul
              className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll coollah_header_nav_links"
            // style="--bs-scroll-height: 100px"
            >
              <li className="nav-item">
                <Link
                  className="nav-link active collah_nav_hover_link"
                  aria-current="page"
                  to={"/"}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu">
                  {services.map((ele, ind) => {
                    return (
                      <li key={ind}>
                        <Link
                          to={
                            "/" +
                            ele.category_name
                              .replace(/\s+/g, "")
                              .replace(/-/g, "")
                              .replace("/", "")
                          }
                          className="dropdown-item"
                          onClick={() =>
                            handleClick(ele.category_id, ele.category_name)
                          }
                        >
                          {ele.category_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link collah_nav_hover_link" to="/Contact">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/Enquiry"
                  className="nav-link collah_nav_hover_active"
                >
                  Enquire Now
                </Link>
              </li>
              {showResults ? (
                <li className="nav-item">
                  <Link to="/Login" className="nav-link collah_nav_hover_link">
                    Login / Register
                  </Link>
                </li>
              ) : (
                <Fragment>
                  <li className="nav-link smooth-scroll d-flex align-items-center">
                    <img
                      src={walletopen}
                      width="23px"
                      height="23px"
                      className="mr-2"
                    ></img>{" "}
                    ${balance ? balance : 0}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user mr-2"></i>
                      {sessionStorage.getItem("first_name")}
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/LoadAppointment" className="dropdown-item">
                          <i className="fa fa-chart-pie"></i> My Account
                        </Link>
                      </li>
                      <li>
                        <Link to="/ManageProfile" className="dropdown-item">
                          <i className="fa fa-list"></i> Manage Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/ManageAllAddress" className="dropdown-item">
                          <i className="fa fa-file-text"></i> Manage Address
                        </Link>
                      </li>
                      {logintype !== "provider" && ( // Check for provider login type
                        <li>
                          <Link to="/ChangePassword" className="dropdown-item">
                            <i className="fa fa-unlock"></i> Change Password
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link to="/" className="dropdown-item" onClick={Logout}>
                          <i className="fa fa-lock"></i> Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </Fragment>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {/* <div className="bg-blue text-center p1">
          <Marquee direction="left" play={true} gradient={false}>
            {couponList?.map((ele, ind) => (
              <h5 key={ind}>
                {ele.coupon_text}
                <button
                  className="btn btn-sm btn-danger"
                  onClick={(e) => handleBooknow(ele)}
                >
                  Book Now
                </button>
              </h5>
            ))}
          </Marquee>
        </div> */}
    </header>
  );
};

export default Topbar;
