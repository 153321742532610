import { Fragment, useEffect, useState } from "react";
import defaultImg from "../src/assets/revamp-css/assets/images/logo/no-image.jpg";

const ImageFallback = ({ src, fallbackSrc, ...rest }) => {
  const fallbackImage = fallbackSrc || defaultImg; // Default fallback image
  const [imgSrc, setImgSrc] = useState(src);

  // If the src changes, update the state
  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Fragment>
      <img
        {...rest}
        src={imgSrc}
        onLoad={(e) => {
          // Check if the image loaded correctly (naturalWidth should be non-zero)
          if (e.target.naturalWidth === 0) {
            setImgSrc(fallbackImage);
          }
        }}
        onError={() => {
          // If the image fails to load, set the fallback image
          setImgSrc(fallbackImage);
        }}
        alt={rest.alt || "Image"}
      />
    </Fragment>
  );
};

export default ImageFallback;
