// import constant
import { RELOAD_LOGIN_ACTION } from "../constant";

const initialState = {
  reloadLoginAction: false,
};

const account = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RELOAD_LOGIN_ACTION:
      setAuthUser(payload);
      return {
        ...state,
        reloadLoginAction: payload,
      };

    default:
      return state;
  }
};

export default account;
