import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import API from './API/API';

const DisableSidebar = () => {
  const [screens, setScreens] = useState(null);

  useEffect(() => {

    API.get("load_screens?type=Member", {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then((response) => {
      setScreens(response.data);
    });

  }, []);
  if (!screens) return null;
  return (
    <div className="st-sidebar st-right-sidebar">
      <div className="widget widget_categories">
        <h2 className="widget-title">Dashboard</h2>
        <ul>
          {screens.result.map((screen) =>
            <li key={screen.screen_name}>
              <Link to={screen.screen_path} className="Link disabled-link">
                <i className={screen.screen_logo} ></i>
                {screen.screen_name}
              </Link>
            </li>
          )}
          {/* <li><Link to="/LoadAppointment" className='Link'><i className="fa fa-list-alt"></i> My Appointments</Link></li>
        <li><Link to="/LoadContract" className='Link'><i className="fa fa-list-ul"></i> My Contracts</Link></li>
        <li><Link to="/InstallationTicketDetails" className='Link'><i className="fa fa-info-circle"></i> Installation Ticket</Link></li>
        <li><Link to="/ServiceTicket" className='Link'><i className="fa fa-info-circle"></i> Service Ticket</Link></li>
        <li><Link to="/Invoices" className='Link'><i className="fa fa-file-text"></i> Service Ticket</Link></li> */}
        </ul>
      </div>

    </div>
  )
}
export default DisableSidebar;