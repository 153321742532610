import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Topbar from "../Home/Topbar";
import Banner from "../assets/img/service-bg-9.jpg";
import Contact from "../Home/Contact";
import FooterRevamp from "../FooterRevamp";

const AnnualService = () => {
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const handleValidate = (e) => {
    setPostalCode(e.target.value);
  };
  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        navigate("/CategoryService");
      }
    }
  };
  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  };
  return (
    <div className="st-blue-color">
      <Topbar />
      <div className="st-content" style={{ marginBottom: "412px" }}>
        <div className="st-hero-slide st-style2 st-flex">
          <div className="container">
            <div className="st-hero-text st-style1 st-color1">
              <h1 className="st-hero-title">Handyman Services</h1>
              <div className="st-hero-subtitle">
                Spend your time on the most important things in life.
                <br />
                Leave the home cleaning to Helpling, Singapore's No. 1<br />
                professional home cleaning service!
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="st-newsletter">
                    <form className="mailchimp st-subscribe-form" action="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                      />
                      <button
                        type="button"
                        id="subscribe-button"
                        className="st-newsletter-btn"
                        onClick={handleClick}
                      >
                        Book Now
                      </button>
                      <span className="formError">{formErrors.postalCode}</span>
                      {}
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label
                        className="subscription-label"
                        htmlFor="subscriber-email"
                      ></label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="st-hero-img"></div>
          <div className="st-circla-animation">
            <div className="st-circle st-circle-first"></div>
            <div className="st-circle st-circle-second"></div>
          </div>
          <div className="st-wave-wrap">
            <div className="st-wave">
              <div
                className="st-wave-in"
                style={{ backgroundImage: `url(${Banner})` }}
              ></div>
              <div id="color-overlay"></div>
            </div>
          </div>
        </div>
        <Contact />
      </div>
      <FooterRevamp />
    </div>
  );
};

export default AnnualService;
