import { Link } from "react-router-dom";
import appStore from "../assets/revamp-css/assets/images/app-store/app-02.png";
import playStore from "../assets/revamp-css/assets/images/app-store/app-01.png";


const GetCoollahApp = () => {
  return (
    <div className="coollah_app_store_sec_wrapper">
      <div className="container-fluid p-0">
        <div className="row coollah_app_store_sec">
          <div className="col col-12 col-lg-5">
            <div className="coollah_app_store_content_sec">
              <h6>
                Get The Coollah App To Access Our Huge Network Of Providers And
                Manage Your Appointments Online!
              </h6>
              <p>
                View our wide range of services, book your appointments,
                download invoices after your sessions and even chat with your
                service providers directly through the app!
              </p>
            </div>
            <div className="row coollah_app_play_store_sec">
              <div className="col col-6 col-sm-6 col-lg-6 col-md-6">
                <a
                  href="https://play.google.com/store/apps/details?id=com.coollah.customer"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playStore} alt="play-store-img" />
                </a>
              </div>
              <div className="col col-6 col-sm-6 col-lg-6 col-md-6">
                <a
                  href="https://apps.apple.com/us/app/coollah-home-services/id6560117976"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appStore} alt="app-store-img" />
                </a>
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6"></div>
        </div>
      </div>
    </div>
  );
};

export default GetCoollahApp;
