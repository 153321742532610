import FooterRevamp from './FooterRevamp';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import API from "./API/API";
import Sidebar from './Sidebar';
import CallReportTable from './CallReportTable';
import CallReportService from './CallReportService';
import SpareDetails from './SpareDetails';
import Topbar from './Home/Topbar';
const ViewContractCallReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();

  const navigate = useNavigate();
  const [remarks, setRemarks] = useState("");
  const [service, setService] = useState([]);
  const [callReport, setCallReport] = useState([]);
  const [spare, setSpare] = useState([]);
  useEffect(() => {
    API.get('load_call_reports', { params: { ticket_id: state.ticket_id } }).then(result => {
      setCallReport(result.data.result);

    });

  }, [])

  useEffect(() => {

    setIsLoading(true);

    API.get('load_single_service_ticket', { params: { member_id: sessionStorage.getItem('member_id'), ticket_id: state.ticket_id } }).then(result => {

      setSpare(result.data.result[0].spares);
      setService(result.data.result[0].services)

    });

    setIsLoading(false);

  }, [])



  return (
    <>

      <>
        <div className="st-height-70"></div>
        <div className='st-blue-color'>
          <Topbar />
        </div>

        <section className="st-page-header st-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="st-page-header-text">
                  <h1>Service Call Report</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="st-blog-wrap st-section">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <Sidebar />
              </div>
              <div className="col-md-9">
              <Link to="/ViewContract" state={{ ticket_id: state.ticket_id,contract_id:state.contract_id}} title="Click here to get back to the list" className="btn btn-round btn-primary btn-sm float-end" ><i className="fa fa-backward"></i></Link>
                {/* <Link to="/SingleInstallationTicketDetails" state={{ ticket_id: state.ticket_id }} title="Click here to get back to the list" className="btn btn-round btn-primary btn-sm float-end" ><i className="fa fa-backward"></i></Link> */}

                <div className="container-fluid">

                  <h3 className="font-w600 title mb-2 mr-auto">
                    You can see Service Call Report details here
                  </h3>

                </div>

                <br></br>
                <div className="card">
                  <div className="card-body">
                    <form >

                      <ul><li style={{ display: 'none' }}></li>
                      </ul>
                      <div>
                        <h4>Call Report Details</h4>
                        <br></br>
                        <div className="form-group row">
                          {isLoading ? (
                            <p>Loading ...</p>
                          ) : (

                            <CallReportTable columns={
                              [
                                {
                                  Header: "Report Date",
                                  accessor: "report_date"
                                },

                                {
                                  Header: "Technician Name",
                                  accessor: "tech_name",
                                  Cell: row => {
                                    const technician_name = row.row.original.tech_name
                                    if (technician_name === " ") return "NA";
                                    return technician_name;

                                  }
                                },

                                {
                                  Header: "Technician Mobile",
                                  accessor: "tech_mob",
                                  Cell: row => {
                                    const technician_name = row.row.original.tech_mob
                                    if (technician_name === " ") return "Null";
                                    return technician_name;

                                  }
                                },
                                {
                                  Header: "Technician Email",
                                  accessor: "tech_email",
                                  Cell: row => {
                                    const technician_name = row.row.original.tech_email
                                    if (technician_name === " ") return "Null";
                                    return technician_name;

                                  }
                                },
                                {
                                  Header: "Call Status",
                                  accessor: "call_status"
                                },

                              ]

                            } data={callReport} loading={isLoading} minRows={0} paging={true} />

                          )}
                        </div>
                        <br></br>

                      </div>
                      <br></br>
                      <h4>Spare Details</h4>
                      <br></br>

                      <div className="form-group-row">
                        {isLoading ? (
                          <p>Loading ...</p>
                        ) : (

                          <SpareDetails columns={
                            [
                              {
                                Header: "Spare Name",
                                accessor: "spareName"
                              },

                              {
                                Header: "Cost",
                                accessor: "cost"
                              },

                              {
                                Header: "Quantity",
                                accessor: "qty"
                              },
                              {
                                Header: "Total",
                                accessor: "total",

                              },



                            ]

                          } data={spare} loading={isLoading} minRows={0} paging={true} />

                        )}
                      </div>

                      <br></br>
                      <h4>Service</h4>
                      <br></br>
                      <div className="form-group-row">
                        {isLoading ? (
                          <p>Loading ...</p>
                        ) : (

                          <CallReportService columns={
                            [
                              {
                                Header: "Service Name",
                                accessor: "serviceName"
                              },

                              {
                                Header: "Cost",
                                accessor: "cost"
                              },

                              {
                                Header: "Quantity",
                                accessor: "qty"
                              },
                              {
                                Header: "Total",
                                accessor: "total",

                              },
                            ]

                          } data={service} loading={isLoading} minRows={0} paging={true} />

                        )}
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterRevamp />

      </>

    </>
  )
}

export default ViewContractCallReport;