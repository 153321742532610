import React, { useRef } from "react";
import Topbar from "./Home/Topbar";
import FooterRevamp from "./FooterRevamp";

const Privacy = () => {
  const footerRef = useRef();

  return (
    <div className="st-blue-color">
      <Topbar />

      <section className="st-page-header st-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="st-page-header-text">
                <h1>Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="st-blog-wrap st-section tabbo">
          <div className="container">
            <h5>DATA PROTECTION NOTICE</h5>

            <p className="text-justify">
              This Data Protection Notice (“Coollah Pte Ltd”) sets out the basis
              which (“we”, “us”, or “our”) may collect, use, disclose or
              otherwise process personal data of our customers in accordance
              with the Personal Data Protection Act (“PDPA”). This Notice
              applies to personal data in our possession or under our control,
              including personal data in the possession of organisations which
              we have engaged to collect, use, disclose or process personal data
              for our purposes.
            </p>

            <h5>PERSONAL DATA</h5>
            <p className="text-justify">
              1.As used in this Notice: “customer” means an individual who (a)
              has contacted us through any means to find out more about any
              goods or services, we provide, or (b) may, or has, entered into a
              contract with us for the supply of any goods or services by us;
              and “Personal data” means data, whether true or not, about a
              customer who can be identified: (a) from that data; or (b) from
              that data and other information to which we have or are likely to
              have access.
            </p>
            <p className="text-justify">
              2.Depending on the nature of your interaction with us, some
              examples of personal data which we may collect from you include
              your name and identification information such as your NRIC number,
              contact information such as your address, email address or
              telephone number, nationality, gender, date of birth, marital
              status, photographs, and other audio-visual information,
              employment information and financial information such as credit
              card numbers, debit card numbers or bank account information.
            </p>
            <p className="text-justify">
              3. Other terms used in this Notice shall have the meanings given
              to them in the PDPA (where the context so permits). COLLECTION,
              USE AND DISCLOSURE OF PERSONAL DATA
            </p>
            <p className="text-justify">
              4. We generally do not collect your personal data unless (a) it is
              provided to us voluntarily by you directly or via a third party
              who has been duly authorised by you to disclose your personal data
              to us or authorised representative after (i) Coollah Pte Ltd (or
              your authorised representative) have been notified of the purposes
              for which the data is collected, and (ii) Coollah Pte Ltd (or your
              authorised representative) have provided written consent to the
              collection and usage of your personal data for those purposes, or
              (b) collection and use of personal data without consent is
              permitted or required by the PDPA or other laws. We shall seek
              your consent before collecting any additional personal data and
              before using your personal data for a purpose which has not been
              notified to you (except where permitted or authorised by law).
            </p>
            <p className="text-justify">
              5. We may collect and use your personal data for any or all of the
              following purposes: (a) performing obligations in the course of or
              in connection with our provision of the goods and/or services
              requested by you; (b) verifying your identity; (c) responding to,
              handling, and processing queries, requests, applications,
              complaints, and feedback from you; SAMPLE CLAUSES AND TEMPLATES
              FOR CUSTOMERS (published 17 October 2017) (d) managing your
              relationship with us; (e) processing payment or credit
              transactions; (f) sending your marketing information about our
              goods or services including notifying you of our marketing events,
              initiatives and promotions, lucky draws, membership and rewards
              schemes and other promotions; (g) complying with any applicable
              laws, regulations, codes of practice, guidelines, or rules, or to
              assist in law enforcement and investigations conducted by any
              governmental and/or regulatory authority; (h) any other purposes
              for which you have provided the information; (i) transmitting to
              any unaffiliated third parties including our third-party service
              providers and agents, and relevant governmental and/or regulatory
              authorities, whether in Singapore or abroad, for the
              aforementioned purposes; and (j) any other incidental business
              purposes related to or in connection with the above.
            </p>
            <p className="text-justify">
              6. We may disclose your personal data: (a) where such disclosure
              is required for performing obligations in the course of or in
              connection with our provision of the goods or services requested
              by you; or (b) to third party service providers, agents and other
              organisations we have engaged to perform any of the functions
              listed in clause 5 above for us.
            </p>
            <p className="text-justify">
              7. The purposes listed in the above clauses may continue to apply
              even in situations where your relationship with us (for example,
              pursuant to a contract) has been terminated or altered in anyway,
              for a reasonable period thereafter (including, where applicable, a
              period to enable us to enforce our rights under any contract with
              you). WITHDRAWING YOUR CONSENT
            </p>
            <p className="text-justify">
              8. The consent that you provide for the collection, use and
              disclosure of your personal data will remain valid until such time
              it is being withdrawn by you in writing. You may withdraw consent
              and request us to stop using and/or disclosing your personal data
              for any or all of the purposes listed above by submitting your
              request in writing or via email to our Data Protection Officer at
              the contact details provided below.
            </p>
            <p className="text-justify">
              9. Upon receipt of your written request to withdraw your consent,
              we may require reasonable time (depending on the complexity of the
              request and its impact on our relationship with you) for your
              request to be processed and for us to notify you of the
              consequences of us acceding to the same, including any legal
              consequences which may affect your rights and liabilities to us.
              In general, we shall seek to process your request within ten (10)
              business days of receiving it.
            </p>
            <p className="text-justify">
              10. Whilst we respect your decision to withdraw your consent,
              please note that depending on the nature and scope of your
              request, we may not be in a position to continue providing our
              good SAMPLE CLAUSES AND TEMPLATES FOR CUSTOMERS (published 17
              October 2017) or services to you and we shall, in such
              circumstances, notify you before completing the processing of your
              request. Should you decide to cancel your withdrawal of consent,
              please inform us in writing in the manner described in clause 8
              above.
            </p>
            <p className="text-justify">
              11. Please note that withdrawing consent does not affect our right
              to continue to collect, use and disclose personal data where such
              collection, use and disclose without consent is permitted or
              required under applicable laws. ACCESS TO AND CORRECTION OF
              PERSONAL DATA
            </p>
            <p className="text-justify">
              12. If you wish to make (a) an access request for access to a copy
              of the personal data which we hold about you or information about
              the ways in which we use or disclose your personal data, or (b) a
              correction request to correct or update any of your personal data
              which we hold about you, you may submit your request in writing or
              via email to our Data Protection Officer at the contact details
              provided below.
            </p>
            <p className="text-justify">
              13. Please note that a reasonable fee may be charged for an access
              request. If so, we will inform you of the fee before processing
              your request.
            </p>
            <p className="text-justify">
              14. We will respond to your request as soon as reasonably
              possible. Should we not be able to respond to your request within
              thirty (30) days after receiving your request, we will inform you
              in writing within thirty (30) days of the time by which we will be
              able to respond to your request. If we are unable to provide you
              with any personal data or to make a correction requested by you,
              we shall generally inform you of the reasons why we are unable to
              do so (except where we are not required to do so under the PDPA).
              PROTECTION OF PERSONAL DATA
            </p>
            <p className="text-justify">
              15. To safeguard your personal data from unauthorised access,
              collection, use, disclosure, copying, modification, disposal, or
              similar risks, we have introduced appropriate administrative,
              physical and technical measures such as up-to-date antivirus
              protection, encryption and the use of privacy filters to secure
              all storage and transmission of personal data by us, and
              disclosing personal data both internally and to our authorised
              third-party service providers and agents only on a need-to-know
              basis.
            </p>
            <p className="text-justify">
              16. You should be aware, however, that no method of transmission
              over the Internet or method of electronic storage is completely
              secure. While security cannot be guaranteed, we strive to protect
              the security of your information and are constantly reviewing and
              enhancing our information security measures. ACCURACY OF PERSONAL
              DATA
            </p>
            <p className="text-justify">
              17. We generally rely on personal data provided by you (or your
              authorised representative). In order to ensure that your personal
              data is current, complete and accurate, please update us if there
              are changes to your personal data by informing our Data Protection
              Officer in writing or via email at the contact details provided
              below. RETENTION OF PERSONAL DATA
            </p>
            <p className="text-justify">
              18. We may retain your personal data for as long as it is
              necessary to fulfil the purpose for which it was collected, or as
              required or permitted by applicable laws. SAMPLE CLAUSES AND
              TEMPLATES FOR CUSTOMERS (published 17 October 2017)
            </p>
            <p className="text-justify">
              19. We will cease to retain your personal data, or remove the
              means by which the data can be associated with you, as soon as it
              is reasonable to assume that such retention no longer serves the
              purpose for which the personal data was collected, and is no
              longer necessary for legal or business purposes. TRANSFERS OF
              PERSONAL DATA OUTSIDE OF SINGAPORE
            </p>
            <p className="text-justify">
              20. We generally do not transfer your personal data to countries
              outside of Singapore. However, if we do so, we will obtain your
              consent for the transfer to be made and we will take steps to
              ensure that your personal data continues to receive a standard of
              protection that is at least comparable to that provided under the
              PDPA. DATA PROTECTION OFFICER
            </p>
            <p className="text-justify">
              21. You may contact our Data Protection Officer if you have any
              enquiries or feedback on our personal data protection policies and
              procedures, or if you wish to make any request, in the following
              manner: enquiry@coollah.com. EFFECT OF NOTICE AND CHANGES TO
              NOTICE
            </p>
            <p className="text-justify">
              22. This Notice applies in conjunction with any other notices,
              contractual clauses and consent clauses that apply in relation to
              the collection, use and disclosure of your personal data by us.
            </p>
            <p className="text-justify">
              23. We may revise this Notice from time to time without any prior
              notice. You may determine if any such revision has taken place by
              referring to the date on which this Notice was last updated. Your
              continued use of our services constitutes your acknowledgement and
              acceptance of such changes.
            </p>
            <p className="text-justify">Effective date: [01/01/2023]</p>
            <p className="text-justify">Last updated: [01/01/2023]</p>

            <h5>ACKNOWLEDGEMENT AND CONSENT</h5>
            <p className="text-justify">
              I acknowledge that I have read and understood the [above Data
              Protection Notice], and consent to the collection, use and
              disclosure of my personal data by Cool Lah Pte Ltd 's for the
              purposes set out in the Notice.
            </p>
          </div>
        </div>
      </section>
      <FooterRevamp />
    </div>
  );
};

export default Privacy;
