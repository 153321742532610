import React, { useState, useEffect, useRef, Fragment } from "react";
import "react-multi-carousel/lib/styles.css";
import API from "../API/API";
import Loading from "../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Scrollbar,
  A11y,
  EffectFade,
  Pagination,
} from "swiper/modules";
import ratingImg from "../assets/revamp-css/assets/images/customer-ser/rating.png";
import { Rating } from "react-simple-star-rating";

const Testimonials = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  useEffect(() => {
    API.get("load_Testimonials", {
      headers: {
        "content-type": "multipart/form-data",
        access_token: sessionStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log("response::", response);
      if (response?.data?.is_successful) {
        setData(response?.data?.result);
      }
    });
  }, []);

  const breakpoints = {
    390: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 51,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 51,
    },
  };

  return (
    <section>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="coollah_customer_services_sec">
          <div className="container">
            <div className="row">
              <div className="col col-12 col-lg-12">
                <div className="coollah_cus_serv_title">
                  <h6>Testimonial</h6>
                  <h2>Our Customers are Saying</h2>
                </div>
                <div className="coollah_service_doubled_border">
                  <span className="border_first"></span>
                  <span className="border_second"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-12 col-lg-12">
                <Swiper
                  modules={[
                    Navigation,
                    Scrollbar,
                    A11y,
                    EffectFade,
                    Pagination,
                  ]}
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={false}
                  className="coollah_cust_serv_slider"
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                  }}
                  breakpoints={breakpoints}
                >
                  {data?.map((ele, ind) => {
                    const { guestName,starRating, testimonial } = ele;
                    return (
                      <SwiperSlide key={ind}>
                        <div  className="coollah_customer_card_say_wrap">
                          <div className="card coollah_customer_card">
                          <div className="card-footer">
                              <div className="coollah_cus_ser_wrapper">
                                {/* <div className="coollah_cust_serv_card_footer_img">
                                  <img src={userImg} alt="user-img" />
                                </div> */}
                                <div id="profileImage" className="coollah_test">
                                {guestName?.slice(0, 1).toUpperCase()}
                              </div>
                                <h5>{guestName}</h5>
                              </div>
                            </div>
                            <div className="card-header">
                              <div className="coollah_customer_rating_sec">
                                {/* <img src={ratingImg} alt="rating-img" /> */}
                                <Rating
                                  size={20}
                                  transition
                                  allowFraction
                                  disableFillHover={true}
                                  allowHover={false}
                                  readonly={true}
                                  initialValue={starRating}
                                  fillColorArray={["#FFDF00"]}
                                />
                              </div>
                              {/* <p>Easy & Fair Pricing</p> */}
                            </div>
                            <div className="card-body">
                              <div className="coollah_cust_serv_content">
                                <p>{testimonial}</p>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  {data?.length > 3 && (
                    <Fragment>
                      <button
                        ref={navigationNextRef}
                        className="coollah_swiper_btn_next"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          height={20}
                          width={20}
                        >
                          <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                        </svg>
                      </button>
                      <button
                        ref={navigationPrevRef}
                        className="coollah_swiper_btn_prev"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          height={20}
                          width={20}
                        >
                          <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </button>
                    </Fragment>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Testimonials;
