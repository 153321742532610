import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import API from "../API/API";
import { Modal } from "react-bootstrap";
import Topbar from "../Home/Topbar";
import Contact from "../Home/Contact";
import postCodeImg from "../assets/revamp-css/assets/images/services/post-code.png";
import cleanHouseImg from "../assets/revamp-css/assets/images/services/clean-house.png";
import welcomeCallImg from "../assets/revamp-css/assets/images/services/welcome-call.png";
import cleanPlanImg from "../assets/revamp-css/assets/images/services/cleaning-plan.png";
import electricalPostImg from "../assets/revamp-css/assets/images/post/ele-ser.png";
import service_cards from "../assets/revamp-css/assets/images/services/service_cardsE-1.jpg";
import service_cards2 from "../assets/revamp-css/assets/images/services/service_cardsE-2.jpg";
import service_cards3 from "../assets/revamp-css/assets/images/services/service_cardsE-3.jpg";
import service_cards4 from "../assets/revamp-css/assets/images/services/service_cardsE-4.jpg";
import card_svg from "../assets/revamp-css/assets/images/services/card_svg.png";
import service_booking_man from "../assets/revamp-css/assets/images/services/service_booking_man.png";
import Axios from "axios";
import ModalCommon from "../Modal/modal";
import FooterRevamp from "../FooterRevamp";
import GetCoollahApp from "../Home/GetCoollahApp";
import FAQ from "../Home/Faq";
import ImageFallback from "../ImageFallback";

const ElectricalServicesRevamp = () => {
  const navigate = useNavigate();
  const getCategoryId = sessionStorage.getItem("category_id");
  const [postalCode, setPostalCode] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const footerRef = useRef();
  const pincodeRef = useRef();
  const [data, setData] = React.useState(null);
  const [membertype, setMemberType] = useState("Non Commercial");

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  const handleChangeType = (type) => {
    setMemberType(type);
  };

  useEffect(() => {
    footerRef?.current?.scrollIntoView();
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
          // MemberType: membertype,
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        if (response?.data?.is_successful) {
          setData(response.data?.result?.[0]?.services);
        }
      })
      .catch((error) => {});
  }, [membertype]);

  useEffect(() => {}, [sessionStorage.getItem("product_name")]);
  const handleClick = () => {
    setFormErrors(validate(postalCode));
    sessionStorage.setItem("Pincode", postalCode);
    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        footerRef.current.scrollIntoView();
        // navigate("/CategoryService");
      }
    }
  };

  const { state } = useLocation();
  useEffect(() => {
    if (state?.value === "home") {
      setProduct(sessionStorage.getItem("product_name"));
      pincodeRef.current.scrollIntoView();
    }
  }, [sessionStorage.getItem("product_name")]);

  const handleBook = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);
    sessionStorage.setItem("product_image", data.product_image);
    sessionStorage.setItem("remarks", data.remarks);
    setProduct(data.product_name);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    pincodeRef.current.scrollIntoView();
  };

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    }
    return errors;
  };

  const loadCatgories = () => {
    const headers = {
      "content-type": "multipart/form-data",
      access_token: sessionStorage.getItem("access_token"),
    };
    API.get("load_all_categories", { headers }).then((resp) => {
      if (resp?.data?.is_successful) {
        setServices(resp.data?.result);
      }
      sessionStorage.setItem("menus", JSON.stringify(resp.data.result));
    });
  };

  const handleClickCategories = (category_id, category_name) => {
    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem("category_name", category_name);
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("product_name");
  };

  useEffect(() => {
    loadCatgories();
  }, []);

  return (
    <Fragment>
      <Topbar />
      {/* <div className="st-blue-color" ref={pincodeRef}>
        <div className="st-content">
          <div className="st-hero-slide st-style2 st-flex">
            <div className="container">
              <div className="st-hero-text st-style1 st-color1">
                <h1 className="st-hero-title">Electrical Services</h1>
                <h3>{product}</h3>
                <div className="st-hero-subtitle">
                  Spend your time on the most important things in life.
                  <br />
                  Leave the home cleaning to Coollah, Singapore's No. 1<br />
                  professional home cleaning service!
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="st-newsletter">
                      <form className="mailchimp st-subscribe-form" action="">
                        <input
                          type="text"
                          id="pincode"
                          name="pincode"
                          pattern="[0-9]*"
                          maxLength="6"
                          placeholder="Please Enter Postal Code"
                          className="form-control ccp per AddrClass"
                          title="Please Enter Postal Code"
                          autoComplete="off"
                          tabIndex="37"
                          required=""
                          value={postalCode}
                          onChange={(e) =>
                            setPostalCode((v) =>
                              e.target.validity.valid ? e.target.value : v
                            )
                          }
                          onBlur={(e) => checkPincode(e)}
                        />
                        <button
                          type="button"
                          id="subscribe-button"
                          className="st-newsletter-btn"
                          onClick={handleClick}
                        >
                          Book Now
                        </button>

                        <span className="formError">
                          {formErrors.postalCode}
                        </span>
                        {}
                        <h5 className="subscription-success"> .</h5>
                        <h5 className="subscription-error"> .</h5>
                        <label
                          className="subscription-label"
                          htmlFor="subscriber-email"
                        ></label>
                      </form>
                      <div ref={footerRef}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="st-hero-img"></div>

            <div className="st-circla-animation">
              <div className="st-circle st-circle-first"></div>
              <div className="st-circle st-circle-second"></div>
            </div>

            <div className="st-wave-wrap">
              <div className="st-wave">
                <div
                  className="st-wave-in"
                  style={{ backgroundImage: `url(${Banner})` }}
                ></div>
                <div id="color-overlay"></div>
              </div>
            </div>
          </div>

          <section>
            <div className="st-about-wrap st-section bg-cray">
              <div className="container-fluid container1">
                <h2 className="mb-3 text-center text-red">
                  Electrical Services
                </h2>
                <div className="row">
                  {data?.result[0]?.services.map((data, ind) => (
                    <div className="col-lg-3" key={ind}>
                      <div
                        className="st-iconbox st-style1 st-style3 wow fadeInUp"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.2s"
                      >
                        <img src={data.product_image} alt="Img" />
                        <h5 className="st-iconbox-title font-bold">
                          {data.product_name}
                        </h5>
                        <p>${data.price}</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: data.remarks }}
                          className="st-iconbox-text"
                        ></div>
                        <div className="text-right bottom-po">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(e) => handleBook(data)}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="st-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                  <div className="">
                    <h2 className="mb-3">
                      Get your home services professionally
                    </h2>

                    <form className="mailchimp st-subscribe-form" action="">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        className="form-control ccp per AddrClass"
                        placeholder="Please Enter Postal Code"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                      />
                      <button
                        type="button"
                        id="subscribe-button"
                        className="st-newsletter-btn"
                        onClick={handleClick}
                      >
                        Book Now
                      </button>
                      <span className="formError">{formErrors.postalCode}</span>
                      {}
                      <h5 className="subscription-success"> .</h5>
                      <h5 className="subscription-error"> .</h5>
                      <label
                        className="subscription-label"
                        htmlFor="subscriber-email"
                      ></label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="st-about-wrap st-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h4>How To Get Started</h4>
                </div>
                <div className="col-lg-3 text-center">
                  <img src={Start1} alt="img" />
                  <div className="st-section-heading st-style1">
                    <h5>1. Enter your postcode</h5>
                  </div>
                  <p>
                    Enter your postal code to check the availability of cleaners
                    in your area.
                  </p>
                </div>
                <div className="col-lg-3 text-center">
                  <img src={Start2} alt="img" />
                  <div className="st-section-heading st-style1">
                    <h5>2. Choose your cleaning plan</h5>
                  </div>
                  <p>
                    Select your preferred cleaning plan and secure your booking.
                  </p>
                </div>
                <div className="col-lg-3 text-center">
                  <img src={Start3} alt="img" />
                  <div className="st-section-heading st-style1">
                    <h5>3. Receive a welcome call</h5>
                  </div>
                  <p>
                    Your dedicated Home Advisor will be in touch with you
                    shortly to support all your needs.
                  </p>
                </div>
                <div className="col-lg-3 text-center">
                  <img src={Start4} alt="img" />
                  <div className="st-section-heading st-style1">
                    <h5>4. Enjoy your clean house</h5>
                  </div>
                  <p>
                    Enjoy a sparkling home and share your feedback with us for
                    constant quality improvement.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact />
        </div>
      </div> */}
      <div className="container-fluid over-hid" ref={pincodeRef}>
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_sec_wrapper3">
              <div className="coollah_service_main_content">
                <div className="coollah_service_realtive_div">
                  <div className="coollah_service_absolute_content">
                    <h1 className="text-nowrap">Electrical Services</h1>
                    <p>Empower your space with Coollah's Electrical Services, where precision meets innovation for all your residential electrical needs.</p>
                    <form className="coollah_service_main_form" action="">
                      <input
                        id="pincode"
                        name="pincode"
                        pattern="[0-9]*"
                        maxLength="6"
                        placeholder="Please Enter Postal Code"
                        className="form-control ccp per AddrClass"
                        title="Please Enter Postal Code"
                        autoComplete="off"
                        tabIndex="37"
                        required=""
                        value={postalCode}
                        onChange={(e) =>
                          setPostalCode((v) =>
                            e.target.validity.valid ? e.target.value : v
                          )
                        }
                        onBlur={(e) => checkPincode(e)}
                      />
                      <button type="button" onClick={handleClick}>
                        Book Now
                      </button>
                    </form>
                    <span className="formError">{formErrors.postalCode}</span>
                    <div ref={footerRef}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid" ref={footerRef}>
        <div className="row">
          <div className="col col-12 col-lg-3 order-lg-1 order-2">
            <div className="coollah_service_lhs">
              <div className="coollah_service_main_page">
                <p>Select Service</p>
                <div className="collah_services_list_wrapp">
                  <div className="row">
                    {services.map((ele, ind) => {
                      const { category_name, category_image, category_id } =
                        ele;
                      return (
                        <div
                          onClick={() =>
                            handleClickCategories(category_id, category_name)
                          }
                          key={ind}
                          className="col col-6 col-md-4"
                        >
                          <Link
                            to={
                              "/" +
                              category_name
                                .replace(/\s+/g, "")
                                .replace(/-/g, "")
                                .replace("/", "")
                            }
                          >
                            <div className="coollah_service_explain">
                              <ImageFallback
                                src={category_image}
                                alt={"service_img"}
                              />
                              <p>{category_name}</p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="coollah_service_aircon_list">
                <img src={electricalPostImg} alt="ele-service-img" />
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-9 order-lg-2 order-1">
            <div className="row">
              <div className="col-md-12">
                <div className="coollah_rhs">
                  <div className="coollah_service_services">
                    <div className="coollah_residential_height_wrapper">
                      <div className="row">
                        {data?.length === 0 && (
                          <div className="col-md-12">
                            <div className="text-center my-5">
                              <h3>No Services Found</h3>
                            </div>
                          </div>
                        )}
                        {data?.map((data, ind) => (
                          <div className="col-md-12" key={ind}>
                            <div className="coollah_service_chemical">
                              <div className="row">
                                <div className="col col-md-9">
                                  <div className="coollah_service_chemical_content">
                                    <h3> {data.product_name}</h3>
                                    <span className="heading_border"></span>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: data.remarks,
                                      }}
                                    ></p>
                                    <p className="price">
                                      Starts at <span>${data.price}</span>{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="col col-md-3">
                                  <div className="coollah_service_chem_img_container">
                                    <ImageFallback
                                      src={data.product_image}
                                      alt={"chemical_service_img"}
                                    />
                                    <button onClick={(e) => handleBook(data)}>
                                      Book Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_service_over">
              <h2>What does Our Electrical Service Cover?</h2>
              <div className="coollah_service_doubled_border">
                <span className="border_first"></span>
                <span className="border_second"></span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="coollah_service_card_sec">
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>Our expert team provides installation of electrical systems and wiring for homes and businesses.</li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>We ensure precision in routing and connecting electrical components for safe and efficient operation.</li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Installation and Wiring</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards2}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>We deliver rapid response to electrical issues, with skilled technicians diagnosing and resolving problems.</li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>We offer thorough troubleshooting to identify and address the root causes of electrical malfunctions.</li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Repairs and Troubleshooting</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards3}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>Our team conducts comprehensive electrical safety inspections to identify potential hazards and ensure compliance with regulations.</li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>We provide recommendations for safety improvements and preventive measures.</li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Safety Inspections</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="coollah_service_card_slide">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={service_cards4}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <div className="coollah_service_card_body">
                          <div className="coollah_service_body_wrapper">
                            <div className="coollah_service_card_list">
                              <li>We offer a wide range of services including Installation, repair, and maintenance of indoor and outdoor lighting systems.</li>
                            </div>
                            <div className="coollah_service_card_list">
                              <li>Our energy-efficient lighting options and design consultation for ambiance and functionality ensure the combination of functionality with elegance.</li>
                            </div>
                            <div className="coollah_service_card_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="280"
                                height="50"
                                viewBox="0 0 280 50"
                                fill="none"
                              >
                                <path
                                  d="M0 0H280L240.5 50H0V0Z"
                                  fill="#1A6DC6"
                                />
                              </svg>
                              <h5>Lighting Solutions</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row coollah_service_book">
          <div className="col col-12 col-md-12 col-lg-6">
            <div className="coollah_service_booking_man">
              <img src={service_booking_man} alt="Service_bboking_man_img" />
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-4">
            <div className="coollah_service_connect_form">
              <p>Get Your Services Professionally</p>
              <form className="coollah_service_booking_form">
                <input
                  name="pincode"
                  pattern="[0-9]*"
                  maxLength="6"
                  placeholder="Please Enter Postal Code"
                  title="Please Enter Postal Code"
                  autoComplete="off"
                  tabIndex="37"
                  required=""
                  value={postalCode}
                  onChange={(e) =>
                    setPostalCode((v) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                  onBlur={(e) => checkPincode(e)}
                />
                <button type="button" onClick={handleClick}>
                  Book Now
                </button>
              </form>
            </div>
          </div>
        </div>
        
        <div className="coollah_service_circles_mobile">
          <div className="row ">
            <div className="col-md-12">
              <div className="coollah_service_started">
                <h2>How to Get Started</h2>
                <div className="coollah_service_doubled_border">
                  <span className="border_first"></span>
                  <span className="border_second"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="coollah_service_circles">
            <div className="row">
              <div className="bg_sec">
                <div className="col-md-12">
                  <div className="bg-dot"></div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_location">
                  <img src={postCodeImg} alt="Location_image" />
                  <div className="coollah_service_content">
                    <h3>Enter your postcode</h3>
                    <p>Begin your journey with Coollah's service by entering your pin code on our application. Instantly check service availability in your area and access service plans tailored to your location, ensuring a seamless and personalized experience.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_calender">
                  <img src={cleanPlanImg} alt="Calender_image" />
                  <div className="coollah_service_content">
                    <h3>Choose your Service plan</h3>
                    <p>Select the service plan that best suits your needs, whether it's standard service, deep service, or specialized services. Each plan comes with detailed descriptions, empowering you to make an informed choice. Choose the frequency that aligns with your lifestyle, from one-time service to convenient recurring schedules.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_telecalling">
                  <img src={welcomeCallImg} alt="Telecalling_image" />
                  <div className="coollah_service_content">
                    <h3>Receive a welcome call</h3>
                    <p>After choosing your service plan, our dedicatedservice team will reach out with a warm welcome call. Confirm service details, schedule your service appointment, and have any questions addressed. We strive for a personalized and hassle-free experience, ensuring your complete satisfaction.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="coollah_service_circle_success">
                  <img src={cleanHouseImg} alt="Success_logo" />
                  <div className="coollah_service_content">
                    <h3>Enjoy Our Service</h3>
                    <p>With all administrative details sorted, it's time to savor the convenience of our top-notch service. Our highly trained professionals arrive at your scheduled time, equipped with tools and materials to transform your living space. Sit back, relax, and witness your home refreshed and revitalized.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="coollah_circle_connector_waves">
              <img
                src="./assets/images/services/connector.png"
                alt="Connectin_waves_image"
              />
            </div> */}
          </div>
        </div>
        
      </div>
      <GetCoollahApp />
      <FAQ categoryId={getCategoryId} />
      <Contact />
      <FooterRevamp />
      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
            <h5 className="mb-2">Not Found</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </Fragment>
  );
};

export default ElectricalServicesRevamp;
