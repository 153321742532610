import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "./API/API";
import Loading from "./Loader";
import FooterRevamp from "./FooterRevamp";
import Topbar from "./Home/Topbar";
const ServiceSelection = () => {
  const [post, setPost] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [allData, setAllData] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    API.get("load_all_categories", {
      headers: {
        "content-type": "multipart/form-data",
        access_token: sessionStorage.getItem("access_token"),
      },
    }).then((response) => {
      setPost(response.data);
      console.log(response.data);
      var value = response.data.result[0].category_id;

      API.get(
        "load_all_services",
        { params: { category_id: value, tab: "Y" } },
        {
          headers: {
            "content-type": "multipart/form-data",
            access_token: sessionStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => {
          setData(response.data);

          {
            response.data.result.map((data) => {
              console.log("category_name", data.category_name);
              console.log("services", data.services);
              {
                data.services.map((products) => {
                  console.log("product_name", products.product_name);
                });
              }
            });
          }
        })
        .catch((error) => {});
    });
  }, []);
  const handleCategory = (post) => {
    setAllData([]);
    sessionStorage.setItem("category_id", post.category_id);
    sessionStorage.setItem("category_name", post.category_name);
    API.get(
      "load_all_services",
      { params: { category_id: post.category_id, tab: "Y" } },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  };
  const handleAllCategory = (category_id) => {
    API.get(
      "load_all_services",
      { params: { tab: "Y" } },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setAllData(response.data);
      })
      .catch((error) => {});
  };
  const handleClick = (datas, name) => {
    if (name === "allServices") {
      var value = allData.result.find(
        (x) => x.category_id === datas.category_id
      ).category_name;
      sessionStorage.setItem("category_name", value);
    } else {
      var value = data.result.find(
        (x) => x.category_id === datas.category_id
      ).category_name;
      sessionStorage.setItem("category_name", value);
    }
    var categoryName = sessionStorage.getItem("category_name");
    sessionStorage.setItem("category_id", datas.category_id);
    sessionStorage.setItem("category_name", categoryName);
    sessionStorage.setItem("product_id", datas.product_id);
    sessionStorage.removeItem("coupon_code");
    sessionStorage.removeItem("coupon_value");
    sessionStorage.setItem("product_name", datas.product_name);

    navigate("/Booking");
  };
  return (
    <>
      <Topbar />
      {isLoading ? (
        <Loading />
      ) : (
        <section className="st-section bg-cray">
          <div className="container-fluid container1">
            <div className="row">
              <div className="col-md-12 text-center">
                <h4>Our Services</h4>
              </div>
            </div>
            <nav>
              <div
                className="nav nav-tabs nav-fill"
                id="nav-tab"
                role="tablist"
              >
                <Link
                  className="nav-item nav-link"
                  id="all-services-tab"
                  data-bs-toggle="tab"
                  to="#all-services"
                  role="tab"
                  aria-controls="all-services"
                  aria-selected="false"
                  onClick={(e) => handleAllCategory()}
                >
                  All Services
                </Link>

                {post?.result?.map((post, index) => (
                  <>
                    {index === 0 ? (
                      <Link
                        className="nav-item nav-link active"
                        id={post.category_id}
                        data-bs-toggle="tab"
                        to={
                          "#" +
                          post.category_name
                            .replace(/\s+/g, "")
                            .replace(/-/g, "")
                            .replace("/", "")
                        }
                        role="tab"
                        aria-controls={post.category_name
                          .replace(/\s+/g, "")
                          .replace(/-/g, "")
                          .replace("/", "")}
                        aria-selected="true"
                        onClick={(e) => handleCategory(post)}
                      >
                        {post.category_name}
                      </Link>
                    ) : (
                      <Link
                        className="nav-item nav-link"
                        id={post.category_id}
                        data-bs-toggle="tab"
                        to={
                          "#" +
                          post.category_name
                            .replace(/\s+/g, "")
                            .replace(/-/g, "")
                            .replace("/", "")
                        }
                        role="tab"
                        aria-controls={post.category_name
                          .replace(/\s+/g, "")
                          .replace(/-/g, "")
                          .replace("/", "")}
                        aria-selected="false"
                        onClick={(e) => handleCategory(post)}
                      >
                        {post.category_name}
                      </Link>
                    )}
                  </>
                ))}
              </div>

              <div className="tab-content" id="nav-tabContent">
                {/* All Services */}
                <div
                  className="tab-pane fade py-3"
                  id="all-services"
                  role="tabpanel"
                  aria-labelledby="all-services-tab"
                >
                  <div className="row">
                    {allData?.result?.map((item, index) => (
                      <>
                        <h4>{item.category_name}</h4>

                        {item.services.map((service, i) => (
                          <div className="col-lg-3" key={i}>
                            <div
                              className="st-iconbox st-style1 st-style3 wow fadeInUp"
                              data-wow-duration="0.8s"
                              data-wow-delay="0.2s"
                            >
                              <h5 className="st-iconbox-title font-bold">
                                {service.product_name}
                              </h5>
                              <p>{service.price}</p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: service.remarks,
                                }}
                                className="st-iconbox-text"
                              ></div>
                              <div className="text-right bottom-po">
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={(e) =>
                                    handleClick(service, "allServices")
                                  }
                                >
                                  Book Now
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                </div>

                {/* individual services */}
                <div className="row">
                  {data?.result[0]?.services.map((item, index) => (
                    <>
                      {/* <div
                  className="active show"
                  id={item.category_id}
                  role="tabpanel"
                  aria-labelledby={item.category_name.replace(/\s+/g, "").replace(/-/g, "").replace("/", "")}
                >
           */}

                      <div className="col-lg-3 py-3 active show">
                        <div
                          className="st-iconbox st-style1 st-style3 wow fadeInUp"
                          data-wow-duration="0.8s"
                          data-wow-delay="0.2s"
                        >
                          <h5 className="st-iconbox-title font-bold">
                            {item.product_name}
                          </h5>
                          <p>{item.price}</p>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.remarks }}
                            className="st-iconbox-text"
                          ></div>
                          <div className="text-right bottom-po">
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={(e) =>
                                handleClick(item, "individualService")
                              }
                            >
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                    </>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </section>
      )}

      <FooterRevamp />
    </>
  );
};

export default ServiceSelection;
