import React, { useState, useEffect } from "react";
import { sha256 } from "js-sha256";
import moment from "moment";
import FooterRevamp from "./FooterRevamp";
import Sidebar from "./Sidebar";
import API from "./API/API";
import Pagination from "./common/pagination";

// import { MDBDataTable } from "mdbreact";
import ArrowIcon1 from "./assets/img/arrow-icon-1.png";
import ArrowIcon2 from "./assets/img/arrow-icon-2.png";
import Topbar from "./Home/Topbar";

const filterOpt = {
  page_no: 1,
  page_size: 9,
};

const Recharge = () => {
  const [amount, setAmount] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const user_id = sessionStorage.getItem("user_id");

  const [isLoading, setIsLoading] = useState(false);
  const [rechargefilter, setRechargeFilter] = useState({ ...filterOpt });
  const [rechargeTotalPage, setRechargeTotalPage] = useState(0);
  const [recharges, setRecharges] = useState([]);
  const [rechargeSelectedPage, setRechargeSelectedPage] = useState(0);

  const changeRechargePage = ({ selected }) => {
    // console.log("selected::", selected);
    if (Number(selected) >= 0) {
      setRechargeSelectedPage(selected);
    }
  };

  useEffect(() => {
    // console.log("rechargeSelectedPage::", rechargeSelectedPage);
    setRechargeFilter({
      ...rechargefilter,
      page_no: rechargeSelectedPage + 1,
    });
  }, [rechargeSelectedPage]);

  useEffect(() => {
    if (rechargefilter) {
      setIsLoading(true);
      async function loadRechargeList(rechargefilter) {
        const params = {
          user_id: sessionStorage.getItem("user_id"),
          ...rechargefilter,
        };
        const resp = await API.get("load_wallet_transactions", { params });
        if (resp && resp.status === 200) {
          const { result: datas, totalCount } = resp.data;
          setRechargeTotalPage(
            Math.ceil(totalCount > 0 ? totalCount / filterOpt.page_size : 0)
          );
          setRecharges(datas);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
      loadRechargeList(rechargefilter);
    }
  }, [rechargefilter]);

  const handleChange = (e) => {
    let hash = "Recharge" + user_id + "Coollah-CLH" + "wh_eHdig@!xcp4es3H#$*3";
    let hashstring = sha256(hash);
    console.log(amount);
    API.post(`Create_AmountRecharge_Session`, {
      hash: hashstring,
      type: "Recharge",
      user_id: user_id,
      amount: amount,
    }).then(function (response) {
      window.location.href = response.data.url;
    });
    // alert('hi')
  };

  // const loadWalletTransactions = () => {
  //   API.get(`load_wallet_transactions?user_id=${user_id}`).then((resp) => {
  //     if (resp && resp.status === 200) setTransactionList(resp.data.result);
  //   });
  // };

  const data = {
    columns: [
      {
        label: "Transaction ID",
        field: "transaction_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Transaction Date",
        field: "transaction_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Transaction Type",
        field: "transaction_type",
        sort: "asc",
        width: 200,
      },
    ],
    rows: transactionList.map((row) => {
      return {
        transaction_id: row.transaction_id,
        amount: row.amount,
        transaction_date: moment(row.transaction_date).format(
          "DD-MM-yy, h:mm A"
        ),
        transaction_type: row.transaction_type,
      };
    }),
  };

  return (
    <>
      <div className="st-height-70"></div>
      <div className="st-blue-color">
        <Topbar />
      </div>

      <section className="st-page-header st-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="st-page-header-text">
                <h1>Coollah Wallet</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="st-blog-wrap st-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <h3 className="mb-4">Choose Amount for Recharge:</h3>
              <div className="row" onChange={(e) => setAmount(e.target.value)}>
                <div className="col-md-2 col-sm-6 sm-box">
                  <div className="styled-radio">
                    <input type="radio" value="50" id="50" name="selector" />
                    <label htmlFor="50">$50</label>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 sm-box">
                  <div className="styled-radio">
                    <input
                      type="radio"
                      value="100"
                      id="100"
                      name="selector"
                      defaultChecked={parseInt(amount) === parseInt(100)}
                    />
                    <label htmlFor="100">$100</label>
                  </div>
                </div>

                <div className="col-md-2 col-sm-6 sm-box">
                  <div className="styled-radio">
                    <input
                      type="radio"
                      value="150"
                      id="150"
                      name="selector"
                      defaultChecked={parseInt(amount) === parseInt(150)}
                    />
                    <label htmlFor="150">$150</label>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 sm-box">
                  <div className="styled-radio">
                    <input
                      type="radio"
                      value="200"
                      id="200"
                      name="selector"
                      defaultChecked={parseInt(amount) === parseInt(200)}
                    />
                    <label htmlFor="200">$200</label>
                  </div>
                </div>

                <div className="col-md-2 col-sm-6 sm-box">
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    className="form-control"
                    name="amount"
                    maxLength={4}
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-2 col-sm-6 sm-box">
                  <button
                    className="btn btn-primary width100"
                    onClick={(e) => handleChange(e)}
                  >
                    Recharge
                  </button>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-4">
                  <div className="card-nbox">
                    <h4>Transaction ID</h4>
                    <h5>Amount</h5>
                    <p>Transaction Date</p>
                    <hr></hr>
                    <div className="text-right">Transaction Type</div>
                  </div>
                </div>
              </div>

              <div className="st-pagination">
                <ul>
                  <li>
                    <a href="#">Previous</a>
                  </li>
                  <li className="is-active">
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#">5</a>
                  </li>
                  <li>
                    <a href="#">6</a>
                  </li>
                  <li>
                    <a href="#">Next</a>
                  </li>
                </ul>
              </div> */}

              {isLoading ? (
                <p>Loading ...</p>
              ) : (
                <>
                  {recharges.length === 0 ? (
                    <p>No results found</p>
                  ) : (
                    <>
                      <div className="row">
                      
                        {recharges.map((recharge, ind) => (
                          <div className="col-md-4" key={ind}>
                            <div className="card-nbox card-eqhe">
                              <div className="row">
                                <div className="col-md-3 text-center">
                                  {recharge.transaction_type === "IN" && (
                                    <img
                                      src={ArrowIcon1}
                                      alt="img"
                                      className="img1"
                                    />
                                  )}
                                  {recharge.transaction_type === "OUT" && (
                                    <img
                                      src={ArrowIcon2}
                                      alt="img"
                                      className="img1"
                                    />
                                  )}
                                  {/* {recharge.transaction_type} */}
                                </div>
                                <div className="col-md-9">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <p>
                                        {moment(
                                          recharge.transaction_date
                                        ).format("DD-MM-yy, h:mm A")}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <h4 className="text-danger float-right1">
                                        ${recharge.amount}
                                      </h4>
                                    </div>
                                  </div>
                                  <h4>{recharge.transaction_desription}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="st-pagination">
                        {rechargeTotalPage > 1 && (
                          <Pagination
                            initialPage={rechargeSelectedPage}
                            pageCount={rechargeTotalPage}
                            onPageChange={changeRechargePage}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

              {/* <MDBDataTable striped bordered small data={data} /> */}
            </div>
          </div>
        </div>
      </div>
      <FooterRevamp />
    </>
  );
};

export default Recharge;
