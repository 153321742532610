import "react-multi-carousel/lib/styles.css";
import contactBgImg from "../assets/revamp-css/assets/images/contact/contact-bg.png";
import callImg from "../assets/revamp-css/assets/images/contact/call.png";
import mailImg from "../assets/revamp-css/assets/images/contact/mail.png";
import locationImg from "../assets/revamp-css/assets/images/contact/locate.png";

const Contact = () => {
  const Map =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7491451500196!2d103.89636499999999!3d1.3264631999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17f5f9955555%3A0x625d6ce67ebff243!2s10%20Ubi%20Cres%2C%20%2304-100%20Ubi%20Techpark%2C%20Singapore%20408564!5e0!3m2!1sen!2sin!4v1719204796649!5m2!1sen!2sin";

  return (
    <section>
      {/* <div className="st-about-wrap st-section-help bg-blue">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div className="contentSection">
                <h3></h3>
                <h2 className="text-white">Get the Coollah App to access our huge network of providers and manage your appointments
                  online!</h2>
              </div>
              <div className="st-about-text text-white">
                View our wide range of services, book your appointments, download invoices after your sessions and even chat with your service providers directly through the app!
              </div>
              <div className="st-footer-social">
                <ul className="st-footer-social-btn st-mp0">
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.coollah.customer"
                      className="Link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={app1} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://apps.apple.com/in/developer/naveen-prabhaker/id1511397038"
                      className="Link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={app2} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
            </div>
          </div>
        </div>
      </div>

      <div className="st-about-wrap st-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div className="st-section-heading st-style1">
                <h2>Office Address</h2>
              </div>
              <div className="st-about-text">
                362, #05-14, Upper Payalebar Road, Da Jin Factory Building, Singapore-534963.
              </div>
              <div className="st-section-heading st-style1">
                <h2>Contact Us</h2>
              </div>
              <div className="st-about-text">
                <p>Call : +65 8949 3347<br></br>Email : enquiry@coollah.com</p>
              </div>
            </div>
            <div className="col-lg-6">
              <iframe src={Map} width="100%" height="300" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div> */}
      <div className="coollah_map_sec_wrapper1 mb-0">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12 col-12">
              <iframe src={Map} width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="map-frame"></iframe>
            </div>
            <div className="coollah_contact_us_sec">
              <div className="card">
                {/* <div className="card-header">
                  <p>Contact Info</p>
                </div> */}
                <img className="card-img-top" src={contactBgImg} alt="contact-bg" />
                <div className="card-img-overlay">
                  <p>Contact Info</p>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      <span>
                        {/* <FontAwesomeIcon icon={faLocationDot} /> className="contact_collah_img_wrap" */}
                        <img src={locationImg} alt="location"/>
                      </span>
                      <span>
                        <h3>Office</h3>
                        <p>10 Ubi Crescent<br></br>#04-100 Ubi Techpark<br></br>Singapore 408564</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                        <img src={mailImg} alt="e-mail"/>
                      </span>
                      <span>
                        <h3>Mail Us</h3>
                        <a href="#">enquiry@coollah.com</a>
                      </span>
                    </li>
                    <li>
                      <span>
                        {/* <FontAwesomeIcon icon={faPhoneVolume} /> */}
                        <img src={callImg} alt="call"/>
                      </span>
                      <span>
                        <h3>Call</h3>
                        <a href="#">+65 89493347</a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
