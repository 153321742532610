import { useEffect, useState } from "react";
import API from "../API/API";
import { Accordion } from "react-bootstrap";

const FAQ = (props) => {
  const { categoryId } = props;
  const [faqList, setFaqList] = useState([]);

  const loadAllFaq = () => {
    const url = categoryId ? `load_all_faq?cat=${categoryId}` : "load_all_faq?cat=0";
    console.log("url::", url);
    API.get(url, {
      headers: {
        "content-type": "multipart/form-data",
        access_token: sessionStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log("Resp::", response);
      if (response?.data?.is_successful) {
        setFaqList(response?.data?.result);
      }
    });
  };

  useEffect(() => {
    loadAllFaq();
  }, []);

  return (
    <div className="coollah_freq_ask_sec_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_freq_ask_title_sec">
              <h5>Frequently Asked Questions</h5>
              <div className="coollah_service_doubled_border">
                <span className="border_first"></span>
                <span className="border_second"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="coollah_freq_ask_accoridan_sec">
              <Accordion>
                {faqList.length > 0 &&
                  faqList.map(({ answers, question }, ind) => {
                    return (
                      <Accordion.Item
                        eventKey={ind}
                        key={ind}
                        className="accordion-item"
                      >
                        <Accordion.Header>{question}</Accordion.Header>
                        <Accordion.Body className="accordion-body">
                          <p dangerouslySetInnerHTML={{ __html: answers }}></p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
              <div>
                {faqList?.length === 0 && (
                  <div className="text-center">
                    <h4>No Frequently Asked Questions</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
