import appStoreImg from "../src/assets/revamp-css/assets/images/footer/app-store.png";
import playStoreImg from "../src/assets/revamp-css/assets/images/footer/play-store.png";
import logo from "../src/assets/revamp-css/assets/images/logo/logo.png";
import paymentImg from "../src/assets/revamp-css/assets/images/footer/payment-img.png";
import twitter from "../src/assets/img/twitter.png";
import { Link } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import app1 from "./assets/img/google_play.png";
import app2 from "./assets/img/app_store.png";
import API1 from "./API/API1";
import API from "./API/API";
import { Modal } from "react-bootstrap";
import ModalCommon from "./Modal/modal";

const FooterRevamp = () => {
  const [email, setEmail] = useState("");
  const [post, setPost] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const handleError = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormErrors(validate(name, value));
  };

  const handleClick = (category_id, category_name) => {
    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem("category_name", category_name);
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("product_name");
  };

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      setIsSubmit(true);
    }
  }, [formErrors]);

  const validate = (name, value) => {
    const errors = {};
    switch (name) {
      case "email_id":
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!email) {
          errors.email = "Email is required!";
        } else if (!regex.test(email)) {
          errors.email = "This is not a valid email format!";
        }
        break;

      default:
        break;
    }
    return errors;
  };

  const validate1 = () => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      errors.email = "Email is required!";
    } else if (!regex.test(email)) {
      errors.email = "This is not a valid email format!";
    }

    return errors;
  };

  useEffect(() => {
    API.get("load_all_categories", {
      headers: {
        "content-type": "multipart/form-data",
        access_token: sessionStorage.getItem("access_token"),
      },
    }).then((response) => {
      setPost(response.data);
    });
  }, []);

  const handleSubscribe = () => {
    setFormErrors(validate1());
    if (isSubmit === true) {
      API1.post("subscribe_to_news_letter", {
        email: email,
      })
        .then(function (response) {
          if (response.data.is_successful === true) {
            setMessage("You are now subscribed to our newsletter");
            handleSuccessShow();
            setTitle("News letter");
            setEmail("");
          } else {
            setMessage("You are already subscribed to our newsletter");
            handleFaliureShow();
            setEmail("");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
    }
  };

  return (
    <Fragment>
      {/* <footer className="st-site-footer st-style1">
        <div className="st-main-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <div className="st-footer-logo">
                  <img src={logo} alt="demo" />
                </div>
                <div className="st-footer-text">
                  Spend your time on the most important things in life. Leave
                  the home cleaning to Coollah, Singapore's No. 1 professional
                  home cleaning service!
                </div>
                <div className="st-footer-social">
                  <ul className="st-footer-social-btn st-flex st-mp0">
                    <li>
                      <a
                        href="https://www.facebook.com/CoolLahOfficial"
                        className="Link social facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/CoolLahOfficial"
                        className="Link social twitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/coollahofficial/"
                        className="Link social linked-in"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <h5>Services</h5>
                <hr />

                <ul className="list-foot1">
                  {post?.result?.map((post) => (
                    <li key={post.category_name} className="singleService">
                      <Link
                        to={
                          "/" +
                          post.category_name
                            .replace(/\s+/g, "")
                            .replace(/-/g, "")
                            .replace("/", "")
                        }
                        className="Link"
                      >
                        {post.category_name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-2">
                <h5>Privacy & You</h5>
                <hr />
                <ul className="list-foot1">
                  <li>
                    <a href="/Terms">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/Privacy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <div className="st-newsletter">
                  <h5>Subscribe to our newsletter</h5>
                  <hr />
                  <form className="mailchimp st-subscribe-form" action="">
                    <input
                      type="email"
                      id="subscriber-email"
                      placeholder="Enter your Email"
                      name="subscribe"
                      value={email}
                      onBlur={(e) => handleError(e)}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="formError">{formErrors.email}</p>
                    <button
                      type="button"
                      id="subscribe-button"
                      className="st-newsletter-btn1"
                      onClick={handleSubscribe}
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
                <div className="st-footer-text"></div>
                <h6>Get our new app</h6>
                <div className="st-footer-social">
                  <ul className="st-footer-social-btn st-flex st-mp0">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.coollah.customer"
                        target="_blank"
                        rel="noreferrer"
                        className="Link"
                      >
                        <img src={app1} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/in/developer/naveen-prabhaker/id1511397038"
                        target="_blank"
                        rel="noreferrer"
                        className="Link"
                      >
                        <img src={app2} alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="st-copyright text-center">
          <div className="st-copyright-text">
            © 2023. Developed by <Link to=""></Link>
          </div>
        </div>
      </footer> */}
      <section>
        <footer>
          <div className="container">
          <div className="coollah_footer_sec_wrapper">
            <div className="row d-flex justify-content-center">
              <div className="col col-12 col-md-6 col-lg-3">
                <div className="coollah_footer_sec_1">
                  <a href="index.html">
                    <img src={logo} alt="logo-img" />
                  </a>
                  <p>Spend your time on the most important things in life. Leave the home service to Coollah.</p>
                  <div className="coollah_footer_social_link">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=61560752892460" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/coollahpteltd" target="_blank">
                          {/* <i className="fab fa-twitter"></i> */}
                          <img src={twitter} alt="payment-img" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/76093877/admin/" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/coollahpteltd/" target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.pinterest.com/coollahpteltd/" target="_blank">
                          <i className="fab fa-pinterest"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UCiqgYMIR1hzYJISx6Uw_uSQ" target="_blank">
                        <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <div className="footer_responsive_wrapper">
                  <div className="coollah_footer_sec_2">
                    <h6>Services</h6>
                  </div>
                  <div className="coollah_footer_services_sec_2">
                    <ul>
                      {post?.result?.map((post) => (
                        <li key={post.category_name}>
                          <Link
                            to={
                              "/" +
                              post.category_name
                                .replace(/\s+/g, "")
                                .replace(/-/g, "")
                                .replace("/", "")
                            }
                            className="Link"
                            onClick={() =>
                              handleClick(post.category_id, post.category_name)
                            }
                          >
                            {post.category_name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <div className="footer_responsive_wrapper">
                  <div className="coollah_footer_sec_2">
                    <h6>Privacy & You</h6>
                  </div>
                  <div className="coollah_footer_services_sec_2">
                    <ul>
                      <li>
                        <Link to="/Terms">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/Privacy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                <div className="coollah_footer_sec_3">
                  <h6>Subscribe to our newsletter</h6>
                </div>
                <div className="coollah_footer_services_sec_3">
                  <div className="row">
                    <div className="col col-md-12">
                      <div className="coollah_footer_subscribe_sec">
                        <form>
                          <div className="input-group">
                            <input
                              type="email"
                              className="form-control"
                              name="subscribe"
                              value={email}
                              onBlur={(e) => handleError(e)}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your Email"
                            />
                            <button type="button" onClick={handleSubscribe}>
                              Subscribe
                            </button>
                          </div>
                          <p className="formError">{formErrors.email}</p>
                        </form>
                      </div>
                      <div className="coollah_app_store_wrapp">
                        <h6>Get Our New App</h6>
                        <div className="row d-flex justify-content-center">
                          <div className="col col-md-4">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.coollah.customer"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={playStoreImg} alt="play-store-img" />
                            </a>
                          </div>
                          <div className="col col-md-4">
                            <a
                              href="https://apps.apple.com/us/app/coollah-home-services/id6560117976"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={appStoreImg} alt="app-store-img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="">
          <div className="coollah_mini_footer_sec">
            <div className="coollah_mini_footer_sec_wrapper">
              <div className="row d-flex justify-content-between">
                <div className="col col-12 col-md-6 col-lg-4">
                  <div className="coollah_mini_footer_copy_right_sec">
                    <p>
                      &copy; {new Date().getFullYear()} Powered by . All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-md-6 col-lg-4 coollah_mini_footer_payment_col_sec">
                  <div className="coollah_mini_footer_payment-sec">
                    <img src={paymentImg} alt="payment-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </footer>
      </section>

      <ModalCommon show={successShow || failureShow} onHide={handleClose}>
        {successShow && (
          <Modal.Header className="modal-head">
            <div className="icon-box">
              <i className="fa fa-check icons-1"></i>
            </div>
          </Modal.Header>
        )}
        {failureShow && (
          <Modal.Header className="modal-headd">
            <div className="icon-box1">
              <i className="fa fa-exclamation icons-1"></i>
            </div>
          </Modal.Header>
        )}

        {(successShow || failureShow) && (
          <Fragment>
            <Modal.Body className="text-center">
              <h5>{title}</h5>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer className="text-right font-weight">
              <a
                className="text-blue"
                data-dismiss="modal"
                onClick={handleClose}
              >
                OK
              </a>
            </Modal.Footer>
          </Fragment>
        )}
      </ModalCommon>
    </Fragment>
  );
};

export default FooterRevamp;
