import { Modal } from "react-bootstrap";

const ModalCommon = (props) => {
    const { children, show, onHide } = props;
    return (
        <Modal
            show={show}
            backdrop="static"
            size="md"
            keyboard={false}
            id="myModal"
            onHide={onHide}
            centered={true}
        >
            <Modal.Body className="text-center p-0">
                {children}
            </Modal.Body>
        </Modal>
    )
};

export default ModalCommon