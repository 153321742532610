import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import FooterRevamp from "./FooterRevamp";
import API from "./API/API";
import Loading from "./Loader";
import Seperator from "./assets/img/blue-img/seperator-icon1.png";
import Banner1 from "./assets/img/service-bg-3.jpg";
import Banner3 from "./assets/img/service-bg-5.jpg";
import Banner4 from "./assets/img/service-bg-6.jpg";
import Banner5 from "./assets/img/service-bg-8.jpg";
import ModalCommon from "./Modal/modal";
import Topbar from "./Home/Topbar";
// import MainPage from "./Home/MainPage";

const CategoryService = (props) => {
  const { state } = useLocation();
  const pincodeRef = useRef();
  const footerRef = useRef();
  const [post, setPost] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [product, setProduct] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const navigate = useNavigate();
  const [successAlert, setSuccessAlert] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [failureAlert, setFailureAlert] = useState(false);

  const [successShow, setSuccessShow] = useState(false);
  const [failureShow, setFailureShow] = useState(false);
  const [message, setMessage] = useState("");

  const handleSuccessShow = () => setSuccessShow(true);
  const handleFaliureShow = () => setFailureShow(true);

  const handleClose = () => {
    if (successShow) {
      setSuccessShow(false);
    }
    if (failureShow) {
      setFailureShow(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    API.get(
      "load_all_services",
      {
        params: {
          category_id: sessionStorage.getItem("category_id"),
          tab: "Y",
        },
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          access_token: sessionStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => {
        setPost(response.data);
        // console.log(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [sessionStorage.getItem("category_id")]);

  useEffect(() => {
    // console.log("product", sessionStorage.getItem("product_name"));
    if (sessionStorage.getItem("product_name") === null) {
      footerRef.current.scrollIntoView();
    }
  }, [sessionStorage.getItem("category_name")]);

  const handleClick = (data) => {
    sessionStorage.setItem("product_id", data.product_id);
    sessionStorage.setItem("product_name", data.product_name);

    if (postalCode !== "" && postalCode.length === 6) {
      navigate("/Booking");
    } else {
      pincodeRef.current.scrollIntoView();
    }
  };

  const checkPincode = (e) => {
    const errors = {};
    console.log(e.target.value);
    if (!e.target.value) {
      errors.postalCode = "Postal Code is required";
    } else if (e.target.value.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (e.target.value.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${e.target.value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            // setFailureAlert(true);
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", e.target.value);
          }
        });
      }
    }
    setFormErrors(errors);
  };

  const handlePincodeClick = () => {
    setFormErrors(validate(postalCode));

    if (postalCode !== "" && postalCode.length === 6) {
      if (sessionStorage.getItem("product_name") !== null) {
        navigate("/Booking");
      } else {
        footerRef.current.scrollIntoView();
      }
    }
  };

  const validate = (postalCode) => {
    const errors = {};
    console.log(postalCode);
    if (!postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (postalCode.length < 6) {
      errors.postalCode = "Postal Code must have 6 digits";
    } else {
      if (postalCode.length === 6) {
        const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
        Axios.get(`${pinCodeBaseUrl}?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
          if (result.data.results.length === 0) {
            setMessage("No Such Postal Code,Try Another!");
            // setFailureAlert(true);
            handleFaliureShow();
            setPostalCode("");
          } else {
            sessionStorage.setItem("Pincode", postalCode);
          }
        });
      }
    }
    return errors;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Topbar />
          <div className="st-hero-slide st-style2 st-flex" ref={pincodeRef}>
            <div className="container">
              <div className="st-hero-text st-style1 st-color1">
                <h1 className="st-hero-title">
                  {sessionStorage.getItem("category_name")}
                </h1>
                <h3 className="st-hero-title">
                  {sessionStorage.getItem("product_name")}
                </h3>
                <h3>{product}</h3>
                <div className="st-hero-subtitle">
                  Spend your time on the most important things in life.
                  <br />
                  Leave the home cleaning to Coollah, Singapore's No. 1<br />
                  professional home cleaning service!
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="st-newsletter">
                      <form className="mailchimp st-subscribe-form" action="">
                        <input
                          type="text"
                          id="pincode"
                          name="pincode"
                          pattern="[0-9]*"
                          maxLength="6"
                          placeholder="Please Enter Postal Code"
                          className="form-control ccp per AddrClass"
                          title="Please Enter Postal Code"
                          autoComplete="off"
                          tabIndex="37"
                          required=""
                          value={postalCode}
                          onChange={(e) =>
                            setPostalCode((v) =>
                              e.target.validity.valid ? e.target.value : v
                            )
                          }
                          onBlur={(e) => checkPincode(e)}
                        />
                        <button
                          type="button"
                          id="subscribe-button"
                          className="st-newsletter-btn"
                          onClick={handlePincodeClick}
                        >
                          Book Now
                        </button>
                        <span className="formError">
                          {formErrors.postalCode}
                        </span>
                        {}
                        <h5 className="subscription-success"> .</h5>
                        <h5 className="subscription-error"> .</h5>
                        <label
                          className="subscription-label"
                          htmlFor="subscriber-email"
                        ></label>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="st-hero-img"></div>
            <div className="st-circla-animation">
              <div className="st-circle st-circle-first"></div>
              <div className="st-circle st-circle-second"></div>
            </div>

            <div className="st-wave-wrap">
              <div className="st-wave">
                {sessionStorage.getItem("category_name") ===
                  "Aircon Services" ||
                sessionStorage.getItem("category_name") ===
                  "Carpenter Services" ? (
                  <div
                    className="st-wave-in"
                    style={{ backgroundImage: `url(${Banner1})` }}
                  ></div>
                ) : sessionStorage.getItem("category_name") ===
                  "Cleaning Services" ? (
                  <div
                    className="st-wave-in"
                    style={{ backgroundImage: `url(${Banner3})` }}
                  ></div>
                ) : sessionStorage.getItem("category_name") ===
                  "Electrical Services" ? (
                  <div
                    className="st-wave-in"
                    style={{ backgroundImage: `url(${Banner4})` }}
                  ></div>
                ) : (
                  <div
                    className="st-wave-in"
                    style={{ backgroundImage: `url(${Banner5})` }}
                  ></div>
                )}
                <div id="color-overlay"></div>
              </div>
            </div>
          </div>
          <section ref={footerRef}>
            <div className="container">
              <div className="st-section-heading st-style2 text-center">
                <h2>{sessionStorage.getItem("category_name")}</h2>
                <div className="st-seperator">
                  <div
                    className="st-seperator-left-bar wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  ></div>
                  <img
                    src={Seperator}
                    alt="demo"
                    className="st-seperator-icon"
                  />
                  <div
                    className="st-seperator-right-bar wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  ></div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                {post?.result[0]?.services?.map((post, ind) => (
                  <React.Fragment key={ind}>
                    <div className="col-lg-3">
                      <div
                        className="st-iconbox st-style1 st-style3 wow fadeInUp"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.2s"
                      >
                        <img src={post.product_image} alt="Img" />
                        <h5 className="st-iconbox-title font-bold">
                          {post.product_name}
                        </h5>
                        <p>{post.price}</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: post.remarks }}
                          className="st-iconbox-text"
                        ></div>
                        <div className="text-right">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(e) => handleClick(post)}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </section>

          <FooterRevamp />

          <ModalCommon show={successShow || failureShow} onHide={handleClose}>
            {successShow && (
              <Modal.Header className="modal-head">
                <div className="icon-box">
                  <i className="fa fa-check icons-1"></i>
                </div>
              </Modal.Header>
            )}
            {failureShow && (
              <Modal.Header className="modal-headd">
                <div className="icon-box1">
                  <i className="fa fa-exclamation icons-1"></i>
                </div>
              </Modal.Header>
            )}

            {(successShow || failureShow) && (
              <Fragment>
                <Modal.Body className="text-center">
                  <p>{message}</p>
                </Modal.Body>
                <Modal.Footer className="text-right font-weight">
                  <a
                    className="text-blue"
                    data-dismiss="modal"
                    onClick={handleClose}
                  >
                    OK
                  </a>
                </Modal.Footer>
              </Fragment>
            )}
          </ModalCommon>
        </>
      )}
    </>
  );
};

export default CategoryService;
