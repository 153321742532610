import ReactPaginate from "react-paginate";

const Pagination = (props) => {
    const { pageCount, onPageChange, initialPage } = props;

    const prevText = (
        <>
            <span aria-hidden="true">«</span> Prev
        </>
    );
    const nextText = (
        <>
            Next <span aria-hidden="true"> »</span>
        </>
    );

    return (
        <ReactPaginate
            initialPage={initialPage}
            pageCount={pageCount}
            onPageChange={onPageChange}
            className="pagination"
            previousLabel={prevText}
            nextLabel={nextText}
            disabledClassName={"navigationDisabled"}
            activeClassName="active"
            previousLinkClassName={"previous"}
            nextLinkClassName={"next"}
        />
    );
};

export default Pagination;
