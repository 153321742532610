import React, { Suspense } from "react";
import "mdbreact/dist/css/mdb.css";
import "../src/assets/css/core.css";
import "../src/assets/css/responsive.css";
import "../node_modules/swiper/swiper.css";
import "../node_modules/swiper/modules/scrollbar.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "./App.css";
import "./animate.css";

import "../src/assets/revamp-css/assets/index.css";
import "../src/assets/revamp-css/assets/responsive.css";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./Path/Layout";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./core/store";


function App() {
  return (
    <div className="st-blue-color">
      <Provider store={store}>
        <Router>
          <Suspense
            fallback={
              <div id="st-preloader">
                <div className="st-preloader-wave"></div>
                <div className="st-preloader-wave"></div>
                <div className="st-preloader-wave"></div>
                <div className="st-preloader-wave"></div>
                <div className="st-preloader-wave"></div>
              </div>
            }
          >
            <Layout />
          </Suspense>
        </Router>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
