import React, { useEffect, useState } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { usePagination } from "react-table/dist/react-table.development";

export default function CallReportTable({ columns, data ,loading}) {
  const [filterInput, setFilterInput] = useState("");
  const [searchby,setSearchby]=useState(" ");
  const[show,setShow]=useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    
    page, 
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,usePagination
  );

  useEffect(() => {

    if(searchby !== " ")
    {
      
      setShow(true);
    }
  })
  const handleFilterChange = e => {
    const value = e.target.value ;
    
     setFilter(searchby, value);
   
    setFilterInput(value);
  };


  return (
    <>
    {/* <div className="row">
    <div className="col-md-6" style={{float:'left'}}>
      <label>Show
    <select style={{width:'50px'}}
    value={pageSize}
    onChange={e => {
      setPageSize(Number(e.target.value))
    }}
  >
    {[10, 20, 30, 40, 50].map(pageSize => (
     
      <option key={pageSize} value={pageSize}>
         {pageSize}
      </option>
    
    ))}
  </select>
  entries
  </label>
  </div>
  <div className="col-md-6" style={{paddingLeft:'180px'}}>
    <label>Search:</label>
    <select style={{width:'180px'}}
 
    onChange={e => {
      setSearchby(e.target.value)
    }}
  >
  
  <option  value="">
        Searchby
      </option>
      <option  value="ticket_no">
        SearchbyTicketNo
      </option>
      <option value="contract_ref_no">
        SearchbyContractRefNo
      </option>
      <option value="ticket_datetime">
        SearchbyContractDate
      </option>
      <option value="timing_text">
        SearchbyTiming
      </option>
  </select>
    {show?  <input
        value={filterInput}
        placeholder={searchby}
        onChange={handleFilterChange}
        style={{marginLeft:'50px'}}
      />:null}
      </div>
      </div> */}
      <div className="mt-3" >
      <table {...getTableProps()} className="table table-condensed table-bordered table-hover dataTable no-footer">
        <thead style={{textAlign:'center'}}>
          {headerGroups.map(headerGroup => (
            <tr >
              {headerGroup.headers.map(column => (
             
                <th
             
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                 
                  {column.render("Header")}
                </th>
              
              ))}
            </tr>
          ))}
        </thead>
        {/* <tbody {...getTableBodyProps()}>
      
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (

                    <td {...cell.getCellProps()}  
                    >{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          }) 
      
        }
        </tbody> */}


<tbody>
        {page.length > 0  ? ( 
          page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (

                    <td {...cell.getCellProps()}  
                    >{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          }) 
                    ): !loading ? <div>No Data Available</div> : 
                       <div>Loading...</div>
        }
        </tbody>
      </table>
      </div>
      {/* <div className="pagination">
        <div className="row">
          <div className="col-md-8">
      <span>
         
          <strong>
         Showing {pageIndex + 1} of {pageOptions.length} entries
          </strong>{' '}
        </span>
        </div>
        <div className="col-md-4">
          <div className="row">
          <div className="col-md-6">
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'Previous'}
        </button>{' '}
        </div>
        <div className="col-md-6">
        <button onClick={() => nextPage()} style={{marginLeft:'20px'}} disabled={!canNextPage}>
          {'Next'}
        </button>{' '}
        </div>
        </div>
        </div>
     </div>
       
      
    
      </div> */}
    </>
  );
}
